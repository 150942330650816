import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../camponets/Banner/Banner';
import ServicesCard from '../../../camponets/ServicesCard/ServicesCard';

export default function QAService() {
    const manualQASubServices = [{ name: 'Functional Testing' }, { name: 'Regression Testing' }, { name: 'Usability Testing' }, { name: 'Exploratory Testing' }]
    const webTestingSubServices = [{ name: 'Functional Test Automation' }, { name: 'Regression Test Automation' }, { name: 'Cross-Browser Testing' }, { name: 'Load Testing' }]
    const mobileAppSubServices = [{ name: 'Functional Testing' }, { name: 'Usability Testing' }, { name: 'Compatibility Testing' }, { name: 'Security Testing' }]
    const performanceTestingSubServices = [{ name: 'Load Testing' }, { name: 'Stress Testing' }, { name: 'Scalability Testing' }, { name: 'Speed and Latency Testing' }]
    const apiTestingSubServices = [{ name: 'Functional Testing' }, { name: 'Load Testing' }, { name: 'Security Testing' }, { name: 'Interoperability Testing' }]

    const AllServices = [{
        id: "manual-qa-testing",
        image: "https://cdn.technolee.com/image/services/qaservice/manualqa.webp",
        title: "Manual QA Testing",
        description: "Our Manual QA Testing service is the meticulous examination that ensures the quality and functionality of your digital products. Our skilled testers employ comprehensive test cases, assessing usability, functionality, and performance. Functional testing validates that every aspect of your software or application operates as intended. Regression testing prevents new updates from introducing new issues. Usability testing evaluates the user-friendliness of your product, while exploratory testing uncovers unexpected issues through ad-hoc assessments. With our manual QA testing, we leave no stone unturned, delivering a polished and reliable digital solution. Manual testing includes:",
        iconData: [],
        subServicesData: manualQASubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "manualqa"
    }, {
        id: "web-testing-automation",
        image: "https://cdn.technolee.com/image/services/qaservice/webtesting.webp",
        title: "Web Testing Automation",
        description: "Our Web Testing Automation service harnesses the power of automation to streamline the testing process for web applications. We create automated scripts to verify web application functionality, saving time and ensuring comprehensive coverage. Functional test automation guarantees that your web applications operate seamlessly. Regression test automation efficiently validates new updates without manual repetition. Cross-browser testing verifies compatibility across various web browsers. Load testing measures your application's performance under different loads, providing valuable insights for optimization. Types of web testing automation include:",
        iconData: [],
        subServicesData: webTestingSubServices,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "webtesting"

    }, {
        id: "mobile-app-testing",
        image: "https://cdn.technolee.com/image/services/qaservice/mobileapptesting.webp",
        title: "Mobile App Testing",
        description: "Our Mobile App Testing service is dedicated to ensuring the functionality, performance, and compatibility of mobile applications. We scrutinize your mobile apps for functional accuracy, conducting usability tests to assess user experience and interface quality. Compatibility tests are performed across diverse devices and operating systems, guaranteeing smooth operation for all users. We also assess security, identifying and addressing vulnerabilities to protect your users and their data. With our rigorous mobile app testing, your apps will perform flawlessly and securely. It includes:",
        iconData: [],
        subServicesData: mobileAppSubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "mobileapptesting"

    }, {
        id: "performance-testing",
        image: "https://cdn.technolee.com/image/services/qaservice/performancetesting.webp",
        title: "Performance Testing",
        description: "Our Performance Testing service is all about evaluating the speed, responsiveness, and stability of your software or applications under different conditions. Load testing assesses how your system performs under anticipated and peak loads, ensuring it can handle user demands. Stress testing determines the breaking point of your system, allowing for proactive measures. Scalability testing ensures that your system can grow with your business. Speed and latency testing assess responsiveness and latency issues, enhancing user satisfaction and engagement. Types of performance testing include:",
        iconData: [],
        subServicesData: performanceTestingSubServices,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "performancetesting"

    }, {
        id: "api-testing",
        image: "https://cdn.technolee.com/image/services/qaservice/apitesting.webp",
        title: "API Testing",
        description: "Our API Testing service focuses on the reliability and functionality of application programming interfaces (APIs). Functional testing guarantees that APIs operate as intended. Load testing assesses the performance and responsiveness of APIs under varying loads, ensuring consistent functionality. Security testing identifies and mitigates vulnerabilities in API security, safeguarding your data and transactions. Interoperability testing ensures that APIs work seamlessly with other software, promoting efficient data exchange and integration. It covers:",
        iconData: [],
        subServicesData: apiTestingSubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "apitesting"

    }]

    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                <title>QA Services - Manual QA Testing, Web Testing Automation, Mobile App Testing, Performance Testing, API Testing</title>
                <meta name="description" content="Explore our QA services, including manual QA testing, web testing automation, mobile app testing, performance testing, and API testing. We ensure the quality, reliability, and performance of your digital solutions." />
                <meta name="keywords" content="QA services, manual QA testing, web testing automation, mobile app testing, performance testing, API testing, Technolee" />
            </Helmet>

            <Banner
                alt={'QA Services'}
                image="https://cdn.technolee.com/image/services/qaservice/qabanner.webp"
                title="Ensuring Quality and Reliability"
                description="Enhance the quality and performance of your digital solutions with our comprehensive QA services. Discover our expertise in manual QA testing, web testing automation, mobile app testing, performance testing, and API testing."
            />
            <div className='page-content container'>
                <div>
                    {AllServices.map((item, ind) =>
                        <ServicesCard
                            key={ind}
                            id={item.id}
                            image={item.image}
                            title={item.title}
                            description={item.description}
                            iconData={item.iconData}
                            subServicesData={item.subServicesData}
                            isLeft={item.isLeft}
                            isFullWidth={item.isFullWidth}
                            alt={item.alt}
                            isReadMore={item.isReadMore}
                        />
                    )}
                </div>
            </div>
        </>
    )
}