import React from 'react'
import TechnologiesDetails from '../../../../camponets/TechnologiesDetails/TechnologiesDetails';
import { Helmet } from 'react-helmet';

const TechName = "Native Android"

const BannerData = [
  { alt: "androidbanner", BannerimgUrl: 'https://cdn.technolee.com/image/technologies/technobennar/androidbanner.webp', htmlString: 'Crafting Excellence in <span class="text-highlight">Native Android</span> Development', BennerDecription: "Immerse your audience in a superior Android experience with our native app development services. From conceptualization to deployment, our expert Android developers ensure your app stands out for its performance, design, and user engagement." }
]

const AboutCardData = [
  { alt: "androidtechnology", imgUrl: 'https://cdn.technolee.com/image/technologies/technoexceptional/androidtechnology.webp', AboutTitle: "Unleashing the Power of Native Android Development", AboutDecription: "Discover why our native Android development services are the ideal choice for businesses seeking top-notch, platform-specific applications." }
]

const TechDetailsCardData = [
  { title: 'Expert Android Developers', description: "Our skilled Android developers bring years of experience to the table, ensuring the creation of robust and visually appealing native Android applications." },
  { title: 'Tailored Android Solutions', description: "We understand the unique requirements of Android development, delivering tailored solutions that align seamlessly with the Android ecosystem." },
  { title: 'Innovative App Solutions', description: "Embrace innovation with our forward-thinking approach to Android development. We integrate the latest Android features and design principles for cutting-edge applications." },
  { title: 'Quality Assurance', description: "Quality is at the core of our Android development process. Rigorous testing ensures that the applications we deliver are reliable, secure, and deliver an outstanding user experience." },
  { title: 'Transparent Collaboration', description: "Collaboration is key. We involve you in the development process, ensuring transparent communication and making you an integral part of the decision-making process." },

]

const ExpertiseCardData = [
  { title: 'Java and Kotlin Proficiency', description: "Leverage our proficiency in Java and Kotlin, ensuring that your native Android app is developed using the latest and most effective programming languages." },
  { title: 'UI/UX Design Excellence', description: "Elevate your app's visual appeal with our expert UI/UX design integration. We prioritize intuitive design and smooth navigation, ensuring an immersive user experience." },
  { title: 'Integration with Android Ecosystem', description: "Seamless integration with the Android ecosystem ensures that your native Android app leverages the full range of features available on Android devices." },
  { title: 'Performance Optimization', description: "Our team is dedicated to optimizing your native Android app for superior performance. We implement best practices to ensure responsiveness, speed, and reliability." },
  { title: 'Security Best Practices', description: "Security is a top priority. We adhere to the highest security standards, ensuring that your Android app is robust and protected against potential threats." },
]

const TechFancyCardData = [
  { title: 'Custom Android App Development', description: "Tailored native Android applications crafted to meet your unique business requirements. Our custom development ensures that your app stands out in functionality and design." },
  { title: 'Java to Kotlin Migration', description: "Seamlessly migrate existing apps to Kotlin, ensuring they are up-to-date with the latest Android technologies and standards." },
  { title: 'UI/UX Design', description: "Elevate your app's visual appeal with our expert UI/UX design services. We prioritize user-centric design, creating interfaces that captivate and engage users." },
  { title: 'Integration Services', description: "Ensure smooth integration with third-party APIs, plugins, and backend systems. We streamline the integration process, expanding your app's functionality." },
  { title: 'Maintenance and Support', description: "Beyond development, we offer ongoing maintenance and support services. Ensure your Android apps stay up-to-date, secure, and perform seamlessly." },
  { title: 'Real-Time Features Implementation', description: ": Implement real-time features that enhance user engagement and collaboration. Whether it's live updates, messaging, or collaborative features, we bring your Android app to life with real-time capabilities." },
]


const KeyBenefitsCardData = [
  { alt: "performance", title: 'Optimized Performance', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/performance.webp', description: "Native Android apps are optimized for performance, providing a smoother and faster user experience compared to cross-platform solutions." },
  { alt: "crossplatform", title: 'Access to Device-Specific Features', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/crossplatform.webp', description: "Leverage the full range of features available on Android devices, ensuring that your app takes advantage of device-specific functionalities." },
  { alt: "integration2", title: 'Seamless Integration', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/integration2.webp', description: "Seamlessly integrate your app with other Android devices and services, providing a cohesive user experience within the Android ecosystem." },
  { alt: "security", title: 'Enhanced Security', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/security.webp', description: "Native Android apps benefit from robust security measures, ensuring the protection of user data and the overall integrity of the application." },
  { alt: "flexible", title: 'Optimized UI/UX', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/flexible.webp', description: "Tailor your app's user interface and experience to the specific design principles of Android, creating an interface that feels native and intuitive." },
  { alt: "reusability", title: 'Efficient Performance Optimization', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/reusability.webp', description: "Our team dedicates efforts to optimize the performance of your native Android app, ensuring it runs efficiently on a variety of Android devices." },
]

const TestimonialsData = [
  { title: 'What Is the Advantage of Developing a Native Android App?', description: 'Native Android apps offer optimized performance, access to device-specific features, seamless integration, enhanced security, and a UI/UX tailored to Android design principles.' },
  { title: 'How Long Does it Take to Develop a Native Android App?', description: 'Development time varies based on the complexity of the project. Our team ensures efficient development without compromising on quality.' },
  { title: 'Can You Migrate an Existing App to Native Android?', description: 'Yes, we provide migration services to Kotlin, ensuring your existing app is up-to-date with the latest Android technologies.' },
  { title: 'What Are the Key Features of a Native Android App?', description: 'Native Android apps can leverage the full range of Android features, including optimized performance, access to device-specific functionalities, and seamless integration.' },
  { title: 'Is Native Android Development More Expensive Than Cross-Platform?', description: 'While native development may have higher upfront costs, it offers long-term advantages in terms of performance, user experience, and access to the latest Android features.' },
  { title: 'How Do You Ensure the Security of Native Android Apps?', description: "We adhere to Android's security best practices, implementing measures to protect user data and ensure the overall security of native Android applications." },
  { title: 'Can Real-Time Features Be Implemented in Native Android Apps?', description: 'Yes, we can implement real-time features such as live updates, messaging, and collaboration in native Android apps, enhancing user engagement.' },
  { title: 'What Is the Role of UI/UX Design in Native Android Development?', description: 'UI/UX design is crucial in native Android development to create interfaces that are intuitive, visually appealing, and aligned with Android design principles.' },
]

export default function Android() {
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Native Android App Development Services</title>
        <meta name="description" content="Elevate your Android app experience with our native Android development services. Our expert Android developers create seamless, performance-oriented applications tailored to your business needs." />
        <meta name="keywords" content="Android App Development, Native Android Apps, Java Programming, Kotlin Development, Android UI/UX Design, Android Integration Services, Android Maintenance and Support, Real-Time Android Apps, Android Security Best Practices, Android Developers, Android Performance Optimization, Android Migration Services, Custom Android Solutions, Swift to Kotlin Migration, Android Consulting Services" />
      </Helmet>

      <TechnologiesDetails TechName={TechName} AboutCardData={AboutCardData} BannerData={BannerData} TechDetailsCardData={TechDetailsCardData} TechFancyCardData={TechFancyCardData} ExpertiseCardData={ExpertiseCardData} KeyBenefitsCardData={KeyBenefitsCardData} TestimonialsData={TestimonialsData} />
    </div>
  )
}
