import React from 'react'
import './pagesectionsecondary.css'

export default function PageSectionSecondary({title,isSize}) {
  return (
    <div className={isSize == true ?"change-size flex-center":'Page-Section-Secondary  flex-center'}>
      <p>{title}</p>
    </div>
  )
}
