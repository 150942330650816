import React from 'react'
import './workcard.css'

export default function WorkCard({ data }) {
    return (
        <div>
            <div className='flex-space-between work-card-main'>
                {data.map((item, ind) => {
                    return <>
                        <div key={ind} className='workcard-block'>
                            {item.isLine && <div className='workcard-line'></div>}
                            <div className='workcard-content'>
                                <div className='workcard-content-inner'>
                                    <div><img className='workcard-icon' src={item.IconUrl} alt={item.alt} /></div>
                                </div>
                            </div>
                            {item.isLineRight && <div className='workcard-line-right'></div>}
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </div>
                        {ind == 3 && <div className='workcard-right-corner' key={ind}></div>}
                    </>
                })}
            </div>
        </div>
    )
}
