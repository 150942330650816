import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../camponets/Banner/Banner';
import Card from '../../camponets/Card/Card';
import AboutBusiness from '../../camponets/AboutBusiness/AboutBusiness';
import PageSectionMain from '../../camponets/page-Section-Main/PageSectionMain';
import PageSectionSecondary from '../../camponets/Page-Section-Secondary/PageSectionSecondary';
import KeyStatistics from '../../camponets/KeyStatistics/KeyStatistics';

export default function About() {
  const htmlString = 'Unleashing <span class="text-highlight">Innovation</span> Since 2017';

  const CardData = [
    { alt: 'Healthcare', Header: "Healthcare", image: "https://cdn.technolee.com/image/about/industry/healthcare.webp", description: "Elevate patient care with our healthcare solutions. From intuitive patient portals to robust electronic health record systems, we tailor software to streamline processes, enhance communication, and ensure compliance." },
    { alt: 'Finance', Header: "Finance", image: "https://cdn.technolee.com/image/about/industry/finance.webp", description: "Transform your financial operations with our cutting-edge solutions. Whether it's secure transaction platforms, advanced analytics, or bespoke financial software, we empower organizations to thrive in the dynamic world of finance." },
    { alt: 'E-commerce', Header: "E-commerce", image: "https://cdn.technolee.com/image/about/industry/ecommerce.webp", description: "Revolutionize your online presence with our e-commerce expertise. From user-friendly interfaces to seamless payment gateways, we craft digital storefronts that captivate audiences and drive conversions in the competitive e-commerce landscape." },
    { alt: 'Manufacturing', Header: "Manufacturing", image: "https://cdn.technolee.com/image/about/industry/manufacturing.webp", description: "Optimize your manufacturing processes with our tailored solutions. From supply chain management to automation integration, we leverage technology to enhance efficiency, reduce costs, and boost overall productivity." },
    { alt: 'Education', Header: "Education", image: "https://cdn.technolee.com/image/about/industry/education.webp", description: "Empower the future with our educational technology. From interactive learning platforms to student management systems, we create innovative solutions that enhance the educational experience and facilitate seamless administration." },
    { alt: 'Entertainment', Header: "Entertainment", image: "https://cdn.technolee.com/image/about/industry/entertainment.webp", description: "Immerse your audience in captivating digital experiences. Our entertainment solutions range from interactive apps to content delivery platforms, ensuring that your entertainment offerings leave a lasting impact." },
    { alt: 'Transportation', Header: "Transportation", image: "https://cdn.technolee.com/image/about/industry/transportation.webp", description: "Navigate the digital highway with our transportation solutions. From logistics management to real-time tracking systems, we provide technology-driven solutions that optimize transportation operations and improve overall efficiency." },
    { alt: 'Government', Header: "Government", image: "https://cdn.technolee.com/image/about/industry/government.webp", description: "Modernize public services with our government-focused solutions. We specialize in secure and scalable software that enhances citizen engagement, streamlines processes, and strengthens the foundation of digital governance." },
    { alt: 'Nonprofit organizations', Header: "Nonprofit organizations", image: "https://cdn.technolee.com/image/about/industry/nonprofit.webp", description: "Advance your mission with our technology solutions for nonprofits. From donor management systems to online fundraising platforms, we support nonprofit organizations in creating a positive impact and fostering community support." },
  ]

  const CoreValueData = [
    { title: "Innovation", description: 'We embrace creativity and continually seek better ways of doing things.' },
    { title: "Integrity", description: 'We uphold the highest ethical standards in everything we do.' },
    { title: "Collaboration", description: 'We believe in the power of teamwork and partnership.' },
    { title: "Excellence", description: 'We pursue excellence in all aspects of our work, delivering the best possible results to our clients.' },
  ]


  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>About Us - Technolee: Unleashing Innovation Since 2017</title>
        <meta name="description" content="Get to know Technolee, a dynamic software and web development company dedicated to innovation and transformation. Discover who we are, our capabilities, mission, vision, core values, and the diverse industries we serve." />
        <meta name="keywords" content="About Us, Technolee, software development, web development, innovation, mission, vision, core values, industries served" />
      </Helmet>

      <Banner
        image="https://cdn.technolee.com/image/home/bannermain.webp"
        title={htmlString}
        alt={'transforming businesses'}
        description="Discover the journey and vision of Technolee. Learn who we are, what we can do, and how we're dedicated to transforming businesses and industries through cutting-edge technology solutions."
      />
      <div className='page-content container'>
        <div>
          <p className='simple-p-tag'>At Technolee, we embarked on our journey in 2017 with a vision to redefine the digital landscape. As a dynamic software and web development company, we have been at the forefront of innovation, driving transformation across various industries. Our commitment to delivering excellence has established us as a trusted partner for businesses seeking to leverage technology for growth and success.</p>

        </div>
        <div>
          <PageSectionMain title='OUR CORE SERVICES' />
          <PageSectionSecondary title='Industries We Served' />

          <div className='flex-space-between ' style={{ alignItems: 'flex-start' }}>
            {CardData.map((item, ind) => {
              return (
                <div className='core-card' key={ind} >
                  <Card image={item.image} title={item.Header} description={item.description} alt={item.alt} cardHeight='155px' />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className='business-main'>
        <PageSectionMain title='OUR BUSINESS' />
        <PageSectionSecondary title='Stand Out From The Rest' />
        <div className='ourcard'>
          <AboutBusiness CoreValueData={CoreValueData} />
        </div>
      </div>
      <div>
        <PageSectionMain title='Some Numbers' />
        <PageSectionSecondary title='Key Analytics' />

        <KeyStatistics />
      </div>

    </>
  )
}
