import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../camponets/Banner/Banner';
import ServicesCard from '../../camponets/ServicesCard/ServicesCard';
import PageSectionSecondary from '../../camponets/Page-Section-Secondary/PageSectionSecondary';
import ProjectInquiryForm from '../../camponets/ProjectInquiryForm/ProjectInquiryForm';
import PageSectionMain from '../../camponets/page-Section-Main/PageSectionMain';
import { useLocation } from 'react-router-dom';


export default function Services() {
  const location = useLocation()
  const id = location.state;
  const htmlString = 'Comprehensive <span class="text-highlight">Technology</span> Services';
  const mobileSubServicesIcon = [
    { iconURL: 'https://cdn.technolee.com/image/services/reactnative.webp', name: "React Native", alt: "reactnative" },
    { iconURL: "https://cdn.technolee.com/image/services/flutter.webp", name: "Flutter", alt: "flutter" },
    { iconURL: "https://cdn.technolee.com/image/services/ios.webp", name: "Ios", alt: "ios" },
    { iconURL: 'https://cdn.technolee.com/image/services/android.webp', name: "Android", alt: "android" },
  ]
  const WebDevelopmentIcon = [
    { iconURL: 'https://cdn.technolee.com/image/services/reactnative.webp', name: "React", alt: "react" },
    { iconURL: 'https://cdn.technolee.com/image/services/angular.webp', name: "React Native", alt: "angular" },
    { iconURL: "https://cdn.technolee.com/image/services/vuejs.webp", name: "Vuejs", alt: "Vuejs" },
    { iconURL: "https://cdn.technolee.com/image/services/typescript.webp", name: "Typescript", alt: "Typescript" },
    { iconURL: "https://cdn.technolee.com/image/services/dotnet.webp", name: "DotNet", alt: "DotNet" },
    { iconURL: "https://cdn.technolee.com/image/services/nodejs.webp", name: "Nodejs", alt: "Nodejs" },
    { iconURL: "https://cdn.technolee.com/image/services/sqlserver.webp", name: "Sqlserver", alt: "Sqlserver" },
  ]
  const UIUXDesignIcon = [
    { iconURL: 'https://cdn.technolee.com/image/services/figma.webp', name: "figma", alt: "figma" },
    { iconURL: 'https://cdn.technolee.com/image/services/adobexd.webp', name: "adobexd", alt: "adobexd" },
    { iconURL: "https://cdn.technolee.com/image/services/sketch.webp", name: "sketch", alt: "sketch" },
    { iconURL: "https://cdn.technolee.com/image/services/photoshop.webp", name: "photoshop", alt: "photoshop" },

  ]

  const subServices = [{ name: 'Enterprise Solutions' }, { name: 'eCommerce Web Development' }, { name: 'Mobile Backend & APIs' }, { name: 'Custom Web Development' }]
  const mobileSubServices = [{ name: 'Cross Platform Apps' }, { name: 'Native iOS Apps' }, { name: 'Native Android Apps' }, { name: 'Custom Web Development' }]
  const productSubServices = [{ name: 'Product Discovery & Design' }, { name: 'Native iOS Apps' }, { name: 'Native Android Apps' }, { name: 'Custom Web Development' }]
  const DigitalTransformationSubServices = [{ name: 'Operational Transformation' }, { name: 'Customer Experience Transformation' }, { name: 'Technological Evolution' }, { name: 'Data-Driven Transformation' }]
  const UiUxSubServices = [{ name: 'Requirement Understanding' }, { name: 'Analysis' }, { name: 'Wireframing' }, { name: 'Custom Web Design' }, { name: 'Custom Web Design' }, { name: 'Custom Web Design' }]
  const DedicatedDevelopersServices = [{ name: 'Full-Time Dedicated' }, { name: 'Part-Time Dedicated' }, { name: 'Project-Based Hiring' }, { name: 'Offshore Development Team' }, { name: 'Custom Web Design' }, { name: 'Custom Web Design' }]



  useEffect(() => {
    // Get the hash from the URL (e.g., #elementId)
    const hash = window.location.hash;

    if (hash) {
      // Remove the '#' character to get the element's ID
      const elementId = hash.slice(1);

      // Find the element with the matching ID
      const element = document.getElementById(elementId);

      if (element) {
        // Scroll to the element
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [id]);


  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Our Services - Web Development, Mobile Apps, UI/UX Design, QA, IoT, Digital Transformation, and More</title>
        <meta name="description" content="Explore Technolee's comprehensive services, including web development, mobile apps, UI/UX design, quality assurance, IoT solutions, digital transformation, product development, and dedicated developers. Unlock your business potential with our expertise." />
        <meta name="keywords" content="services, web xdevelopment, mobile app development, UI/UX design, QA services, IoT solutions, digital transformation, product development, dedicated developers, Technolee" />
      </Helmet>

      <Banner
        alt={"Services banner"}
        image="https://cdn.technolee.com/image/home/bannermain.webp"
        title={htmlString}
        description="Discover our wide range of technology services that drive innovation and success. Explore our expertise in web development, mobile apps, UI/UX design, QA services, IoT solutions, digital transformation, product development, and dedicated developers."
      />

      <PageSectionMain title='OUR SERVICES' />
      <PageSectionSecondary title='Discover our wide range of technology services' />

      <div className=' container'>
        <ServicesCard
          image="https://cdn.technolee.com/image/services/digitaltransformation.webp"
          link="/services/digital-transformation"
          title="Digital Transformation"
          description="In the age of digital transformation, we partner with businesses to reimagine their operations and digital presence. Our digital transformation services are designed to drive efficiency, growth, and innovation. We analyze your current processes and systems, identify opportunities for improvement, and deliver strategic solutions that position your business for success in a rapidly evolving digital landscape. Embrace change and stay competitive with our digital transformation expertise."
          iconData={WebDevelopmentIcon}
          subServicesData={DigitalTransformationSubServices}
          isLeft={true}
          isFullWidth={false}
          isReadMore={true}
          alt={'Digital Transformation'}
        />
        <ServicesCard
          image="https://cdn.technolee.com/image/services/appdev.webp"
          link="/services/mobile-apps"
          title="Mobile Apps Development"
          description="Stay ahead in the mobile-first world with our mobile app development services. Our team of app experts crafts engaging and user-centric mobile applications for both iOS and Android platforms. From initial concept and design to development and deployment, we're dedicated to bringing your app vision to life. Whether you're targeting consumers or businesses, we build apps that captivate users and deliver real value, ensuring a competitive edge in the ever-evolving mobile landscape."
          iconData={mobileSubServicesIcon}
          subServicesData={mobileSubServices}
          isLeft={false}
          isFullWidth={false}
          isReadMore={true}
          alt={'Application Development'}

        />
        <ServicesCard
          image="https://cdn.technolee.com/image/services/webdev.webp"
          title="Web Development"
          link="/services/web-development"
          description="Elevate your online presence with our web development services. Our dedicated team of experienced developers specializes in creating high-performance websites and web applications tailored to your unique needs. We ensure that your digital presence not only looks stunning but functions seamlessly, providing a delightful user experience. Whether you're launching a new website, upgrading an existing one, or need a web application to support your business processes, we're here to transform your ideas into exceptional online solutions."
          iconData={WebDevelopmentIcon}
          subServicesData={subServices}
          isLeft={true}
          isFullWidth={false}
          isReadMore={true}
          alt={'Web Development'}

        />

        <ServicesCard
          image="https://cdn.technolee.com/image/services/uiux.webp"
          link="/services/uiux-design"
          title="UI/UX Design"
          description="User experience (UX) and user interface (UI) design are at the core of our digital solutions. Our talented designers create digital experiences that are not only visually stunning but also highly intuitive and user-friendly. We prioritize understanding your target audience and their needs, ensuring that every interaction with your digital product leaves a lasting impression. Whether you're designing a website, web application, or mobile app, our UI/UX expertise guarantees an exceptional user journey."
          iconData={UIUXDesignIcon}
          subServicesData={UiUxSubServices}
          isLeft={false}
          isFullWidth={false}
          isReadMore={true}
          alt={'UI UX'}

        />
        <ServicesCard
          image="https://cdn.technolee.com/image/services/dedicateddeveloper.webp"
          link="/services/dedicated-developers"
          title="Hiring Dedicated Developers"
          description="Augment your team with our dedicated developers who are experts in various technology domains. When you hire dedicated developers from Technolee, you gain skilled professionals who become an integral part of your project. They work alongside your team, helping you achieve your project goals efficiently and effectively. Whether you need a single developer or an entire team, we offer flexible hiring options to meet your specific project requirements. Our dedicated developers are committed to delivering results and fueling your business growth."
          iconData={[]}
          subServicesData={DedicatedDevelopersServices}
          isLeft={true}
          isFullWidth={false}
          isReadMore={true}
          alt={'Dedicated developer'}

        />
        <ServicesCard
          image="https://cdn.technolee.com/image/services/qualityassurance.webp"
          link="/services/qa-service"
          title="QA Services"
          description="Quality assurance is a fundamental aspect of our service offerings. Our comprehensive quality assurance and testing services ensure that your applications and software meet the highest standards. We rigorously test your digital products to uncover and address any issues, ensuring a seamless and reliable user experience. We conduct exhaustive testing procedures, from functionality and performance testing to security and compatibility assessments, to guarantee your software's quality and reliability."
          iconData={[]}
          subServicesData={subServices}
          isLeft={false}
          isFullWidth={false}
          isReadMore={true}
          alt={'Quality Assurance'}

        />

        <ServicesCard
          image="https://cdn.technolee.com/image/services/productdevelopment.webp"
          link="/services/product-development"
          title="Product Development"
          description="Turn your visionary ideas into market-ready solutions with our product development expertise. Whether it's a software application, a new technology product, or a unique digital solution, we work collaboratively with you to bring your concepts to life. Our product development process encompasses ideation, design, development, and testing, ensuring that your innovation stands out in the market. We're committed to delivering innovative products that drive your business forward and captivate your target audience."
          iconData={[]}
          subServicesData={productSubServices}
          isLeft={true}
          isFullWidth={false}
          isReadMore={true}
          alt={'Product Development'}

        />
        <ServicesCard
          image="https://cdn.technolee.com/image/services/iot.webp"
          link="/services/iot-service"
          title="IoT Solutions"
          description="Explore the limitless potential of the Internet of Things (IoT) with our cutting-edge solutions. We help businesses harness the power of IoT to enhance operational efficiency, connectivity, and data-driven decision-making. From smart devices to data analytics, our IoT experts create solutions that transform how businesses operate. Embrace the future of technology with our IoT services and position your organization for success in the era of connected devices."
          iconData={[]}
          subServicesData={subServices}
          isLeft={false}
          isFullWidth={false}
          isReadMore={true}
          alt={'IOT Service'}
        />

      </div>

      <div className='service-form' id={id}>
        <PageSectionMain title='READY TO START A PROJECT?' />
        <PageSectionSecondary title='To start, use form below to tell us about you and the project' />
        <ProjectInquiryForm />
      </div>
    </>
  )
}
