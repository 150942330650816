import React from 'react'
import './portfolioCard.css'

export default function PortfolioCard({ contentData }) {
    return (
        <div>
            <section className="articles">
                {contentData != undefined && contentData.length && contentData.map((item,ind) =>
                    <article key={ind}>
                        <div className="article-wrapper">
                            <figure>
                                <img className='portfolio-image' src={item.image} alt={item.alt} width='100%' height='100%' />
                            </figure>
                            
                                <div className="article-body">
                                    <h2>{item.title}</h2>
                                    <p className='portfoliao-p'>{item.description}</p>
                                </div>
                                <div className='flex heshtag'>
                                <div className='heshtag-second'>
                                {item.keyword.map((value, index) => {
                                        return (
                                            <p key={index}>{value}</p>
                                        )
                                    })}
                                </div>
                                </div>
                            </div>
                    </article>
                )}

            </section>
        </div>
    )
}
