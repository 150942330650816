
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { Toaster } from "react-hot-toast";

import Navbar from './camponets/Navbar/Navbar';
import Blog from './pages/Blog/Blog';
import Careers from './pages/Careers/Careers';
import ContactUs from './pages/ContactUs/ContactUs';
import Home from './pages/Home/Home';
import Portfolio from './pages/Portfolio/Portfolio';
import Products from './pages/Products/Products';
import Services from './pages/Services/Services';
import WebDevelopment from './pages/Services/WebDevelopment/WebDevelopment';
import MobileApps from './pages/Services/MobileApps/MobileApps';
import ProductDevelopment from './pages/Services/ProductDevelopment/ProductDevelopment';
import UIUXDesign from './pages/Services/UIUXDesign/UIUXDesign';
import QAService from './pages/Services/QAService/QAService';
import IoTService from './pages/Services/IoTService/IoTService';
import Technologies from './pages/Technologies/Technologies';
import About from './pages/Abouts/About';
import Footer from './camponets/Footer/Footer';
import { useLayoutEffect, useState } from 'react';
import CareersLife from './pages/Careers/CareersLife';
import ReactJs from './pages/Technologies/Frontend/ReactJs/ReactJs';
import BlogDetails from './pages/Blog/BlogDetails';
import Angular from './pages/Technologies/Frontend/Angular/Angular';
import TypeScript from './pages/Technologies/Frontend/TypeScript/TypeScript';
import VueJs from './pages/Technologies/Frontend/VueJs/VueJs';
import DotNet from './pages/Technologies/Backend/DotNet/DotNet';
import Node from './pages/Technologies/Backend/Node/Node';
import ReactNative from './pages/Technologies/Mobile/ReactNative/ReactNative';
import Ios from './pages/Technologies/Mobile/Ios/Ios';
import Flutter from './pages/Technologies/Mobile/Flutter/Flutter';
import Android from './pages/Technologies/Mobile/Android/Android';
import Aws from './pages/Technologies/Infrastructure&DevOpsTechnologies/Aws/Aws';
import Azure from './pages/Technologies/Infrastructure&DevOpsTechnologies/Azure/Azure';
import GoogleCloud from './pages/Technologies/Infrastructure&DevOpsTechnologies/GoogleCloud/GoogleCloud';
import Selenium from './pages/Technologies/Infrastructure&DevOpsTechnologies/Selenium/Selenium';
import DigitalTransformation from './pages/Services/DigitalTransformation/DigitalTransformation';
import DedicatedDevelopers from './pages/Services/DedicatedDevelopers/DedicatedDevelopers';
import SQLServer from './pages/Technologies/Backend/SQlServer/SQLServer';
import Native from './pages/TechnologiesNew/MobileNew/Native/Native';

function App() {
  const { pathname } = useLocation();
  const [loading, setloading] = useState(true)

  useLayoutEffect(() => {
    setloading(true)
    window.scrollTo(0, 0);
    setTimeout(() => {
      setloading(false)
    }, 200);
  }, [pathname]);
  return (
    <>

      {loading && <div style={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><span className='loader'></span></div>}
      {!loading && <div className='screen-animation'>
        <Navbar />
        <div style={{ zIndex: '99999999999999999999', position: 'fixed', top: '0px' }}>
          <Toaster position="bottom-center" reverseOrder={false} />
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/web-development" element={<WebDevelopment />} />
          <Route path="/services/mobile-apps" element={<MobileApps />} />
          <Route path="/services/product-development" element={<ProductDevelopment />} />
          <Route path="/services/uiux-design" element={<UIUXDesign />} />
          <Route path="/services/qa-service" element={<QAService />} />
          <Route path="/services/iot-service" element={<IoTService />} />
          <Route path="/services/digital-transformation" element={<DigitalTransformation />} />
          <Route path="/services/dedicated-developers" element={<DedicatedDevelopers />} />
          <Route path="/product" element={<Products />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogdetails" element={<BlogDetails />} />
          <Route path="/career" element={<Careers />} />
          <Route path="/career/life" element={<CareersLife />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/technologies" element={<Technologies />} />
          {/* Front End Tech  */}
          <Route path="/technologies/reactjs" element={<ReactJs />} />
          <Route path="/technologies/angular" element={<Angular />} />
          <Route path="/technologies/typescript" element={<TypeScript />} />
          <Route path="/technologies/vuejs" element={<VueJs />} />
          {/* Backend Tech  */}
          <Route path="/technologies/dotnet" element={<DotNet />} />
          <Route path="/technologies/nodejs" element={<Node />} />
          <Route path="/technologies/sqlserver" element={<SQLServer />} />
          {/* Mobile Tech   */}
          <Route path="/technologies/reactnative" element={<ReactNative />} />
          <Route path="/technologies/ios" element={<Ios />} />
          <Route path="/technologies/flutter" element={<Flutter />} />
          <Route path="/technologies/android" element={<Android />} />
          {/* Infrastructure&DevOpsTechnologies Tech  */}
          <Route path="/technologies/aws" element={<Aws />} />
          <Route path="/technologies/azure" element={<Azure />} />
          <Route path="/technologies/googlecloud" element={<GoogleCloud />} />
          <Route path="/technologies/selenium" element={<Selenium />} />
           {/* Mobile Tech   */}
           <Route path="hire-react-native-developers" element={<Native />} />
        </Routes>
      </div>}
      {!loading && <Footer />}
    </>
  );
}

export default App;
