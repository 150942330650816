import React from 'react';
import { VscGraph } from "react-icons/vsc";
import './aboutbusiness.css'

export default function AboutBusiness({ CoreValueData }) {
    return (
        <div >
            <div className='business-main-card'>
                <div className='business-head'>
                    <div className='head-icon'>
                        <img className='head-icon-image' src="https://cdn.technolee.com/image/about/values.webp" alt='Values' />
                    </div>
                    <h2>Our Core Values</h2>
                </div>
                <div className='business-details'>
                    {CoreValueData.map((item, ind) => {
                        return <p key={ind}><span>{item.title}:</span> {item.description}</p>
                    })}
                </div>
            </div>
            <div className='flex-space-between' style={{ alignItems: 'flex-start' }}>
                <div className='business-card'>
                    <div className='head-icon'>
                        <img className='head-icon-image' src="https://cdn.technolee.com/image/about/whoweare.webp" alt='Whoweare' /></div>
                    <h2>Who We Are</h2>
                    <p>Technolee is a team of passionate and highly skilled professionals who are driven by a common goal: to create digital solutions that make a difference. We bring together a diverse range of expertise in software development, web development, digital transformation, and product development. Our collaborative approach fosters creativity and ensures that every project is approached with fresh ideas and innovative solutions.</p>
                </div>
                <div className='business-card2'>
                    <div className='head-icon'>
                        <img className='head-icon-image' src="https://cdn.technolee.com/image/about/whatwedo.webp" alt='Whatwedo' /></div>
                    <h2>What We Can Do</h2>
                    <p>Our capabilities extend across a spectrum of services, including software and web development, digital transformation, product development, and IoT solutions. We specialize in crafting custom software, designing captivating websites, and harnessing the power of IoT to connect the world. Whether you're a startup looking to establish a digital presence or an established enterprise seeking to innovate, we have the expertise to meet your unique needs.</p>
                </div>
                <div className='business-card2'>
                    <div className='head-icon'>
                        <img className='head-icon-image' src="https://cdn.technolee.com/image/about/mission.webp" alt='mission' /></div>
                    <h2>Our Mission</h2>
                    <p>Our mission is to empower businesses and individuals with cutting-edge technology solutions that drive growth, efficiency, and innovation. We are committed to providing the highest quality services and products, enabling our clients to remain at the forefront of their industries.</p>
                </div>
                <div className='business-card'>
                    <div className='head-icon'>
                        <img className='head-icon-image' src="https://cdn.technolee.com/image/about/vision.webp" alt='vision' /></div>
                    <h2>Our Vision</h2>
                    <p>Our vision is to be a global leader in technology and innovation. We aspire to continually push the boundaries of what are possible, creating solutions that have a positive impact on businesses and society at large. We aim to be a driving force in digital transformation and a catalyst for positive change.</p>
                </div>
            </div>
        </div>
    )
}
