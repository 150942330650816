import React from 'react'
import AboutCard from '../../camponets/AboutCard/AboutCard';
import Banner from '../../camponets/Banner/Banner';
import ExpertiseCard from '../../camponets/ExpertiseCard/ExpertiseCard';
import FAQs from '../../camponets/FAQs/FAQs';
import KeyBenefitsCard from '../../camponets/KeyBenefitsCard/KeyBenefitsCard';
import PageSectionSecondary from '../../camponets/Page-Section-Secondary/PageSectionSecondary';
import TechDetailsCard from '../../camponets/TechDetailesCard/TechDetailsCard';
import TechFancyCard from '../../camponets/TechFancyCard/TechFancyCard';
import Testimonials from '../../camponets/Testimonials/Testimonials';
import PageSectionMain from '../../camponets/page-Section-Main/PageSectionMain';


const TestiData = [
  { alt: "Matt Gaunt", name: "Matt Gaunt", Designation: "Fulfillment Director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar1.webp", description: " <b>Elite BDM</b> has truly revolutionized our business operations.As company representatives, the seamless management of dealers, efficient order processing, and instant access to a wealth of information have become a reality with this avant- garde iPad application.The offline - first approach ensures uninterrupted functionality,setting a new standard for efficiency indealer management.Kudos to the exceptional team at Technolee for delivering a cutting - edge solution that has transformed the way we operate,showcasing their remarkable technical prowess and commitment to excellence" },
  { alt: "Nitesh Joshi", name: "Nitesh Joshi", Designation: "Managing director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar5.webp", description: " <b>EazyMeds</b>, our go-to online pharmacy developed by Technolee, has been a game-changer for our medicine purchases. Whether we're looking for specific medications or uploading prescriptions, <b>EazyMeds</b> ensures a straightforward experience. Tracking orders is a breeze, and we appreciate the platform's design for its convenience in managing our health and wellness journey" },
  { alt: "Matt Gaunt", name: "Matt Gaunt", Designation: "Fulfillment Director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar1.webp", description: " Technolee has reimagined the mobile recharge experience with <b>SIMply</b>, a crossplatform marvel that revolutionizes how dealers engage with their services. This app not only streamlines quick top-ups but also introduces an innovative rewards system, injecting an exciting layer of gamification. With a user-friendly interface meticulously crafted by Technolee, managing users becomes effortlessly seamless. <b>SIMply</b> delivers an immersive and rewarding journey for dealers, showcasing Technolee's ingenuity in creating cutting-edge solutions that elevate the mobile recharge industry to new heights." },
  { alt: "Kaushal Mehta", name: "Kaushal Mehta", Designation: "Managing director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar5.webp", description: " For <b>Chemisto</b>, the B2B mobile app developed by Technolee, it has truly transformed our medical procurement process. The SaaS-based app simplifies the purchase of medicines, providing a seamless and efficient experience. <b>Chemisto</b> goes beyond being just an app; it's a catalyst for transforming B2B interactions in the pharmaceutical domain. Thanks to Technolee, we now have a reliable tool that significantly streamlines our operations in the pharmaceutical sector." },
  { alt: "Matt Gaunt", name: "Matt Gaunt", Designation: "Fulfillment Director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar1.webp", description: " Technolee has developed <b>Rewards</b>, a cross-platform solution tailored for distributors. This practical app provides an immersive merchandising experience, allowing distributors to easily redeem points, make purchases, and navigate transaction details seamlessly. It serves as a testament to Technolee's commitment to bridging the gap between convenience and functionality, offering a unified platform for distributors to enjoy a straightforward and rewarding experience." },
  { alt: "Jignesh Shah", name: "Jignesh Shah", Designation: "Managing director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar5.webp", description: " <b>Erasafe</b>, the FinTech application developed by Technolee, has empowered our financing company to efficiently manage retailers, wallets, redeem points, and handle user interactions seamlessly. This comprehensive app ensures a smooth journey from lead generation to deal completion, creating a seamless experience for financial entities like ours. Thanks to Technolee, we now have a reliable FinTech solution that streamlines our operations, contributing to our overall efficiency and success." },
]
export default function TechnologiesDetails({ TechName, AboutCardData, BannerData, TechDetailsCardData, TechFancyCardData, ExpertiseCardData, KeyBenefitsCardData, TestimonialsData }) {

  return (
    <>

      {BannerData.map((item, ind) => {
        return (
          <Banner
            key={ind}
            image={item.BannerimgUrl}
            IsButton={true}
            title={item.htmlString}
            description={item.BennerDecription}
            alt={item.alt}
          />
        )
      })}


      <div className='page-content container'>
        {AboutCardData.map((item, ind) => {
          return (
            <AboutCard key={ind} isLeft={true} title='Why Choose Us'
              mainTitle={item.AboutTitle}
              description={item.AboutDecription}
              link='' image={item.imgUrl}
              alt={item.alt}
            />
          )
        })
        }
        <TechDetailsCard TechDetailsCardData={TechDetailsCardData} />
      </div>

      <div className='tech-expertise-main'>
        <PageSectionMain title='OUR EXPERTISE' />
        <PageSectionSecondary title={`Our Expertise with ${TechName} Development`} />
        <div className='tech-expertise container'>
          <ExpertiseCard ExpertiseCardData={ExpertiseCardData} />
        </div>
      </div>

      <div className='page-content container'>
        <div>
          <PageSectionMain title='WHAT WE DO' />
          <PageSectionSecondary title={`Our ${TechName} Development Services`} />
          <TechFancyCard TechFancyCardData={TechFancyCardData} />
        </div>
        <div>
          <PageSectionMain title='OUR EXPERTISE' />
          <PageSectionSecondary title={`Our Expertise with ${TechName} Development`} />
          <KeyBenefitsCard KeyBenefitsCardData={KeyBenefitsCardData} />
        </div>

        <div>
          <PageSectionMain title='TESTIMONIAL' />
          <PageSectionSecondary title='What Our Clients Say' />
          <Testimonials TestimonialsData={TestiData} />
        </div>
        <div className='faqs-main'>
          <PageSectionMain title='FIND USEFUL' />
          <PageSectionSecondary title='Frequently Asked Questions' />
          <FAQs TestimonialsData={TestimonialsData} />
        </div>
      </div>

    </>
  )
}
