import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../camponets/Banner/Banner';
import PageSectionSecondary from '../../camponets/Page-Section-Secondary/PageSectionSecondary';
import Symbocs from '../../camponets/Symbocs/Symbocs';
import AdCard from '../../camponets/AdCard/AdCard'



export default function CareersLife() {
    const htmlString = '<span class="text-highlight">Life </span>@ Technolee';

    const BenifitCardData = [
        { imgUrl: "https://cdn.technolee.com/image/career/lifewithtechnolee/dayworkweek.webp", title: '5-Day Workweek', alt: "dayworkweek" },
        { imgUrl: "https://cdn.technolee.com/image/career/lifewithtechnolee/medicalinsurance.webp", title: 'Medical Insurance', alt: "medicalinsurance" },
        { imgUrl: "https://cdn.technolee.com/image/career/lifewithtechnolee/flexibleworkhours.webp", title: 'Flexible Work Hours', alt: "flexibleworkhours" },
        { imgUrl: "https://cdn.technolee.com/image/career/lifewithtechnolee/professionaldevelopment.webp", title: 'Professional Development', alt: "professionaldevelopment" },
        { imgUrl: "https://cdn.technolee.com/image/career/lifewithtechnolee/innovativeprojects.webp", title: 'Innovative Projects', alt: "innovativeprojects" },
        { imgUrl: "https://cdn.technolee.com/image/career/lifewithtechnolee/diverseandinclusiveculture.webp", title: 'Diverse and Inclusive Culture', alt: "diverseandinclusiveculture" },
        { imgUrl: "https://cdn.technolee.com/image/career/lifewithtechnolee/competitivecompensation.webp", title: 'Competitive Compensation', alt: "competitivecompensation" },
        { imgUrl: "https://cdn.technolee.com/image/career/lifewithtechnolee/teamcelebrations.webp", title: 'Team Celebrations', alt: "teamcelebrations" },
        { imgUrl: "https://cdn.technolee.com/image/career/lifewithtechnolee/worklifebalance.webp", title: 'Work-Life Balance', alt: "worklifebalance" },
        { imgUrl: "https://cdn.technolee.com/image/career/lifewithtechnolee/referralbonus.webp", title: 'Referral Bonus', alt: "referralbonus" },
        { imgUrl: "https://cdn.technolee.com/image/career/lifewithtechnolee/opendoorpolicy.webp", title: 'Open Door Policy', alt: "opendoorpolicy" },
        { imgUrl: "https://cdn.technolee.com/image/career/lifewithtechnolee/appreciationsrewards.webp", title: 'Appreciations & Rewards', alt: "appreciationsrewards" },
    ]

    const AdcardData = [
        { buttonname: 'Explore Opportunities', link: '/career', Title: "Elevate Your Career with Technolee's Innovation Hub", SubTitle: 'Unleash Your Potential in a World of Limitless Possibilities', Description: 'At Technolee, we believe in fostering innovation, creativity, and collaboration. Join our dynamic team and embark on a journey where your skills are valued, and your potential is unleashed. We are on the lookout for passionate individuals who are ready to contribute to cutting-edge projects, solve complex challenges, and drive technological advancements.', }
    ]

    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <title>Life @ Technolee - Join Our Innovative Work Environment</title>
                <meta name="description" content=": Explore life at Technolee, where innovation, diversity, and career growth flourish. Join our dynamic team and enjoy competitive benefits like 5-day workweeks, medical insurance, flexible work hours, and more." />
                <meta name="keywords" content="life at Technolee, work culture, benefits, career growth, diversity, 5-day workweek, medical insurance, flexible hours, Technolee" />
            </Helmet>

            <Banner
                alt={'Life Banner'}
                image="https://cdn.technolee.com/image/career/lifewithtechnolee/lifebanner.webp"
                title={htmlString}
                description="Discover a work culture that celebrates innovation, growth, and inclusivity. Explore our competitive benefits, including 5-day workweeks, medical coverage, flexible schedules, and more."
            />
            <div className='page-content container'>
                <div >
                    <PageSectionSecondary title='Some Reasons To Join Us' />
                    <Symbocs data={BenifitCardData} />
                </div>
                <AdCard AdcardData={AdcardData} />
            </div>
        </>
    )
}
