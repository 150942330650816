import React, { useEffect, useState } from 'react'
import { FaRegThumbsUp } from "react-icons/fa";
import './keystatistics.css';
import CountUp from "react-countup";

export default function KeyStatistics() {
    const [count, setcount] = useState(false);
    useEffect(() => {

        const handleScroll = () => {
            if (window.scrollY >= 3250) {
                setcount(true)
            } else {
                setcount(false)
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div>
            <div className='flex-space-between keycard-main'>
                <div className='keycard'>
                <div className='flex-space-between'>
                    <FaRegThumbsUp className='keycard-icon' />
                    <h2>{count == true && <CountUp
                        start={0}
                        end={40}
                        duration={4}
                    />}+</h2>
                    </div>
                    <h3>Globally Clients </h3>
                </div>

                <div className='keycard'>
                <div className='flex-space-between'>
                    <FaRegThumbsUp className='keycard-icon' />
                    <h2>{count == true && <CountUp
                        start={0}
                        end={120}
                        duration={4}
                    />}+</h2>
                </div>
                    <h3>Project Completed</h3>
                </div>

                <div className='keycard'>
                <div className='flex-space-between'>
                    <FaRegThumbsUp className='keycard-icon' />
                    <h2>{count == true && <CountUp
                        start={0}
                        end={20}
                        duration={4}
                    />}+</h2>
                </div>
                    <h3>Team Size</h3>
                </div>

                <div className='keycard'>
                <div className='flex-space-between'>
                    <FaRegThumbsUp className='keycard-icon' />
                    <h2>{count == true && <CountUp
                        start={0}
                        end={90}
                        duration={4}
                    />}%</h2>
                </div>
                    <h3>On time Delivery</h3>
                </div>
                <div className='keycard'>
                <div className='flex-space-between'>
                    <FaRegThumbsUp className='keycard-icon' />
                    <h2>{count == true && <CountUp
                        start={0}
                        end={85}
                        duration={4}
                    />}%</h2>
                </div>
                    <h3>Client Retention</h3>
                </div>
                <div className='keycard'>
                <div className='flex-space-between'>
                    <FaRegThumbsUp className='keycard-icon' />
                    <h2>{count == true && <CountUp
                        start={0}
                        end={24}
                        duration={4}
                    />} Hours</h2>
                </div>
                    <h3>Response Time</h3>
                </div>
            </div>
        </div>
    )
}
