import React from 'react';
import { Helmet } from 'react-helmet';
import TechnologiesDetails from '../../../../camponets/TechnologiesDetails/TechnologiesDetails';


const TechName = "Node Js"

const BannerData = [
  { alt: "nodejsbanner", BannerimgUrl: 'https://cdn.technolee.com/image/technologies/technobennar/nodejsbanner.webp', htmlString: 'Transformative <span class="text-highlight">Node.js</span> Development for Your Business ', BennerDecription: "Unlock the full potential of JavaScript with our expert Node.js development services. From real-time applications to robust APIs, we leverage Node.js for creating scalable and efficient solutions." }
]

const AboutCardData = [
  { alt: "nodejstechnology", imgUrl: 'https://cdn.technolee.com/image/technologies/technoexceptional/nodejstechnology.webp', AboutTitle: "Partner with Our Node.js Development Team", AboutDecription: "Discover the reasons businesses trust us for their Node.js development needs." }
]

const TechDetailsCardData = [
  { title: 'Expert Node.js Developers', description: 'Our skilled Node.js developers bring a wealth of expertise, ensuring the development of scalable and efficient applications.' },
  { title: 'In-Depth JavaScript Knowledge', description: 'JavaScript is our forte. With in-depth knowledge, we leverage the language to its full potential, creating dynamic and responsive applications.' },
  { title: 'Innovative Approach', description: 'We embrace innovation, incorporating the latest features and advancements in Node.js to deliver cutting-edge applications.' },
  { title: 'Scalable Solutions', description: 'Scalability is a priority. Our Node.js solutions are designed to grow with your business, accommodating increased user demands seamlessly.' },
  { title: 'Collaborative Development', description: 'Collaboration is key to success. We involve you in the development process, ensuring transparency, effective communication, and your active participation.' },
]

const ExpertiseCardData = [
  { title: 'Real-Time Application Development', description: 'Leverage Node.js for real-time applications that demand instant updates and interactions, ensuring a seamless user experience.' },
  { title: 'API Development with Node.js', description: 'Create robust and efficient APIs using Node.js, facilitating smooth communication between different parts of your application.' },
  { title: 'Scalable Node.js Solutions', description: 'Embrace the microservices architecture with our Node.js development, creating modular and easily maintainable applications.' },
  { title: 'Custom Node.js Development', description: 'Tailor-made solutions are our expertise. We provide custom Node.js development services, ensuring applications align precisely with your unique requirements.' },
]

const TechFancyCardData = [
  { title: 'Real-Time Application Development', description: 'Craft real-time applications that deliver instant updates and interactions, enhancing user engagement.' },
  { title: 'API Development with Node.js', description: 'Create efficient APIs using Node.js, facilitating seamless communication between different parts of your application.' },
  { title: 'Scalable Node.js Solutions', description: 'Scalability is our focus. Our Node.js solutions are designed to grow with your business, ensuring optimal performance.' },
  { title: 'Microservices with Node.js', description: 'Adopt the microservices architecture for modular and easily maintainable applications with our Node.js development services.' },
  { title: 'Custom Node.js Solutions', description: 'Tailor-made solutions for your unique business requirements. Our custom Node.js development ensures applications that stand out in functionality and design.' },
  { title: 'Maintenance and Support', description: 'Beyond development, we offer ongoing maintenance and support services, ensuring your Node.js applications remain secure, up-to-date, and optimized.' },

]


const KeyBenefitsCardData = [
  { alt: "realtime", title: 'Real-Time Capabilities', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/realtime.webp', description: 'Node.js excels in real-time capabilities, making it ideal for applications that require instant updates and interactions.' },
  { alt: "scalability", title: 'Scalability and Efficiency', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/scalability.webp', description: 'Scalability is inherent in Node.js. Enjoy efficient and scalable applications that can handle increased user loads seamlessly.' },
  { alt: "integration", title: 'Versatile Integration', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/integration.webp', description: 'Node.js seamlessly integrates with various databases, frameworks, and libraries, ensuring a versatile and well-connected application ecosystem.' },
  { alt: "integration2", title: 'JavaScript Everywhere', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/integration2.webp', description: 'With Node.js, you can use JavaScript for both server-side and client-side development, promoting code consistency and developer efficiency' },
  { alt: "ecosystem", title: 'Large Ecosystem of Modules', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/ecosystem.webp', description: 'Node.js has a vast ecosystem of modules, allowing developers to leverage pre-built functionalities and speed up the development process.' },
  { alt: "support", title: 'Active Community Support', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/support.webp', description: 'Benefit from an active and supportive Node.js community, ensuring continuous updates, security patches, and a wealth of resources for developers.' },

]

const TestimonialsData = [
  { title: 'What Is the Advantage of Choosing Node.js for Real-Time Applications?', description: 'Node.js excels in real-time capabilities, making it ideal for applications that require instant updates and interactions.' },
  { title: 'How Long Does It Take to Develop a Node.js Application?', description: 'Development time varies based on project complexity. Our team ensures efficient development without compromising quality.' },
  { title: 'Can You Migrate an Existing App to Node.js?', description: 'Yes, we provide migration services to Node.js, ensuring your existing app benefits from its real-time capabilities and scalability.' },
  { title: 'What Are the Key Features of a Node.js Application?', description: 'Node.js applications boast real-time capabilities, scalability, versatile integration, JavaScript everywhere, a large ecosystem of modules, and active community support.' },
  { title: 'Node.js Development More Expensive Than Other Technologies?', description: 'Node.js development offers cost-effective solutions with its efficiency, scalability, and versatile integration capabilities.' },
  { title: 'How Do You Ensure the Scalability of Node.js Applications?', description: 'Scalability is inherent in Node.js. We design applications with scalability in mind, ensuring optimal performance under increased user loads.' },
  { title: 'Can Node.js Integrate with Different Databases and Frameworks?', description: 'Yes, Node.js seamlessly integrates with various databases, frameworks, and libraries, ensuring a versatile and well-connected application ecosystem.' },
  { title: 'What Is the Role of Node.js in Microservices Architecture?', description: 'Node.js plays a crucial role in microservices architecture, allowing for the creation of modular and easily maintainable applications.' },

]

export default function Node() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Node.js Solutions by Technolee - Powering Server-Side Development</title>
        <meta name="description" content="Node.js, Server-Side Development, API Development, Node.js Services, JavaScript, Backend Development, Server Optimization" />
        <meta name="keywords" content="Technolee provides cutting-edge Node.js solutions for robust server-side development, efficient API creation, and backend optimization. Explore our expertise in leveraging JavaScript for your server-side success." />
      </Helmet>

      <TechnologiesDetails TechName={TechName} AboutCardData={AboutCardData} BannerData={BannerData} TechDetailsCardData={TechDetailsCardData} TechFancyCardData={TechFancyCardData} ExpertiseCardData={ExpertiseCardData} KeyBenefitsCardData={KeyBenefitsCardData} TestimonialsData={TestimonialsData} />
    </>
  )
}
