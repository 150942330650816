import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../camponets/Banner/Banner';
import ServicesCard from '../../../camponets/ServicesCard/ServicesCard';


export default function MobileApps() {
    const crossPlatformSubServices = [{ name: 'iPhone & iPad App Development' }, { name: 'Apple TV & Apple Watch Development' }, { name: 'Android Phone & Tabs App Development' }, { name: 'Android TV & Chromebook Apps Development' }]
    const iOSSubServices = [{ name: 'Consumer Apps' }, { name: 'Enterprise Apps' }, { name: 'Healthcare Apps' }, { name: 'Educational Apps' }]
    const androidSubServices = [{ name: 'Consumer Apps' }, { name: 'Business Apps' }, { name: 'Entertainment Apps' }, { name: 'Travel Apps' }]

    const AllServices = [{
        id: "platform-apps",
        image: "https://cdn.technolee.com/image/services/mobileappsimages/crossplatformapps.webp",
        title: "Cross Platform Apps",
        description: "Our Cross Platform Apps development service offers a cost-effective and efficient way to reach a wide audience on both iOS and Android platforms. We harness the power of leading cross-platform frameworks like Flutter and React Native to create apps with a single codebase that look and feel native on both platforms. This approach not only saves development time and costs but also ensures a consistent user experience. Whether you need a consumer-facing app or an internal tool, we deliver versatile solutions that cater to a broad range of industries. Our Expertise includes:",
        iconData: [],
        subServicesData: crossPlatformSubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "crossplatformapps"
    }, {
        id: "ios-apps",
        image: "https://cdn.technolee.com/image/services/mobileappsimages/nativeiosapps.webp",
        title: "Native iOS Apps",
        description: "Our Native iOS Apps development service is dedicated to crafting exceptional apps exclusively for Apple's iOS platform. We employ Swift and Objective-C to create apps that seamlessly integrate with the iOS ecosystem. These apps are optimized for iOS devices, ensuring a premium user experience. Types of apps we specialize in include:",
        iconData: [], subServicesData: iOSSubServices,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "nativeiosapps"

    }, {
        id: "android-apps",
        image: "https://cdn.technolee.com/image/services/mobileappsimages/nativeandroidapp.webp",
        title: "Native Android Apps",
        description: "Our Native Android Apps development service is committed to delivering top-tier apps tailored exclusively for the Android platform. We use Java and Kotlin to build apps optimized for Android devices, ensuring an authentic Android experience. Our expertise spans various types of Android apps, including:",
        iconData: [],
        subServicesData: androidSubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "nativeandroidapp"

    }]

    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                <title>Mobile Apps Development Services - Cross Platform, Native iOS, Native Android Apps</title>
                <meta name="description" content="Explore our mobile app development services, including cross-platform apps, native iOS apps, and native Android apps. We create engaging and platform-specific mobile solutions to meet your unique needs." />
                <meta name="keywords" content="mobile app development services, cross-platform apps, native iOS apps, native Android apps, Technolee" />
            </Helmet>

            <Banner
                alt={"Mobile Apps"}
                image="https://cdn.technolee.com/image/services/mobileappsimages/mobilebanner.webp"
                title="Crafting Exceptional Mobile Experiences"
                description="Elevate your mobile presence with our customized mobile app solutions. Discover our expertise in cross-platform apps, native iOS, and native Android app development."
            />
            <div className='page-content container'>
                <div>
                    {AllServices.map((item, ind) =>
                        <ServicesCard
                            key={ind}
                            id={item.id}
                            image={item.image}
                            title={item.title}
                            description={item.description}
                            iconData={item.iconData}
                            alt={item.alt}
                            subServicesData={item.subServicesData}
                            isLeft={item.isLeft}
                            isFullWidth={item.isFullWidth}
                            isReadMore={item.isReadMore}
                        />
                    )}
                </div>
            </div>
        </>
    )
}