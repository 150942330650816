import React from 'react';
import './TechnologiesTitleSubtitle.css';

export default function TechnologiesTitleSubtitle({ title, subtitle, isSize, titleColor, subtitleColor, hrColor }) {
    return (
        <div className='TechnologiesTitleSubtitle-main'>
            <div className='TechnologiesTitleSubsitle flex-center'>
                <div style={{ color: titleColor }}>
                    <hr style={{ backgroundColor: hrColor, borderColor: hrColor }} /> 
                    {title}
                    <hr style={{ backgroundColor: hrColor, borderColor: hrColor }} />
                </div>
            </div>
            <div className={isSize === true ? "change-size flex-center" : 'TechnologiesTitleSubsitle-Secondary flex-center'}>
                <p style={{ color: subtitleColor }}>{subtitle}</p>
            </div>
        </div>
    );
}
