import React, { useCallback, useRef, useState } from 'react'
import './testimonials.css'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css/navigation';
import "swiper/css";
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi'

export default function Testimonials({ TestimonialsData }) {
    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    return (
        <>
            <div className='testimonials-swiper'>
                <Swiper slidesPerView={2} ref={sliderRef}
                    breakpoints={{
                        250: {
                            slidesPerView: 1,
                        },
                        350: {
                            slidesPerView: 1,
                        },
                        750: {
                            slidesPerView: 2,
                        },
                    }} className="mySwiper">
                    {TestimonialsData.map((item, ind) => {
                        const imageUrl = item.imageUrl.replace(/\.[^/.]+$/, "") + ".webp";
                        return (
                            <SwiperSlide key={ind}>
                                <div className='testimonials-slide-main'>
                                    <p className='testi-dec' dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                    <div className='flex-space-between testimonials-card'>
                                        <div className='testimonials-img'>
                                            <img 
                                                className='testimonials-images' 
                                                src={imageUrl} 
                                                alt={item.alt} 
                                                srcSet={`${imageUrl} 1x, ${imageUrl.replace('.webp', '@2x.webp')} 2x`}
                                                loading="lazy"
                                            />
                                        </div>
                                        <div className='testimonials-details'>
                                            <h3>{item.name}</h3>
                                            <p>{item.Designation}</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                <div className='flex-center swiper-button'>
                    <div className="prev-arrow" onClick={handlePrev}><BiArrowToLeft /></div>
                    <div className="next-arrow" onClick={handleNext}><BiArrowToRight /></div>
                </div>
            </div>
        </>
    )
}
