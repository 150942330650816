import React, {  Suspense, lazy, useRef, useState } from 'react'
import './applyform.css'
import { toast } from 'react-hot-toast';

import ReactPhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { PostApply } from '../../Api/Api';
import GoogleRecaptcha from '../GoogleRecaptcha/GoogleRecaptcha';


let success1 = {
    ApplyingFor: false,
    Name: false,
    EmailAddress: false,
    PhoneNo: false,
    Message: false,
    ReCaptcha: false,
    DocumentData:false
}

export default function ApplyForm({ positionName, setOpen }) {
    // const ref = useRef();
    const [Success, setSuccess] = useState(success1);
    const [formData, setFormData] = useState({
        ID: "0",
        ApplyingFor: positionName, // Default option
        Name: "",
        EmailAddress: "",
        CountryCode: '+91',
        PhoneNo: "",
        Message: "",
        IPAddress: '192.168.1.1',
        OriginalDocumentName: '',
        DocumentName: '',
        DocumentURL: '',
        DocumentData: "", // Default option

    });
   

    const handleChange = (e) => {
        const { name, value } = e.target;
        validation(name, value)
        setFormData({ ...formData, [name]: value });
    };

    const handlefile = (e) => {
        let ducumentName;
        const reader = new FileReader();

        if (e.target.files.length == 0) {
            alert('file not found')
        } else {
            reader.readAsDataURL(e.target.files[0]);
            ducumentName = Math.floor(Math.random() * 100) * 848488546546546 + ".pdf"
            reader.onload = () => {
                setFormData({ ...formData, DocumentData: reader.result, DocumentName: ducumentName, OriginalDocumentName: e.target.files[0].name, DocumentURL: e.target.files[0].name });
            };
            reader.onerror = (err) => {
                console.log(err);
            };
        };
    };


    const validation = async (name, value) => {
        if ((name == "ApplyingFor" && value == "")) {
            setSuccess({
                ...Success,
                ApplyingFor: true,

            });
        } else if (name == "ApplyingFor" && value != "") {
            setSuccess({
                ...Success,
                ApplyingFor: false,
            });
        } else if ((name == "Name" && value == "")) {
            setSuccess({
                ...Success,
                Name: true,

            });
        } else if (name == "Name" && value !== "") {
            setSuccess({
                ...Success,
                Name: false,
            });
        } else if ((name == "EmailAddress" && value == "")) {
            setSuccess({
                ...Success,
                EmailAddress: true,

            });
        } else if (name == "EmailAddress" && value !== "") {
            setSuccess({
                ...Success,
                EmailAddress: false,
            });
        } else if (formData.PhoneNo.length == "") {
            setSuccess({
                ...Success,
                PhoneNo: true,
            });
        } else if (formData.PhoneNo.length > 13) {
            setSuccess({
                ...Success,
                PhoneNo: true,
            });
        } else if (formData.PhoneNo.length < 13) {
            setSuccess({
                ...Success,
                PhoneNo: true,
            });

        } else if ( formData.DocumentData == "") {

            setSuccess({
                ...Success,
                DocumentData: true,
                PhoneNo: false,
                
            });
        } else if (name == "Message" && value == "") {

            setSuccess({
                ...Success,
                Message: true,
                DocumentData: false,
                PhoneNo: false
            });
        } else if (name == "Message" && value !== "") {
            setSuccess({
                ...Success,
                Message: false,
                DocumentData: false,
                PhoneNo: false,
                ReCaptcha:true
            });
        } else {
            const loading = toast.loading("In progress...");
            try {
                const { data } = await PostApply(formData);
                toast.dismiss(loading);
                toast.success(data.message, {
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                        maxWidth: "700px"
                    },
                    duration: 6000,
                });
                setSuccess({ ...Success, ReCaptcha: false })
                setFormData({ ...formData, Recaptcha: "" })

                setOpen(false);
            } catch (err) {
                toast.dismiss(loading);
                toast.error(err);
            };
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        validation("", "")
    };


    return (
        <div className="apply-form-main" >
            <form onSubmit={handleSubmit} autoComplete="true">
                <div className="apply-dropdown">
                    <label htmlFor='applying'>Applying For</label>
                    <div className="select-apply-form-btn">
                        <select
                            id='applying'
                            required
                            autoComplete="true"
                            name="ApplyingFor"
                            value={formData.ApplyingFor}
                            onChange={handleChange}>
                            <option value="React Developer">ReactJS Developer</option>
                            <option value="React Native Developer">React Native Developer</option>
                            <option value="ASP.NET/C# Developer">ASP.NET/C# Developer</option>
                            <option value="Business Development Manager">Business Development Manager</option>
                            <option value="Project Manager">Project Manager</option>
                        </select>
                    </div>
                    {Success.ApplyingFor && <p>Please Enter Applying For</p>}
                </div>
                <div className='flex-space-between form-feld'>
                    <div className="apply-form-group">
                        <label htmlFor='name'>Name</label>
                        <input
                            id='name'
                            type="text"
                            autoComplete="true"
                            name="Name"
                            value={formData.Name}
                            required
                            onChange={handleChange} />
                        {Success.Name && <p>Please Enter Name</p>}
                    </div>
                    <div className="apply-form-group">
                        <label htmlFor='email'>Email</label>
                        <input
                            id='email'
                            type="email"
                            autoComplete="true"
                            name="EmailAddress"
                            value={formData.EmailAddress}
                            required
                            onChange={handleChange} />
                        {Success.EmailAddress && <p>Please Enter Email Address</p>}
                    </div>
                    <div className="apply-form-group">
                        <label htmlFor='phone'>Phone</label>
                        <ReactPhoneInput
                            id='phone'
                            international
                            countryCallingCodeEditable={false}
                            withCountryCallingCode
                            defaultCountry="IN"
                            autoComplete="true"
                            name="PhoneNo"
                            textProps={{ maxLength: 10 }}
                            textInputProps={{ maxLength: 12 }}
                            value={formData.PhoneNo}
                            onChange={(value) => setFormData({ ...formData, PhoneNo: value })}
                        />
                        {Success.PhoneNo && <p>Please Enter Valid Phone No</p>}
                    </div>
                    <div className="apply-form-group apply-file-upload">
                        <label htmlFor='file'>Upload a Resume (.pdf, .doc,)</label>
                        <input
                            id='file'
                            type="file"
                            name="file"
                            required
                            autoComplete="true"
                            accept=".pdf, .doc, .docx" // Specify the accepted file types
                            onChange={handlefile} />
                    {Success.DocumentData && <p>Please Select file</p>}
                    </div>
                </div>
                <div className="apply-message">
                    <label htmlFor='message'>Message</label>
                    <textarea
                        id='message'
                        name="Message"
                        autoComplete="true"
                        required
                        value={formData.Message}
                        onChange={handleChange} />
                    {Success.Message && <p>Please Enter Message</p>}
                </div>
                {Success.ReCaptcha && <GoogleRecaptcha show={true} formData={formData} setFormData={setFormData}/>}

                {/* <Suspense fallback={renderLoader()}>

                    <GoogleRecaptcha setFormData={setFormData} formData={formData} />
                </Suspense> */}
                {/* {Success.ReCaptcha && <p className='recaptcha'>Please Verify ReCaptcha</p>} */}
                <div className='apply-form-btn'>
                    <button type="submit"> <span>Apply Now</span></button>
                </div>
            </form>
        </div>
    )
}
