import React, { Suspense, lazy, useState } from 'react';
import './projectinquiryform.css';
import { toast } from 'react-hot-toast';
import ReactPhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { PostProjectInqury } from '../../Api/Api';

const GoogleRecaptcha = lazy(() => import('../GoogleRecaptcha/GoogleRecaptcha'));

let success1 = {
  FirstName: false,
  LastName: false,
  EmailAddress: false,
  PhoneNo: false,
  Message: false,
  Service: false,
  Budget: false,
  HowSoonToStart: false,
  ReCaptcha: false
};

export default function ProjectInquiryForm() {
  const [Success, setSuccess] = useState(success1);
  const [formData, setFormData] = useState({
    ID: "0",
    FirstName: "",
    LastName: "",
    EmailAddress: "",
    CountryCode: '+91',
    PhoneNo: "",
    Message: "",
    IPAddress: '192.168.1.1',
    Service: "",
    Budget: "",
    HowSoonToStart: "",
    Recaptcha: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const validateField = (name, value) => {
    let updatedSuccess = { ...Success };

    // Example validation logic
    if (name === "PhoneNo") {
      updatedSuccess.PhoneNo = value.length < 10 || value.length > 15;
    } else {
      updatedSuccess[name] = value === "";
    }

    setSuccess(updatedSuccess);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();  // Prevent the default form submission

    // Ensure all fields are validated
    const fields = ["FirstName", "LastName", "EmailAddress", "PhoneNo", "Message", "Service", "Budget", "HowSoonToStart"];
    fields.forEach(field => validateField(field, formData[field]));

    if (Object.values(Success).every(status => !status)) {
      const loading = toast.loading("In progress...");
      try {
        const { data } = await PostProjectInqury(formData);
        toast.dismiss(loading);
        toast.success(data.message, {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            maxWidth: "700px"
          },
          duration: 6000,
        });
        setSuccess(success1);
        setFormData({
          ID: "0",
          FirstName: "",
          LastName: "",
          EmailAddress: "",
          CountryCode: '+91',
          PhoneNo: "",
          Message: "",
          IPAddress: '192.168.1.1',
          Service: "",
          Budget: "",
          HowSoonToStart: "",
          Recaptcha: "",
        });
      } catch (err) {
        toast.dismiss(loading);
        toast.error(err.message);
      }
    }
  };

  return (
    <div className="project-form-main">
      <form onSubmit={handleSubmit}>
        <div className='flex-space-between form-feld'>
          {/* Form fields as before */}
          <div className="project-form-group">
            <label htmlFor="firstname">First Name</label>
            <input
              id='firstname'
              required
              type="text"
              name="FirstName"
              placeholder='First Name'
              value={formData.FirstName}
              onChange={handleChange}
            />
            {Success.FirstName && <p>Please Enter First Name</p>}
          </div>
          <div className="project-form-group">
            <label htmlFor='lastname'>Last Name</label>
            <input
              id='lastname'
              required
              type="text"
              name="LastName"
              placeholder='Last Name'
              value={formData.LastName}
              onChange={handleChange}
            />
            {Success.LastName && <p>Please Enter Last Name</p>}
          </div>
          <div className="project-form-group">
            <label htmlFor='email'>Email</label>
            <input
              id='email'
              required
              type="email"
              name="EmailAddress"
              placeholder='Email'
              value={formData.EmailAddress}
              onChange={handleChange}
            />
            {Success.EmailAddress && <p>Please Enter Email Address</p>}
          </div>
          <div className="project-form-group" id='project-cantrycode'>
            <label htmlFor='phone'>Phone</label>
            <ReactPhoneInput
              id='phone'
              international
              countryCallingCodeEditable={false}
              withCountryCallingCode
              defaultCountry="IN"
              name="PhoneNo"
              required
              value={formData.PhoneNo}
              onChange={(value) => setFormData({ ...formData, PhoneNo: value })}
            />
            {Success.PhoneNo && <p>Please Enter Valid Phone No</p>}
          </div>
          <div className="project-form-group">
            <label htmlFor='service'>Select a Service</label>
            <div className="select-project-form-btn">
              <select
                id='service'
                required
                name="Service"
                value={formData.Service}
                onChange={handleChange}
              >
                <option value="" disabled>Select</option>
                <option value="Digital Transformation">Digital Transformation</option>
                <option value="Mobile Apps Development">Mobile Apps Development</option>
                <option value="Web Development">Web Development</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="Hiring Dedicated Developers">Hiring Dedicated Developers</option>
                <option value="QA Services">QA Services</option>
                <option value="Product Development">Product Development</option>
                <option value="IoT Solutions">IoT Solutions</option>
              </select>
            </div>
            {Success.Service && <p>Please Enter Service</p>}
          </div>
          <div className="project-form-group">
            <label htmlFor='budget'>Budget</label>
            <div className="select-project-form-btn">
              <select
                id='budget'
                required
                name="Budget"
                value={formData.Budget}
                onChange={handleChange}
              >
                <option value="" disabled>Select</option>
                <option value="$1K-$10K">$1K - $10K</option>
                <option value="$10K-$25K">$10K - $25K</option>
                <option value="$25K - $50K">$25K - $50K</option>
                <option value=">$50K">$50K+</option>
              </select>
            </div>
            {Success.Budget && <p>Please Enter Budget</p>}
          </div>
          <div className="project-form-group">
            <label htmlFor='start'>How soon you want to start?</label>
            <div className="select-project-form-btn">
              <select
                id='start'
                required
                name="HowSoonToStart"
                value={formData.HowSoonToStart}
                onChange={handleChange}
              >
                <option value="" disabled>Select</option>
                <option value="Immediately">Immediately</option>
                <option value="In few Weeks">In few Weeks</option>
                <option value="In few Months">In few Months</option>
              </select>
            </div>
            {Success.HowSoonToStart && <p>Please Enter How Soon To Start?</p>}
          </div>
          <div className="project-form-group project-message-box">
            <label htmlFor='message'>Message</label>
            <textarea
              id='message'
              required
              name="Message"
              type='text'
              value={formData.Message}
              onChange={handleChange}
            />
            {Success.Message && <p>Please Enter Email Message</p>}
          </div>
          {/* Lazy load GoogleRecaptcha */}
          {Success.ReCaptcha && (
            <Suspense fallback={<div>Loading Captcha...</div>}>
              <GoogleRecaptcha show={true} formData={formData} setFormData={setFormData} />
            </Suspense>
          )}
        </div>
        <div className='project-form-btn'>
          <button type="submit"> <span>Let's Talk</span></button>
        </div>
      </form>
    </div>
  );
}
