import React from 'react';
import { Helmet } from 'react-helmet';
import AboutCard from '../../camponets/AboutCard/AboutCard';
import Banner from '../../camponets/Banner/Banner';
import Card from '../../camponets/Card/Card';
import PageSectionSecondary from '../../camponets/Page-Section-Secondary/PageSectionSecondary';
import Tab from '../../camponets/Tabs/Tabs';
import WorkCard from '../../camponets/WorkingProcess/WorkCard';
import PageSectionMain from '../../camponets/page-Section-Main/PageSectionMain';
import Testimonials from '../../camponets/Testimonials/Testimonials';
import './home.css'

export default function Home({ animation }) {
  const htmlString = 'Empowering Innovation & Transformation <span class="text-highlight"></span>';

  const CardData = [
    { alt: 'Mobile Apps', isButton: true, Link: '/services/mobile-apps', image: "https://cdn.technolee.com/image/services/appdev.webp", title: "Software Development", description: " Our software development services are the bedrock of innovation. We craft custom software solutions tailored to your unique needs, leveraging cutting-edge technologies to enhance efficiency, streamline processes, and drive business growth. From concept to deployment, we're your partner in creating software that makes a difference." },
    { alt: 'Web Development', isButton: true, Link: '/services/web-development', image: "https://cdn.technolee.com/image/services/webdev.webp", title: 'Web Development', description: "We're the architects of captivating web experiences. Our web development services create websites that leave a lasting impression. Whether you need an engaging online presence, e-commerce platform, or a web application, we bring your digital vision to life, combining design aesthetics with user-friendly functionality." },
    { alt: 'Digital Transformation', isButton: true, Link: '/services/digital-transformation', image: "https://cdn.technolee.com/image/services/digitaltransformation.webp", title: 'Digital Transformation', description: "Embrace the future of technology with our digital transformation services. We help businesses evolve, leveraging digital solutions to optimize operations, enhance customer experiences, and gain a competitive edge. From digital strategy to implementation, we guide you through the process of innovation and change." },
    { alt: 'Product Development', isButton: true, Link: '/services/product-development', image: "https://cdn.technolee.com/image/services/productdevelopment.webp", title: 'Product Development', description: "Turn your ideas into reality with our product development services. From concept validation to market-ready products, we offer end-to-end support. We create software and hardware products that cater to various industries, empowering you to disrupt markets and drive innovation." },
    { alt: 'IOT Service', isButton: true, Link: '/services/iot-service', image: "https://cdn.technolee.com/image/services/iot.webp", title: 'IoT Solutions', description: "Our IoT solutions are designed to connect and transform the world. We harness the power of the Internet of Things to provide scalable and secure connectivity, enabling data-driven decisions. Whether you need industrial automation, smart home solutions, or connected devices, we're your IoT partner." },
    { alt: 'QA Services', isButton: true, Link: '/services/qa-service', image: "https://cdn.technolee.com/image/services/qualityassurance.webp", title: 'QA Services', description: "Ensure the quality, reliability, and performance of your digital solutions with our QA services. We offer a comprehensive range of testing and quality assurance services, including manual and automated testing, load testing, security testing, and more. Our goal is to deliver flawless and reliable products that exceed user expectations." },
  ]

  const DevlopmentCardData = [
    { alt: 'Discovery', isLineRight: true, IconUrl: "https://cdn.technolee.com/image/home/discovery.webp", isLine: false, title: "Discovery", description: "The Discovery phase is where we delve deep into your project's requirements. We conduct stakeholder interviews, analyze business goals, and define the project's scope. The outcome is a comprehensive project plan that outlines the project's objectives and expectations." },
    { alt: 'Design', isLineRight: true, IconUrl: "https://cdn.technolee.com/image/home/design.webp", isLine: true, title: 'Design', description: "In the Design phase, we focus on creating the blueprint for your software. This includes UI/UX design, information architecture, wireframing, and prototyping. Our goal is to ensure the software's user interface is intuitive, visually appealing, and aligned with your brand." },
    { alt: 'Development', isLineRight: true, IconUrl: "https://cdn.technolee.com/image/home/development.webp", isLine: true, title: 'Development', description: "The Development phase is where the actual coding takes place. Our developers write the software, following the best coding practices and using the latest technology stacks. Frequent code reviews and unit testing are integral to maintaining code quality." },
    { alt: 'Software Testing', isLineRight: false, IconUrl: "https://cdn.technolee.com/image/home/testing.webp", isLine: true, title: 'Testing and Quality Assurance', description: "Our Testing and Quality Assurance phase ensures the software's functionality, reliability, and security. This includes manual and automated testing, load testing, security testing, and user acceptance testing. Any issues are identified and resolved promptly." },
    { alt: 'Implementation', isLineRight: true, IconUrl: "https://cdn.technolee.com/image/home/implementation.webp", isLine: false, title: 'Post-Implementation Review', description: "Following deployment, we conduct a Post-Implementation Review. This assessment evaluates the success of the project, ensuring it aligns with the initial objectives. Any remaining issues are addressed, and feedback is gathered for future enhancements." },
    { alt: 'Maintenance ', isLineRight: true, IconUrl: "https://cdn.technolee.com/image/home/maintenance.webp", isLine: true, title: 'Maintenance and Support', description: "The Maintenance and Support phase is ongoing. We provide regular updates and upgrades to keep the software current. We also address any issues and provide technical support. Performance monitoring and proactive measures are part of our commitment to long-term support." },
    { alt: 'Delivery', isLineRight: true, IconUrl: "https://cdn.technolee.com/image/home/delivery.webp", isLine: true, title: 'Delivery and Training', description: "After deployment, we deliver the software to your organization and provide user training. Our team ensures that your staff is well-equipped to use the software effectively. Any necessary documentation is provided." },
    { alt: 'Deployment', isLineRight: false, IconUrl: "https://cdn.technolee.com/image/home/deployment.webp", isLine: true, title: 'Deployment', description: "Once development and testing are complete, the software is deployed in the target environment. This phase also involves data migration, server setup, and configurations. Integration with other systems and databases is validated." },
  ]

  const TestimonialsData = [
    { alt: 'Client', name: "Matt Gaunt", Designation: "Fulfillment Director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar1.webp", description: " <b>Elite BDM</b> has truly revolutionized our business operations.As company representatives, the seamless management of dealers, efficient order processing, and instant access to a wealth of information have become a reality with this avant- garde iPad application.The offline - first approach ensures uninterrupted functionality,setting a new standard for efficiency indealer management.Kudos to the exceptional team at Technolee for delivering a cutting - edge solution that has transformed the way we operate,showcasing their remarkable technical prowess and commitment to excellence" },
    { alt: 'Client', name: "Nitesh Joshi", Designation: "Managing director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar5.webp", description: " <b>EazyMeds</b>, our go-to online pharmacy developed by Technolee, has been a game-changer for our medicine purchases. Whether we're looking for specific medications or uploading prescriptions, <b>EazyMeds</b> ensures a straightforward experience. Tracking orders is a breeze, and we appreciate the platform's design for its convenience in managing our health and wellness journey" },
    { alt: 'Client', name: "Matt Gaunt", Designation: "Fulfillment Director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar1.webp", description: " Technolee has reimagined the mobile recharge experience with <b>SIMply</b>, a crossplatform marvel that revolutionizes how dealers engage with their services. This app not only streamlines quick top-ups but also introduces an innovative rewards system, injecting an exciting layer of gamification. With a user-friendly interface meticulously crafted by Technolee, managing users becomes effortlessly seamless. <b>SIMply</b> delivers an immersive and rewarding journey for dealers, showcasing Technolee's ingenuity in creating cutting-edge solutions that elevate the mobile recharge industry to new heights." },
    { alt: 'Client', name: "Kaushal Mehta", Designation: "Managing director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar5.webp", description: " For <b>Chemisto</b>, the B2B mobile app developed by Technolee, it has truly transformed our medical procurement process. The SaaS-based app simplifies the purchase of medicines, providing a seamless and efficient experience. <b>Chemisto</b> goes beyond being just an app; it's a catalyst for transforming B2B interactions in the pharmaceutical domain. Thanks to Technolee, we now have a reliable tool that significantly streamlines our operations in the pharmaceutical sector." },
    { alt: 'Client', name: "Matt Gaunt", Designation: "Fulfillment Director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar1.webp", description: " Technolee has developed <b>Rewards</b>, a cross-platform solution tailored for distributors. This practical app provides an immersive merchandising experience, allowing distributors to easily redeem points, make purchases, and navigate transaction details seamlessly. It serves as a testament to Technolee's commitment to bridging the gap between convenience and functionality, offering a unified platform for distributors to enjoy a straightforward and rewarding experience." },
    { alt: 'Client', name: "Jignesh Shah ", Designation: "Managing director", imageUrl: "https://cdn.technolee.com/image/avatar/avatar5.webp", description: " <b>Erasafe</b>, the FinTech application developed by Technolee, has empowered our financing company to efficiently manage retailers, wallets, redeem points, and handle user interactions seamlessly. This comprehensive app ensures a smooth journey from lead generation to deal completion, creating a seamless experience for financial entities like ours. Thanks to Technolee, we now have a reliable FinTech solution that streamlines our operations, contributing to our overall efficiency and success." },
  ]

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Welcome to Technolee - Empowering Innovation and Transformation</title>
        <meta name="description" content="Technolee is your partner in software and web development, digital transformation, and product development. Discover our comprehensive services and commitment to innovation." />
        <meta name="keywords" content="Technolee, software development, web development, digital transformation, product development, innovation, technology solutions" />
      </Helmet>

      <Banner
        image="https://cdn.technolee.com/image/home/bannermain.webp"
        alt={'Digtial Transformation'}
        title={htmlString}
        description="Welcome to Technolee, where innovation meets technology. Explore our cutting-edge solutions in software development, web development, digital transformation, and product development."
      />
      <div className={`container`}>
      <div className='home-page-aboutcard'>
        <AboutCard isLeft={true} title='ABOUT US'
          alt={'Software Development Services'}
          mainTitle='We offer an all-encompassing resolution for every software challenge you encounter!'
          subTitle='For more than 5 years, Technolee has been delivering successful software development services'
          description=' Welcome to Technolee, your gateway to innovation and transformation in the digital world. Since 2017, we have been at the forefront of driving technological change, offering a range of services that cater to diverse industries. Our commitment to excellence, innovation, and client success sets us apart in the software and web development landscape.'
          link='/about' image="https://cdn.technolee.com/image/home/bannerabout.webp" />
      </div>
      </div>
      <div className='home-core-service'>
        <PageSectionMain title='OUR CORE SERVICES' />
        <PageSectionSecondary title='What We Provide' />
        <div className='flex homeservice-main'>
          {CardData.map((item, ind) => {
            return (
              <div className='homeservice-card' key={ind}>
                <Card isButton={item.isButton} Link={item.Link} image={item.image} title={item.title} description={item.description} alt={item.alt} cardHeight="210px" />
              </div>
            )
          })}
        </div>
      </div>
      <div className={`container home-tab `}>
        <PageSectionMain title='TECHNOLOGIES' />
        <PageSectionSecondary title='Technologies We Work with' />
        <Tab />
      </div>
      <div className='home-work-process'>
        <PageSectionMain title='WORKING PROCESS' />
        <PageSectionSecondary title='We Simplify Software Development Process' />
        <WorkCard data={DevlopmentCardData} />
      </div>

      <div className='container testimonials-contectus'>
        <PageSectionMain title='TESTIMONIAL' />
        <PageSectionSecondary title='What Our Clients Say' />
        <Testimonials TestimonialsData={TestimonialsData} />
      </div>
    </>
  )
}
