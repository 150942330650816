import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../camponets/Banner/Banner';
import PageSectionSecondary from '../../camponets/Page-Section-Secondary/PageSectionSecondary';
import TechCardZigZag from '../../camponets/TechCardZigZag/TechCardZigZag';
import PageSectionMain from '../../camponets/page-Section-Main/PageSectionMain';




export default function Technologies() {
  const htmlString = '<span class="text-highlight">Empowering </span> Your <span class="text-highlight">Digital </span>Journey ';

  const TechCardData = [
    {
      alt: "frontend", IsLeft: true, imgUrl: 'https://cdn.technolee.com/image/technologies/frontend.webp', title: "Frontend Technologies", dicription: 'We are a leading frontend technology solutions provider, specializing in custom application development. From initial consulting to ongoing maintenance and support, our dedicated team delivers end-to-end services. As a full-stack frontend development company, we bring expertise in creating responsive applications tailored to meet your unique business requirements. Elevate your user experience and brand image with our comprehensive frontend technology solutions.',
      TechData: [
        { alt: "reactnative", iconURL: 'https://cdn.technolee.com/image/services/reactnative.webp', readmoreUrl: "/technologies/reactjs", Techdicription: 'Harness the power of ReactJS for building interactive user interfaces. Our expertise in React enables us to create dynamic and responsive web applications, ensuring an engaging user experience.' },
        { alt: "vuejs", iconURL: 'https://cdn.technolee.com/image/services/vuejs.webp', readmoreUrl: "/technologies/vuejs", Techdicription: 'VueJS offers simplicity and flexibility for frontend development. We leverage VueJS to create intuitive and scalable user interfaces, providing seamless interactions and performance.' },
        { alt: "angular", iconURL: 'https://cdn.technolee.com/image/services/angular.webp', readmoreUrl: "/technologies/angular", Techdicription: "Angular, with its robust framework, is our choice for building dynamic web applications. We utilize Angular's features to deliver scalable and maintainable frontend solutions." },
        { alt: "typescript", iconURL: 'https://cdn.technolee.com/image/services/typescript.webp', readmoreUrl: "/technologies/typescript", Techdicription: "TypeScript enhances the development of scalable and maintainable code. Our proficiency in TypeScript ensures a robust and structured approach to frontend development." },
      ]
    },
    {
      alt: "backend", IsLeft: false, imgUrl: 'https://cdn.technolee.com/image/technologies/backend.webp', title: "Backend Technologies:", dicription: "We are a dynamic backend technology solutions provider, offering comprehensive services in consulting, development, and support. As a custom backend development company, we specialize in building scalable and secure applications. Our full-stack backend development expertise ensures the seamless integration of backend systems, empowering your digital infrastructure and optimizing business processes.",
      TechData: [
        { alt: "dotnet", iconURL: 'https://cdn.technolee.com/image/services/dotnet.webp', readmoreUrl: "/technologies/dotnet", Techdicription: ".NET provides a powerful framework for backend development. We leverage its versatility to build scalable and secure server-side applications, meeting diverse business needs." },
        { alt: "nodejs", iconURL: 'https://cdn.technolee.com/image/services/nodejs.webp', readmoreUrl: "/technologies/nodejs", Techdicription: "Node.js offers a lightweight and efficient runtime for building scalable backend services. We harness the speed and flexibility of Node.js to create high-performance applications." },
      ]
    },
    {
      alt: "mobiletechno", IsLeft: true, imgUrl: 'https://cdn.technolee.com/image/technologies/mobiletechno.webp', title: "Mobile Technologies", dicription: 'As a premier mobile app development company, we excel in crafting custom applications from inception to ongoing maintenance. Our dedicated team provides end-to-end services, delivering mobile apps that are not only responsive but also aligned with your business objectives. From consulting to development, we bring full-stack mobile app development expertise to create engaging and user-friendly solutions for both iOS and Android platforms.',
      TechData: [
        { alt: "reactnative", iconURL: 'https://cdn.technolee.com/image/services/reactnative.webp', readmoreUrl: "/technologies/reactnative", Techdicription: "React Native empowers cross-platform mobile app development. We utilize its flexibility to create native-like experiences on both iOS and Android, ensuring efficiency and a unified codebase." },
        { alt: "flutter", iconURL: 'https://cdn.technolee.com/image/services/flutter.webp', readmoreUrl: "/technologies/flutter", Techdicription: "Flutter is our choice for building beautiful and natively compiled applications. Its expressive UI, fast development, and native performance make it a top pick for cross-platform mobile development." },
        { alt: "ios", iconURL: 'https://cdn.technolee.com/image/services/ios.webp', readmoreUrl: "/technologies/ios", Techdicription: "We specialize in iOS app development, creating feature-rich applications that leverage the latest advancements in Apple's ecosystem to deliver seamless experiences." },
        { alt: "android", iconURL: 'https://cdn.technolee.com/image/services/android.webp', readmoreUrl: "/technologies/android", Techdicription: "Our Android app development expertise ensures the creation of scalable and high-performance applications tailored to the diverse Android ecosystem." },
      ]
    },

    {
      alt: "infrastructure", IsLeft: false, imgUrl: 'https://cdn.technolee.com/image/technologies/infrastructure.webp', title: "Infrastructure & DevOps Technologies", dicription: 'We are a forward-thinking Infrastructure & DevOps solutions provider, offering a suite of services from consulting to implementation. Our expert team ensures the scalability, security, and efficiency of your digital infrastructure. As a custom Infrastructure & DevOps Company, we leverage leading cloud platforms like AWS, Azure, and Google Cloud, along with tools like Selenium, to optimize deployment processes and enhance overall system performance.',
      TechData: [
        { alt: "aws", iconURL: 'https://cdn.technolee.com/image/services/aws.webp', readmoreUrl: "/technologies/aws", Techdicription: "AWS provides a comprehensive suite of cloud services. We leverage AWS for scalable infrastructure solutions, ensuring reliability, security, and seamless scalability." },
        { alt: "azure", iconURL: 'https://cdn.technolee.com/image/services/azure.webp', readmoreUrl: "/technologies/azure", Techdicription: "Azure offers a flexible and integrated cloud platform. We harness Azure's capabilities for building, deploying, and managing applications, and ensuring robust and scalable solutions." },
        { alt: "googlecloud", iconURL: 'https://cdn.technolee.com/image/services/googlecloud.webp', readmoreUrl: "/technologies/googlecloud", Techdicription: "Google Cloud provides cutting-edge cloud services. We leverage Google Cloud's innovative solutions for scalable infrastructure and advanced data analytics." },
        { alt: "selenium", iconURL: 'https://cdn.technolee.com/image/services/selenium.webp', readmoreUrl: "/technologies/selenium", Techdicription: "Selenium is our choice for automated testing and continuous integration. We use Selenium to ensure the quality and reliability of applications, streamlining the development process." },
      ]
    },
  ]


  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Our Services - Web Development, Mobile Apps, UI/UX Design, QA, IoT, Digital Transformation, and More</title>
        <meta name="description" content="Explore Technolee's comprehensive services, including web development, mobile apps, UI/UX design, quality assurance, IoT solutions, digital transformation, product development, and dedicated developers. Unlock your business potential with our expertise." />
        <meta name="keywords" content="services, web xdevelopment, mobile app development, UI/UX design, QA services, IoT solutions, digital transformation, product development, dedicated developers, Technolee" />
      </Helmet>

      <Banner
        alt={'Technologies Banner'}
        image='https://cdn.technolee.com/image/home/bannermain.webp'
        title={htmlString}
        description="Elevate Your Digital Presence with Technological Excellence. Discover Our Comprehensive Suite of Frontend, Backend, Mobile, Infrastructure, and DevOps Technologies."
      />

      <div className='page-content container'>
        <PageSectionMain title='LEARN MORE ON TECHNOLOGIES' />
        <PageSectionSecondary title='Technology spectrum we have' />
        <TechCardZigZag TechCardData={TechCardData} />

      </div>
    </>
  )
}
