import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { RiMenu3Fill } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';
import SecondaryButtom from '../SecondaryButton/SecondaryButtom';
import './navbar.css';


const Navbar = () => {
  const { pathname } = useLocation();

  const [activeLink, setActiveLink] = useState('');
  const [NavShow, setNavShow] = useState(false);


  useEffect(() => {
    const splitlink = pathname.split("/");
    const activepath = splitlink[1];
    setActiveLink("/" + activepath)

    const handleScroll = () => {
      if (window.scrollY >= 50) {
        setNavShow(true)
      } else {
        setNavShow(false)
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <nav id='nav' className={NavShow ? 'nav-show' : ''} >
      <div className="nav-main">
        <div className="nav-logo">
          <Link to="/" aria-label='home'>
            <img src="https://cdn.technolee.com/image/technoleecolor.webp" alt='Technolee' className='logo' loading="lazy" />
          </Link>
        </div>
        <input type="radio" name="slider" id="menu-btn" readOnly />
        <input type="radio" name="slider" id="close-btn" checked readOnly />
        <ul className="nav-links">
          <li>
            <label htmlFor="close-btn" className="btn close-btn"><AiOutlineClose /></label>
          </li>
          <li>
            <Link exact to="/" className={activeLink === '/' ? 'active-link' : ''} >Home</Link>
          </li>
          <li>
            <Link to="/about" className={activeLink === '/about' ? 'active-link' : ''} >About</Link>
          </li>
          <li >
            <Link to="/services" className={activeLink == '/services' ? 'active-link desktop-item' : 'desktop-item'} activeClassName="active">Services</Link>

            <input type="checkbox" id="showMega" readOnly />
            <label htmlFor="showMega" className="mobile-item">Services</label>
            <div className="mega-box">
              <div className="content arrow-up">
                <div className="row">
                  <header> <Link to="/services/digital-transformation" className={activeLink === '/services' ? 'active-link' : 'service-dropdown-title'}
                  >Digital Transformation</Link></header>
                  <ul className="mega-links">
                    <li> <Link to="/services/digital-transformation#Operational-Transformation" className={activeLink === '/services/digital-transformation#Operational-Transformation' ? 'active-link' : ''}
                    >Operational Transformation</Link></li>
                    <li> <Link to="/services/digital-transformation#Customer-Experience-Transformation" className={activeLink === '/services/digital-transformation#Customer-Experience-Transformation' ? 'active-link' : ''}
                    >Customer Experience Transformation</Link></li>
                    <li> <Link to="/services/digital-transformation#Technological-Evolution" className={activeLink === '/services/digital-transformation#Technological-Evolution' ? 'active-link' : ''}
                    >Technological Evolution</Link></li>
                    <li> <Link to="/services/digital-transformation#Data-Driven-Transformation" className={activeLink === '/services/digital-transformation#Data-Driven-Transformation' ? 'active-link' : ''}
                    >Data-Driven Transformation</Link></li>
                  </ul>
                </div>
                <div className="row">
                  <header><Link to="/services/mobile-apps" className={activeLink === '/services' ? 'active-link' : 'service-dropdown-title'}
                  >Mobile Apps</Link></header>
                  <ul className="mega-links">
                    <li><Link to="/services/mobile-apps#platform-apps" className={activeLink === '/services/mobile-apps#platform-apps' ? 'active-link' : ''}
                    >Cross Platform Apps</Link></li>
                    <li><Link to="/services/mobile-apps#ios-apps" className={activeLink === '/services/mobile-apps#ios-apps' ? 'active-link' : ''}
                    >Native iOS Apps</Link></li>
                    <li><Link to="/services/mobile-apps#android-apps" className={activeLink === '/services/mobile-apps#android-apps' ? 'active-link' : ''}
                    >Native Android Apps</Link></li>
                  </ul>
                </div>
                <div className="row">
                  <header> <Link to="/services/web-development" className={activeLink == '/services' ? 'active-link' : 'service-dropdown-title'} activeClassName="drop-active">Web Development</Link></header>
                  <ul className="mega-links">
                    <li><Link to="/services/web-development#enterprise-solutions" className={activeLink == '/services/web-development#enterprise-solutions' ? 'active-link' : ''}
                    >Enterprise Solutions</Link></li>
                    <li><Link to="/services/web-development#ecommerce-web-development" className={activeLink === '/services/web-development#ecommerce-web-development' ? 'active-link' : ''}
                    >eCommerce Web Development</Link></li>
                    <li><Link to="/services/web-development#mobile-backend-api" className={activeLink === '/services/web-development#mobile-backend-api' ? 'active-link' : ''}
                    >Mobile Backend & APIs</Link></li>
                    <li><Link to="/services/web-development#custom-web-development" className={activeLink === '/services/web-development#custom-web-developmen' ? 'active-link' : ''}
                    >Custom Web Development</Link></li>
                  </ul>
                </div>

                <div className="row">
                  <header> <Link to="/services/uiux-design" className={activeLink === '/services' ? 'active-link' : 'service-dropdown-title'}
                  >UI/UX Design</Link></header>
                  <ul className="mega-links">
                    <li> <Link to="/services/uiux-design#requirement-understanding-services" className={activeLink === '/services/uiux-design#requirement-understanding-services' ? 'active-link' : ''}
                    >Requirement Understanding</Link></li>
                    <li> <Link to="/services/uiux-design#analysis" className={activeLink === '/services/uiux-design#analysis' ? 'active-link' : ''}
                    >Analysis</Link></li>
                    <li> <Link to="/services/uiux-design#wireframing" className={activeLink === '/services/uiux-design#wireframing' ? 'active-link' : ''}
                    >Wireframing</Link></li>
                    <li> <Link to="/services/uiux-design#custom-web-design" className={activeLink === '/services/uiux-design#custom-web-design' ? 'active-link' : ''}
                    >Custom Web Design</Link></li>
                    <li> <Link to="/services/uiux-design#uiux-design" className={activeLink === '/services/uiux-design#uiux-design' ? 'active-link' : ''}
                    >UI/UX Design</Link></li>
                    <li> <Link to="/services/uiux-design#mobile-ui-design" className={activeLink === '/services/uiux-design#mobile-ui-design' ? 'active-link' : ''}
                    >Mobile UI Design</Link></li>
                  </ul>
                </div>
                <div className="row">
                  <header> <Link to="/services/dedicated-developers" className={activeLink === '/services' ? 'active-link' : 'service-dropdown-title'}
                  >Dedicated Developers</Link></header>
                  <ul className="mega-links">
                    <li> <Link to="/services/dedicated-developers#Full-Time-Dedicated-Developers" className={activeLink === '/services/dedicated-developers#Full-Time-Dedicated-Developers' ? 'active-link' : ''}
                    >Full-Time Dedicated </Link></li>
                    <li> <Link to="/services/dedicated-developers#Part-Time-Dedicated-Developers" className={activeLink === '/services/dedicated-developers#Part-Time-Dedicated-Developers' ? 'active-link' : ''}
                    >Part-Time Dedicated </Link></li>
                    <li> <Link to="/services/dedicated-developers#Project-Based-Hiring" className={activeLink === '/services/dedicated-developers#Project-Based-Hiring' ? 'active-link' : ''}
                    >Project-Based Hiring</Link></li>
                    <li> <Link to="/services/dedicated-developers#Offshore-Development-Team" className={activeLink === '/services/dedicated-developers#Offshore-Development-Team' ? 'active-link' : ''}
                    >Offshore Development Team</Link></li>
                  </ul>
                </div>
                <div className="row">
                  <header> <Link to="/services/qa-service" className={activeLink === '/services' ? 'active-link' : 'service-dropdown-title'}
                  >QA</Link></header>
                  <ul className="mega-links">
                    <li> <Link to="/services/qa-service#manual-qa-testing" className={activeLink === '/services/qa-service#manual-qa-testing' ? 'active-link' : ''}
                    >Manual QA Testing</Link></li>
                    <li> <Link to="/services/qa-service#web-testing-automation" className={activeLink === '/services/qa-service#web-testing-automation' ? 'active-link' : ''}
                    >Web Testing Automation</Link></li>
                    <li> <Link to="/services/qa-service#mobile-app-testing" className={activeLink === '/services/qa-service#mobile-app-testing' ? 'active-link' : ''}
                    >Mobile App Testing</Link></li>
                    <li> <Link to="/services/qa-service#performance-testing" className={activeLink === '/services/qa-service#performance-testing' ? 'active-link' : ''}
                    >Performance Testing</Link></li>
                    <li> <Link to="/services/qa-service#api-testing" className={activeLink === '/services/qa-service#api-testing' ? 'active-link' : ''}
                    >API Testing</Link></li>
                  </ul>
                </div>
                <div className="row">
                  <header> <Link to="/services/product-development" className={activeLink === '/services' ? 'active-link' : 'service-dropdown-title'}
                  >Product Development</Link></header>
                  <ul className="mega-links">
                    <li> <Link to="/services/product-development#product-discovery-design" className={activeLink === '/services/product-development#product-discovery-design' ? 'active-link' : ''}
                    >Product Discovery & Design</Link></li>
                    <li> <Link to="/services/product-development#software-product-consulting" className={activeLink === '/services/product-development#software-product-consulting' ? 'active-link' : ''}
                    >Product Consulting</Link></li>
                    <li> <Link to="/services/product-development#software-product-architecture-design" className={activeLink === '/services/product-development#software-product-architecture-design' ? 'active-link' : ''}
                    >Product Architecture</Link></li>
                    <li> <Link to="/services/product-development#software-product-development" className={activeLink === '/services/product-development#software-product-development' ? 'active-link' : ''}
                    >Product Development</Link></li>
                    <li> <Link to="/services/product-development#maintenance-support" className={activeLink === '/services/product-development#maintenance-support' ? 'active-link' : ''}
                    >Maintenance & Support</Link></li>
                  </ul>
                </div>
                <div className="row">
                  <header> <Link to="/services/iot-service" className={activeLink === '/services' ? 'active-link' : 'service-dropdown-title'}
                  >IoT</Link></header>
                  <ul className="mega-links">
                    <li> <Link to="/services/iot-service#industrial-automation" className={activeLink === '/services/iot-service#industrial-automation' ? 'active-link' : ''}
                    >Industrial Automation</Link></li>
                    <li> <Link to="/services/iot-service#home-automation" className={activeLink === '/services/iot-service#home-automation' ? 'active-link' : ''}
                    >Home Automation</Link></li>
                    <li> <Link to="/services/iot-service#smart-healthcare" className={activeLink === '/services/iot-service#smart-healthcare' ? 'active-link' : ''}
                    >Smart Healthcare</Link></li>
                    <li> <Link to="/services/iot-service#connected-devices" className={activeLink === '/services/iot-service#connected-devices' ? 'active-link' : ''}
                    >Connected Devices</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li>
            <Link exact to="/hire" className={activeLink === '/hire' ? 'active-link' : ''} >Hire</Link>
            <input type="checkbox" id="showMega" readOnly />
            <label htmlFor="showMega" className="mobile-item"></label>
            <div className="mega-box-hire arrow-up arrow-up-hire">
              <div className="content ">
              <div className="row">
                  <header> <Link  className={activeLink === '/technologiesNew' ? 'active-link' : 'service-dropdown-title'}
                  >Mobile App</Link></header>
                  <ul className="mega-links">
                    <li> <Link to="hire-react-native-developers" className={activeLink === 'hire-react-native-developers' ? 'active-link' : ''}
                    >Hire React Native developers</Link></li>
                   
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li >
            <Link to="/technologies" className={activeLink == '/technologies' ? 'active-link desktop-item' : 'desktop-item'}
            >Technologies</Link>

            <input type="checkbox" id="showMega-tech" readOnly />
            <label htmlFor="showMega-tech" className="mobile-item">Technologies</label>
            <div className="mega-box-technologies arrow-up-technologies">
              <div className="content">
                <div className="row">
                  <header> <Link to="/technologies" className={activeLink == '/technologies' ? 'active-link ' : 'service-dropdown-title'}
                  >Front End</Link></header>
                  <ul className="mega-links">
                    <li><Link to="/technologies/reactjs" className={activeLink == '/technologies/reactjs' ? 'active-link' : ''}
                    >React JS</Link></li>
                    <li><Link to="/technologies/angular" className={activeLink == '/technologies/angular' ? 'active-link' : ''}
                    >Angular</Link></li>
                    <li><Link to="/technologies/vuejs" className={activeLink == '/technologies/vuejs' ? 'active-link' : ''}
                    >Vue JS</Link></li>
                    <li><Link to="/technologies/typescript" className={activeLink == '/technologies/typescript' ? 'active-link' : ''}
                    >TypeScript</Link></li>
                  </ul>
                </div>
                <div className="row">
                  <header><Link to="/technologies" className={activeLink === '/technologies' ? 'active-link' : 'service-dropdown-title'}
                  >Backend</Link></header>
                  <ul className="mega-links">
                    <li><Link to="/technologies/dotnet" className={activeLink === '/technologies/dotnet' ? 'active-link' : ''}
                    >.NET</Link></li>
                    <li><Link to="/technologies/nodejs" className={activeLink === '/technologies/nodejs' ? 'active-link' : ''}
                    >Node</Link></li>
                    <li><Link to="/technologies/sqlserver" className={activeLink === '/technologies/sqlserver' ? 'active-link' : ''}
                    >SQL Server</Link></li>

                  </ul>
                </div>
                <div className="row">
                  <header> <Link to="/technologies" className={activeLink === '/technologies' ? 'active-link' : 'service-dropdown-title'}
                  >Mobile</Link></header>
                  <ul className="mega-links">
                    <li> <Link to="/technologies/reactnative" className={activeLink === '/technologies/reactnative' ? 'active-link' : ''}
                    >React Native</Link></li>
                    <li> <Link to="/technologies/flutter" className={activeLink === '/technologies/flutter' ? 'active-link' : ''}
                    >Flutter</Link></li>
                    <li> <Link to="/technologies/ios" className={activeLink === '/technologies/ios' ? 'active-link' : ''}
                    >iOS</Link></li>
                    <li> <Link to="/technologies/android" className={activeLink === '/technologies/android' ? 'active-link' : ''}
                    >Android</Link></li>
                  </ul>
                </div>
                <div className="row">
                  <header> <Link to="/technologies" className={activeLink === '/technologies' ? 'active-link' : 'service-dropdown-title'}
                  >Infra & DevOps</Link></header>
                  <ul className="mega-links">
                    <li> <Link to="/technologies/aws" className={activeLink === '/technologies/aws' ? 'active-link' : ''}
                    >AWS</Link></li>
                    <li> <Link to="/technologies/azure" className={activeLink === '/technologies/azure' ? 'active-link' : ''}
                    >Azure</Link></li>
                    <li> <Link to="/technologies/googlecloud" className={activeLink === '/technologies/googlecloud' ? 'active-link' : ''}
                    >Google Cloud</Link></li>
                    <li> <Link to="/technologies/selenium" className={activeLink === '/technologies/selenium' ? 'active-link' : ''}
                    >Selenium</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          {/* <li>
            <Link to="/products" className={activeLink === '/products' ? 'active-link' : ''}
            >Products</Link>
          </li> */}
          <li>
            <Link to="/portfolio" className={activeLink === '/portfolio' ? 'active-link' : ''}
            >Portfolio</Link>
          </li>
          {/* <li>
            <Link to="/blog" className={activeLink === '/blog' ? 'active-link' : ''}
            >Blog</Link>
          </li> */}
          <li>
            <Link to="/career" className={activeLink === '/career' ? 'active-link desktop-item' : 'desktop-item'}
            >Careers</Link>
            <input type="checkbox" id="showMega-career" readOnly />
            <label htmlFor="showMega-career" className="mobile-item"><Link to="/career" className='career-label'>Current Openings</Link></label>
            <div className="mega-box-contect arrow-up-contect">

              <ul className="drop-menu mega-links ">
                <li><Link to="/career" className={activeLink === '/career' ? 'active-link' : ''}
                >Current Openings <br /> <br /> <span>We are always looking for smart and enthusiastic people to add to our growing team.</span></Link> </li>
                <li><Link to="/career/life" className={activeLink == '/career' ? 'active-link' : ''}
                >Life @ Technolee  <br />  <br /><span>We are always looking for smart and enthusiastic people to add to our growing team.</span></Link></li>
              </ul>
            </div>
          </li>
          <li className='life-with'><Link to="/career/life" >Life @ Technolee </Link></li>
          <li className='nav-button'>
            <SecondaryButtom />
          </li>
        </ul>
        <label htmlFor="menu-btn" className="btn menu-btn"><RiMenu3Fill /></label>
      </div>
    </nav>
  );
};

export default Navbar;