
import React from 'react'
import './readmorebutton.css'
import { Link } from 'react-router-dom'
import { MdOutlineArrowRightAlt } from 'react-icons/md'

export default function ReadMoreButton({ link, title }) {
    return (
        <div className='readmore-btn'>
            <Link to={link} > <span>{title} <MdOutlineArrowRightAlt className='readmore-icon' /></span></Link>
        </div>
    )
}
