import React from 'react'
import './pagesectionmain.css'

export default function PageSectionMain({ title }) {
    return (
        <div className='PageSectionMain-content flex-center'>
            <div><hr/> {title}<hr /> </div>
        </div>
    )
}
