import React, { useCallback, useEffect, useState } from 'react';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';


const GoogleRecaptcha = ({ show, formData, setFormData }) => {
  const [showRecapcha, setshowRecapcha] = useState(show);
  const [token, setToken] = useState("");
  // const sitekey = "6LcRWkwpAAAAAC-lufBGNFkphMRksXMzW1abvKSN"
  // const recaptchaRef = React.createRef();

  // const handleRecaptchaChange = (value) => {
  //     setFormData({ ...formData, Recaptcha: value })
  // };
 



  return (
    <>

      <GoogleReCaptchaProvider reCaptchaKey={showRecapcha ? "6LcRWkwpAAAAAC-lufBGNFkphMRksXMzW1abvKSN" : ""} >

      </GoogleReCaptchaProvider>
    </>
  );
};

export default GoogleRecaptcha;
