import React, { useEffect } from 'react'
import { BsCheck2Square } from 'react-icons/bs'
import ReadMoreButton from '../ReadMorebtn/ReadMoreButton'
import './servicescard.css'


export default function ServicesCard({ id, image, link, title, description, iconData, subServicesData, isLeft, isFullWidth, isReadMore,alt }) {

    useEffect(() => {
        // Get the hash from the URL (e.g., #elementId)
        const hash = window.location.hash;
       

        if (hash) {
            // Remove the '#' character to get the element's ID
            const elementId = hash.slice(1);

            // Find the element with the matching ID
            const element = document.getElementById(elementId);

            if (element) {
                // Scroll to the element
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [id]);

    return (
        <div>
            <div className={!isLeft ? 'flex-space-between service-card-content' : 'flex-space-between service-card-content-left'} id={id} >
                {isLeft && <div className='service-card-img-left'>
                    <img className='service-card-image' fetchpriority="high" as="image" src={image} alt={alt} />
                </div>}
                <div className='servicecard-second-content'>
                    <h1>{title}</h1>
                    <p>{description}</p>
                    <div className='flex-space-between servicecard-icon-content '>
                        {iconData.map((item, ind) =>
                            <div key={ind} className='servicecard-icon'>
                                <img src={item.iconURL} alt={item.alt} />
                            </div>
                        )}
                    </div>

                    <div className={`servicecard-check-content ${isFullWidth ? '' : "flex-space-between"}`}>
                        {subServicesData.map((item, ind) => <div key={ind} className={`servicecard-check ${isFullWidth ? "servicecard-check-full-width" : ''}`}><BsCheck2Square /> <span>{item.name}</span></div>)}
                    </div>

                    {isReadMore && <div className='servicecard-btn'>
                        <ReadMoreButton title="Details" link={link} />
                    </div>}
                </div>
                {!isLeft && <div className='service-card-img'>
                    <img className='service-card-image' fetchpriority="high" as="image" src={image} alt={alt} />
                </div>}
            </div>
        </div>
    )
}
