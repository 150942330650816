import React from 'react'
import './location.css'
import { HiOutlineLocationMarker } from "react-icons/hi";
import { MdOutlineMail } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";


export default function Location() {
    return (
        <div className=' location'>
            <div >
                <div className='location-title' >
                    <video autoPlay loop >
                        <source src="https://cdn.technolee.com/image/indiaflage.mp4" type="video/mp4" />
                        <track src="captions_en.vtt" kind="captions" srclang="en" label="english_captions"/>

                    </video>
                    <h1>Bharat (India)</h1>
                </div>
                <p className='location-text-city'>Ahmedabad</p>

                <div className='location-address'>
                    <HiOutlineLocationMarker className='loaction-icon' />
                    <p>A - 903 Siddhi Vinayak Towers,<br />
                        Nr. Kataria Arcade, SG Highway
                        Ahmedabad, Gujarat – 380051</p>
                </div>

                <div className='sales'>
                    <p className='sales-heading'>Get In Touch</p>
                    <div className='sales-address'>
                        <MdOutlineMail className='sales-icon' />
                        <p><a href="mailto:hello@technolee.com">hello@technolee.com</a></p>
                    </div>
                    <div className='sales-address'>
                        <FiPhoneCall className='sales-icon' />
                        <p><a href='tel:+91 84010 68567'>+91 84010 68567</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
