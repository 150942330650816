import React from 'react'
import TechnologiesDetails from '../../../../camponets/TechnologiesDetails/TechnologiesDetails';
import { Helmet } from 'react-helmet';

const TechName = "Flutter"

const BannerData = [
    { alt: "flutterbanner", BannerimgUrl: 'https://cdn.technolee.com/image/technologies/technobennar/flutterbanner.webp', htmlString: 'Explore <span class="text-highlight">Flutter</span>for Unmatched Mobile Excellence', BennerDecription: "Dive into the world of Flutter—an innovative framework for cross-platform mobile app development. Elevate user experiences on iOS and Android with a unified codebase, ensuring swift development, stunning UI, and unparalleled efficiency." }
]

const AboutCardData = [
    { alt: "flutertechnology", imgUrl: 'https://cdn.technolee.com/image/technologies/technoexceptional/flutertechnology.webp', AboutTitle: "Elevating Businesses with Flutter Expertise", AboutDecription: "Discover the advantages of partnering with us for your Flutter development needs. From seasoned developers to cutting-edge solutions, we bring a unique value proposition to your business." }
]

const TechDetailsCardData = [
    { title: 'Expert Flutter Developers', description: 'Tap into the expertise of our seasoned Flutter developers, ensuring the creation of robust and visually striking applications.' },
    { title: 'Tailored Solutions', description: 'We understand the unique requirements of your business, delivering bespoke Flutter solutions that perfectly align with your goals.' },
    { title: 'Innovation at the Core', description: ': Embrace innovation through our forward-thinking approach, integrating the latest trends and technologies for standout Flutter applications.' },
    { title: 'Transparent Collaboration', description: 'We value collaboration, ensuring transparent communication throughout the development process. Your vision seamlessly translates into a functional Flutter application.' },
    { title: 'Quality Assurance Excellence', description: 'Quality is fundamental to our Flutter development process. Rigorous testing guarantees reliable, secure, and flawless applications.' },
    { title: 'Customer-Centric Communication', description: 'Experience clear and customer-centric communication, keeping you informed and involved in the decision-making process.' },
]

const ExpertiseCardData = [
    { title: 'Strategic Consultation and Planning', description: 'Initiate the process with strategic consultation, understanding your objectives and mapping out the development roadmap for success.' },
    { title: 'Efficient Cross-Platform Development', description: 'Leverage Flutter for efficient cross-platform development, creating applications that seamlessly run on both iOS and Android devices.' },
    { title: 'Expert UI/UX Design Integration', description: "Elevate your app's visual appeal with our expert UI/UX design integration, prioritizing intuitive design for an immersive user experience." },
    { title: 'Seamless Third-Party API Integration', description: "Ensure enhanced functionality by seamlessly integrating your Flutter app with third-party APIs, expanding your app's features." },
    { title: 'Real-Time Feature Implementation', description: "Explore real-time features in your app, from dynamic updates to messaging, expertly implemented by our Flutter development team." },
    { title: 'Optimization for Superior Performance', description: "Dedicated optimization ensures superior performance, responsiveness, and speed across various devices." },


]

const TechFancyCardData = [
    { title: 'Custom App Development', description: "Crafted to meet your unique business requirements, our custom Flutter applications stand out in functionality and design." },
    { title: 'Cross-Platform Migration Expertise', description: "Seamlessly migrate existing mobile applications to Flutter, preserving functionality while unlocking the benefits of a unified codebase." },
    { title: 'Expert UI/UX Design Services', description: "Elevate your app's visual appeal with our expert UI/UX design, prioritizing user-centric design for captivating interfaces." },
    { title: 'Efficient Integration Services', description: "Ensure smooth integration with third-party APIs, plugins, and backend systems. Our Flutter developers streamline the integration process for expanded functionality." },
    { title: 'Ongoing Maintenance and Support', description: "Beyond development, we offer ongoing maintenance and support services to keep your Flutter apps up-to-date, secure, and performing seamlessly." },
    { title: 'Real-Time Features Implementation', description: "Implement real-time features that enhance user engagement and collaboration, bringing your app to life with real-time capabilities." },

]


const KeyBenefitsCardData = [
    { alt: "reusability", title: 'Cost-Effectiveness Through Code Reusability', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/reusability.webp', description: "Save resources with Flutter's code reusability, ensuring efficient development and reduced time-to-market for cost-effective app development." },
    { alt: "developmentcoding", title: 'Rapid Development with Hot-Reload', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/developmentcoding.webp', description: "Flutter's hot-reload feature accelerates development cycles, facilitating real-time code changes and faster time-to-market for your mobile apps." },
    { alt: "crossplatform", title: 'Cross-Platform Excellence', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/crossplatform.webp', description: "Reach a wider audience with a single codebase, ensuring consistent performance on both iOS and Android for simplified maintenance and updates." },
    { alt: "performance", title: 'Native-Like Performance', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/performance.webp', description: "Benefit from native-like performance in your mobile apps, ensuring high-quality, smooth, and responsive user experiences." },
    { alt: "ecosystem", title: 'Community and Ecosystem Support', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/ecosystem.webp', description: "Join a vibrant Flutter developer community, tapping into a robust ecosystem of libraries and plugins. Continuous improvement and support are guaranteed." },
    { alt: "flexible", title: 'Flexibility and Scalability', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/flexible.webp', description: "Flutter provides flexibility and scalability for your app. Easily scale your application as your user base grows, ensuring a seamless and adaptable user experience." },
    { alt: "realtime", title: 'Time and Resource Efficiency', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/realtime.webp', description: "Develop and maintain a unified codebase for both iOS and Android, saving time and resources. Flutter's efficiency contributes to quicker development cycles." },
]

const TestimonialsData = [
    { title: 'Industries Are Best Suited for Flutter App Development?', description: "Flutter's versatility makes it ideal for various industries, including healthcare, e-commerce, finance, education, travel, and more." },
    { title: 'Can Flutter Handle Complex and Large-Scale Applications?', description: "Absolutely. Flutter's robust architecture ensures optimal performance, making it suitable for developing complex and large-scale applications." },
    { title: 'How Does Flutter Ensure a Native-Like User Experience?', description: "Flutter leverages a rich set of pre-designed widgets, ensuring a native-like user experience with smooth animations and high performance." },
    { title: 'Is Flutter a Cost-Effective Choice for App Development?', description: "Yes, Flutter's code reusability significantly reduces development costs by maintaining a unified codebase for both iOS and Android platforms." },
    { title: 'Can I Integrate Third-Party Services and APIs with Flutter Apps?', description: "Certainly. Flutter supports seamless integration with third-party services and APIs, expanding functionality and enhancing features." },
    { title: 'How Does Flutter Facilitate Real-Time Features in Mobile Apps?', description: "Flutter provides the necessary tools for implementing real-time features, making it suitable for applications requiring dynamic and instantaneous updates." },
    { title: 'Is Flutter Suitable for Startups and Small Businesses?', description: "Yes, Flutter is an excellent choice for startups and small businesses, offering cost-effectiveness, quick development cycles, and cross-platform capabilities." },
    { title: 'What Is the Community Support Like for Flutter Developers?', description: "Flutter boasts a vibrant and active developer community, ensuring continuous improvement, support, and a wealth of resources for developers." },
]

export default function Flutter() {
    return (
        <div>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <title>Flutter App Development Solutions</title>
                <meta name="description" content="Revolutionize your mobile presence with our Flutter app development services. We specialize in crafting dynamic, cross-platform applications for an immersive user experience" />
                <meta name="keywords" content="Flutter App Development, Cross-Platform Mobile Apps, Flutter Framework, Dart Programming, Flutter UI/UX Design, Real-Time Flutter Apps, Flutter Development Services, Custom Flutter Solutions, Flutter Migration, Flutter Maintenance, Flutter Performance Optimization, Flutter Integration, Flutter Consulting, Flutter Developers, Flutter Community." />
            </Helmet>

            <TechnologiesDetails TechName={TechName} AboutCardData={AboutCardData} BannerData={BannerData} TechDetailsCardData={TechDetailsCardData} TechFancyCardData={TechFancyCardData} ExpertiseCardData={ExpertiseCardData} KeyBenefitsCardData={KeyBenefitsCardData} TestimonialsData={TestimonialsData} />
        </div>
    )
}
