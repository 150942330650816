import React from 'react'
import './Developmentskillcard.css'

export default function DevelopmentSkillCard({ DevelopmentSkillCardData }) {
    const getClassName = (index) => {
        const itemsCount = DevelopmentSkillCardData.length;

        // If last row has only 2 items, make them take extra space
        if (itemsCount % 3 === 2 && index >= 3) {
            return 'developmentskillcard-main developmentskillcard-extra-width';
        }
        return 'developmentskillcard-main';
    }

    return (
        <div className='flex developmentskillcard'>
            {DevelopmentSkillCardData.map((item, ind) => {
                return (
                    <div className={getClassName(ind)} key={ind}>
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                    </div>
                )
            })}
        </div>
    );
}
