import React from 'react'
import TechnologiesDetails from '../../../../camponets/TechnologiesDetails/TechnologiesDetails';
import { Helmet } from 'react-helmet';

const TechName = "AngularJS"

const BannerData = [
  { alt: "angularbanner", BannerimgUrl: 'https://cdn.technolee.com/image/technologies/technobennar/angularbanner.webp', htmlString: 'Empower Your Web Solutions with <span class="text-highlight">AngularJS</span>', BennerDecription: "Redefine web development with our AngularJS development services. From modular architecture to seamless performance, we leverage AngularJS to elevate your digital presence." }
]

const AboutCardData = [
  { alt: "angulartechnology", imgUrl: 'https://cdn.technolee.com/image/technologies/technoexceptional/angulartechnology.webp', AboutTitle: "Your Partner for Exceptional AngularJS Development", AboutDecription: "Discover why businesses choose our AngularJS development services for building modern, feature-rich web applications." }
]

const TechDetailsCardData = [
  { title: 'Expert AngularJS Developers', description: "Our skilled AngularJS developers bring expertise in creating scalable and dynamic web applications, ensuring a superior user experience.Innovative Development Approach" },
  { title: 'Innovative Development Approach', description: "Embrace innovation with our forward-thinking approach to AngularJS development. We leverage the latest features and design principles for cutting-edge applications." },
  { title: 'Modular Architecture', description: "Our developers specialize in building applications using Angular's modular architecture, ensuring maintainability, scalability, and efficient code." },
  { title: 'Performance Excellence ', description: "We prioritize performance excellence, delivering web applications that are fast, responsive, and provide an exceptional user experience." },
  { title: 'Collaborative Development', description: "Collaboration is integral to our development process. We involve you in decision-making, ensuring transparency and effective communication throughout the project." },
]

const ExpertiseCardData = [
  { title: 'Single Page Applications (SPAs)', description: "Leverage the power of AngularJS to build efficient and dynamic single-page applications that provide a seamless user experience." },
  { title: 'Modular Application Development', description: "We specialize in developing modular applications using Angular, ensuring maintainability, scalability, and efficient code." },
  { title: 'Angular Material Design Integration', description: "Elevate your application's visual appeal with our expert integration of Angular Material Design, creating interfaces that are both functional and aesthetically pleasing." },
  { title: 'Real-Time Features Implementation', description: "Implement real-time features such as live updates, messaging, and collaboration, enhancing user engagement in your AngularJS applications." },
  { title: 'Cross-Platform Development', description: "Explore the potential for cross-platform development with AngularJS, ensuring your applications reach a wider audience." },
]

const TechFancyCardData = [
  { title: 'Custom AngularJS Application Development', description: "Craft tailored web applications that align with your unique business requirements, ensuring functionality and design excellence." },
  { title: 'AngularJS Module Development', description: "Develop modular applications with our AngularJS module development services, ensuring maintainability and scalability." },
  { title: 'Angular Material Design Integration', description: "Prioritize visual appeal with our expert integration of Angular Material Design, creating interfaces that captivate and engage users." },
  { title: 'Real-Time Features Implementation', description: "Implement real-time features that enhance user engagement, including live updates, messaging, and collaborative functionalities." },
  { title: 'Cross-Platform AngularJS Development', description: "Explore the potential for cross-platform development with AngularJS, ensuring your applications reach a wider audience." },
  { title: 'Performance Optimization', description: "Our team dedicates efforts to optimize the performance of your AngularJS applications, ensuring they run efficiently on various devices." },
  { title: 'Maintenance and Support', description: "Beyond development, we offer ongoing maintenance and support services to keep your AngularJS applications secure, up-to-date, and optimized." },

]


const KeyBenefitsCardData = [
  { alt: "integration", title: 'Modular Architecture', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/integration.webp', description: "AngularJS's modular architecture enables the development of applications with components that are easily maintainable, scalable, and reusable." },
  { alt: "efficienttest", title: 'Two-Way Data Binding', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/efficienttest.webp', description: "Benefit from AngularJS's two-way data binding, ensuring real-time synchronization between the model and the view for a seamless user experience." },
  { alt: "crossplatform", title: 'Cross-Platform Capabilities', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/crossplatform.webp', description: "With AngularJS, explore the potential for cross-platform development, allowing your applications to reach a broader audience." },
  { alt: "developmentcoding", title: 'Dynamic Single Page Applications (SPAs)', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/developmentcoding.webp', description: "AngularJS enables the creation of dynamic and responsive single-page applications, providing a seamless user experience." },
  { alt: "designaesthetics", title: 'Angular Material Design Aesthetics', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/designaesthetics.webp', description: "Prioritize visual appeal with Angular Material Design integration, creating interfaces that are both functional and aesthetically pleasing." },
  { alt: "performance", title: 'Efficient Development Process', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/performance.webp', description: "AngularJS streamlines the development process with its modular and declarative approach, leading to quicker development cycles." },
]

const TestimonialsData = [
  { title: 'What Is the Advantage of Choosing AngularJS for Web Development?', description: 'AngularJS offers a modular architecture, two-way data binding, cross-platform capabilities, dynamic single-page applications (SPAs), Angular Material Design aesthetics, and an efficient development process.' },
  { title: 'How Long Does It Take to Develop an AngularJS Application?', description: 'Development time varies based on project complexity. Our team ensures efficient development without compromising quality.' },
  { title: 'Can AngularJS Applications Integrate with Other Technologies?', description: 'Yes, AngularJS applications seamlessly integrate with various technologies, ensuring versatility and compatibility.' },
  { title: 'What Are the Key Features of an AngularJS Application?', description: 'Key features include modular architecture, two-way data binding, cross-platform capabilities, dynamic single-page applications (SPAs), Angular Material Design aesthetics, and an efficient development process.' },
  { title: 'Is AngularJS Development Suitable for Single Page Applications (SPAs)?', description: 'Yes, AngularJS is highly suitable for developing efficient and dynamic single-page applications (SPAs) that provide a seamless user experience.' },
  { title: 'How Do You Ensure the Performance Optimization of AngularJS Applications?', description: 'Our team dedicates efforts to optimize the performance of AngularJS applications, ensuring they run efficiently on various devices.' },
  { title: 'Can Real-Time Features Be Implemented in AngularJS Applications?', description: 'Yes, AngularJS applications can implement real-time features such as live updates, messaging, and collaboration to enhance user engagement.' },
  { title: 'What Role Does UI/UX Design Play in AngularJS Development?', description: 'UI/UX design is crucial in AngularJS development to create interfaces that are visually appealing, intuitive, and aligned with user expectations.' },
]

export default function Angular() {
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Transforming Digital Experiences with AngularJS Development</title>
        <meta name="description" content="Explore our AngularJS development services for creating robust, scalable, and dynamic web applications. Our expert AngularJS developers bring innovation and efficiency to your digital solutions." />
        <meta name="keywords" content="AngularJS Development, AngularJS Developers, Modular Architecture, Two-Way Data Binding, Cross-Platform AngularJS, Dynamic Single Page Applications, Angular Material Design Integration, Efficient AngularJS Development, Custom AngularJS Applications, AngularJS Module Development, AngularJS Maintenance and Support, Advantages of AngularJS, AngularJS Development Benefits, AngularJS FAQs" />
      </Helmet>

      <TechnologiesDetails TechName={TechName} AboutCardData={AboutCardData} BannerData={BannerData} TechDetailsCardData={TechDetailsCardData} TechFancyCardData={TechFancyCardData} ExpertiseCardData={ExpertiseCardData} KeyBenefitsCardData={KeyBenefitsCardData} TestimonialsData={TestimonialsData} />
    </div>
  )
}
