import React from 'react';
import './TechnologyHireCard.css';
import Briefing from '../../../src/image/TechnologiesNew/Briefing.png';
import Atom from '../../../src/image/TechnologiesNew/Atom symbol.png';
import Technical from '../../../src/image/TechnologiesNew/Technical Support.png';
import Sociology from '../../../src/image/TechnologiesNew/Sociology.png';

const steps = [
    {
        icon: Briefing,
        title: "Underline Your Project Requirements",
        description: "Feel Free To Tell Us About Your Project Requirements Along With Discussing Your Vision. Highlight The Skills And Expertise You Are Looking For."
    },
    {
        icon: Atom,
        title: "Review Our Talent Pool",
        description: "Within 48 Hours, We Will Provide You Some Pre-Vetted React Native Developers As Per Your Project Needs."
    },
    {
        icon: Technical,
        title: "Interviews And Technical Assessment",
        description: "As Per Your Preferred Candidates, Conduct Interviews With Them. Assess Their Technical Skills, And Finalize Your Choice."
    },
    {
        icon: Sociology,
        title: "On-Board React Native Developer",
        description: "Start Working On Your Project Immediately, And Get Support Throughout Your Project, Along With Follow-Ups From Our Team."
    }
];

const TechnologyHireCard = () => {
    return (
        <div className="technologyHireCard-container">
            {steps.map((step, index) => (
                <div key={index} className="technologyHireCard-step">
                    <div className="technologyHireCard-icon-container">
                        <div className="technologyHireCard-icon">
                            <img width={40} src={step.icon} alt={step.title} />
                        </div>
                    </div>
                    <h3>{step.title}</h3>
                    <p>{step.description}</p>
                </div>
            ))}

            <div className="technologyHireCard-line-container">
                <div className="technologyHireCard-dotted-line"></div>
                <div className="technologyHireCard-arrow-main">
                    <div className="technologyHireCard-arrow1">&#8594;</div>
                    <div className="technologyHireCard-arrow2">&#8594;</div>
                    <div className="technologyHireCard-arrow3">&#8594;</div>
                </div>
            </div>
        </div>
    );
};

export default TechnologyHireCard;
