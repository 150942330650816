import React from 'react'
import './techcard.css'
import { Link } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'

export default function TechCardZigZag({ TechCardData }) {
    return (
        <div>
            {TechCardData.map((item, ind) => {
                return (
                    <div className={item.IsLeft === true ? 'flex-center techcard-content' : 'flex-center techcard-content-left'} key={ind}>
                        {item.IsLeft === false && <div className='techcard-content-img'>
                            <img className='techcard-upper-img' src={item.imgUrl} alt={item.alt} />
                        </div>}
                        <div className='techcard-second-content'>
                            <h2>{item.title}</h2>
                            <p>{item.dicription}</p>
                            {item.TechData.map((tech, index) => {
                                return (
                                    <div className='flex-space-between tech-details' key={index}>
                                        <div className='tech-img'>
                                            <img src={tech.iconURL} alt={tech.alt} />
                                        </div>
                                        <div className='tech-content'>
                                            <p>{tech.Techdicription}</p>
                                            <Link to={tech.readmoreUrl} >Details<FiChevronRight /></Link>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {item.IsLeft === true && <div className='techcard-content-img2'>
                            <img className='techcard-lower-img' src={item.imgUrl} alt={item.alt} />
                        </div>}
                    </div>
                )
            })}
        </div>
    )
}
