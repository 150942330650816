import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../camponets/Banner/Banner'
import ServicesCard from '../../../camponets/ServicesCard/ServicesCard';

export default function DedicatedDevelopers() {
    const FullTimeDedicatedDevelopers = [{ name: 'Exclusive commitment to your projects' }, { name: 'Continuous collaboration and communication' }, { name: 'In-depth understanding of project goals' }, { name: 'Focused development approach' }, { name: 'Seamless integration with your in-house team' }]
    const PartTimeDedicatedDevelopers = [{ name: 'Flexibility to align with project requirements' }, { name: 'Ideal for projects with varying workloads' }, { name: 'Resource scalability based on project needs' }, { name: 'Efficient utilization of part-time dedicated expertise' }]
    const ProjectBasedHiring = [{ name: 'Assemble a team for targeted project initiatives' }, { name: 'Engage skilled professionals for specific durations' }, { name: 'Optimal resource allocation for project efficiency' }, { name: 'Flexibility in adapting to project-specific needs' }]
    const OffshoreDevelopmentTeam = [{ name: 'Cost-effective offshore development resources' }, { name: 'Collaboration with skilled offshore developers' }, { name: 'Diverse expertise for comprehensive solutions' }, { name: 'Efficient project execution with optimized costs' }, { name: 'Seamless communication and project management' }]

    const AllServices = [{
        id: "Full-Time-Dedicated-Developers",
        image: "https://cdn.technolee.com/image/services/dedicateddev/fulltimededicateddevelopers.webp",
        title: "Full-Time Dedicated Developers",
        description: "Leverage the expertise of our full-time dedicated developers who become an integral part of your team. With undivided attention, they ensure continuous collaboration, in-depth understanding, and a focused approach to your projects. This model guarantees a committed team working exclusively on your tasks, fostering efficiency, and delivering successful project outcomes.",
        iconData: [],
        subServicesData: FullTimeDedicatedDevelopers,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "fulltimededicateddevelopers"
    }, {
        id: "Part-Time-Dedicated-Developers",
        image: "https://cdn.technolee.com/image/services/dedicateddev/parttimededicateddevelopers.webp",
        title: "Part-Time Dedicated Developers",
        description: "Tailor your development team with part-time dedicated developers, offering flexibility to match specific project requirements. Ideal for projects with variable workloads, this model allows you to scale resources according to evolving development needs. Benefit from skilled professionals contributing part-time, ensuring efficiency and adaptability for varying project scopes.",
        iconData: [],
        subServicesData: PartTimeDedicatedDevelopers,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "parttimededicateddevelopers"

    }, {
        id: "Project-Based-Hiring",
        image: "https://cdn.technolee.com/image/services/dedicateddev/projectbasedhiring.webp",
        title: "Project-Based Hiring",
        description: "Opt for project-based hiring to assemble a dedicated development team tailored to specific initiatives. This model provides the advantage of engaging skilled professionals for defined project durations, ensuring optimal resource allocation and efficient project delivery. Enjoy flexibility and a targeted approach to meet the unique requirements of individual projects.",
        iconData: [],
        subServicesData: ProjectBasedHiring,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "projectbasedhiring"

    }, {
        id: "Offshore-Development-Team",
        image: "https://cdn.technolee.com/image/services/dedicateddev/offshoredevelopmentteam.webp",
        title: "Offshore Development Team",
        description: "Embrace the cost-effective and skilled resources provided by our offshore development team model. Collaborate seamlessly with our offshore developers, benefiting from their diverse expertise. This model enhances the efficiency of project execution, delivering high-quality software solutions while optimizing development costs.",
        iconData: [],
        subServicesData: OffshoreDevelopmentTeam,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "offshoredevelopmentteam"

    }]
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <title>Hire Dedicated Developers - Expert Development Team at Your Service</title>
                <meta name="description" content="Dedicated Developers, Hire Development Team, Offshore Development, Custom Software Development, Remote Development Team" />
                <meta name="keywords" content="Explore the flexibility of hiring dedicated developers from Technolee. Tailor your development team with skilled professionals adept at delivering custom software solutions. Benefit from our offshore development model for unparalleled expertise and a collaborative development experience." />
            </Helmet>

            <Banner
                alt= {"Dedicateddevelopers Banner"}
                image="https://cdn.technolee.com/image/services/dedicateddev/dedicateddevbanner.webp"
                title="Unlock Development Potential with Our Dedicated Developers"
                description="Build your dream projects with Technolee's dedicated developers. Tailor your development team, embrace expertise, and witness your software visions come to life."
            />
            <div className='page-content container'>
                <div>
                    {AllServices.map((item, ind) =>
                        <ServicesCard
                            key={ind}
                            id={item.id}
                            image={item.image}
                            title={item.title}
                            description={item.description}
                            iconData={item.iconData}
                            subServicesData={item.subServicesData}
                            isLeft={item.isLeft}
                            isFullWidth={item.isFullWidth}
                            isReadMore={item.isReadMore}
                            alt={item.alt}
                        />
                    )}
                </div>
            </div>
        </>
    )
}
