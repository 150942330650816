import React from 'react'
import './adcard.css'
import { Link } from 'react-router-dom'


export default function AdCard({AdcardData}) {
    return (
        <div>
            {AdcardData.map((item,ind)=>{
                return (
            <div className='flex-space-between adcard-main' key={ind}>
                    <div className='adcard-details'>
                    <p>{item.SubTitle}</p>
                    <h1>{item.Title}</h1>
                    <p className='adcard-buttom-text'>{item.Description}</p>
                </div>
                <div className='contect-button '>
                        <Link to={ item.link} state= {"projectinqurys"}>{item.buttonname}</Link>
                </div>
            </div>
                )
            })}
                
        </div>
    )
}
