import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../camponets/Banner/Banner';
import ServicesCard from '../../../camponets/ServicesCard/ServicesCard';

export default function ProductDevelopment() {
    const productSubServices = [{ name: 'Idea Validation' }, { name: 'User-Centric Design' }, { name: 'Prototyping' }, { name: 'Wireframing' }]
    const softwareProductSubServices = [{ name: 'Market Research' }, { name: 'Product Roadmapping' }, { name: 'Business Model Development' }, { name: 'Technical Feasibility Assessment' }]
    const architectureProductSubServices = [{ name: 'System Architecture Design' }, { name: 'Scalability Planning' }, { name: 'Security and Compliance Integration' }, { name: 'Technology Stack Selection' }]
    const softwareDevelopmentSubServices = [{ name: 'Custom Software Development' }, { name: 'Agile Development Methodology' }, { name: 'Quality Assurance' }, { name: 'Testing and Debugging' }]
    const maintenanceSubServices = [{ name: 'Updates and Upgrades' }, { name: 'Bug Fixes and Issue Resolution' }, { name: 'Performance Monitoring' }, { name: 'User Assistance' }]

    const AllServices = [{
        id: "product-discovery-design",
        image: "https://cdn.technolee.com/image/services/productdevelopment/productdiscovery.webp",
        title: "Product Discovery & Design",
        description: "Our Product Discovery & Design service is the pivotal starting point for transforming your product concept into reality. We guide you through the journey, beginning with idea validation to assess feasibility and market potential. Our user-centric design approach tailors user interfaces and experiences to your target audience, ensuring intuitive and engaging interactions. We create functional prototypes to validate concepts and gather feedback, while Wireframing provides the initial product structure for clarity and alignment with your vision. Our comprehensive product discovery and design services pave the way for a successful product development journey. We offer:",
        iconData: [],
        subServicesData: productSubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "productdiscovery"
    }, {
        id: "software-product-consulting",
        image: "https://cdn.technolee.com/image/services/productdevelopment/softwareproductconsulting.webp",
        title: "Software Product Consulting",
        description: "Our Software Product Consulting service provides invaluable insights and expert guidance for your product strategy. We conduct thorough market research to identify trends, competition, and opportunities, helping you make informed decisions. We collaborate with you to create a comprehensive product roadmap, ensuring a clear plan for development and future iterations. Our business model development assistance helps you define your revenue model and pricing strategy. We also perform technical feasibility assessments to evaluate the technical requirements and limitations of your project, ensuring that your product aligns with your vision and market demands. We offer:",
        iconData: [],
        subServicesData: softwareProductSubServices,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "productdiscovery"

    }, {
        id: "software-product-architecture-design",
        image: "https://cdn.technolee.com/image/services/productdevelopment/softwareproductarchitecturedesign.webp",
        title: "Software Product Architecture Design",
        description: "Our Software Product Architecture Design service focuses on crafting the technical foundation that underpins your product's success. We define the system architecture, outlining the overall structure and organization of your product. Scalability planning ensures that your product can grow seamlessly with user demand, providing a robust foundation for future expansion. Security and compliance integration incorporates rigorous measures to safeguard data and ensure regulatory compliance. Technology stack selection involves choosing the ideal technologies and tools to deliver optimal performance, reliability, and user experience. We offer:",
        iconData: [],
        subServicesData: architectureProductSubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "productdiscovery"

    }, {
        id: "software-product-development",
        image: "https://cdn.technolee.com/image/services/productdevelopment/softwareproductdevelopment.webp",
        title: "Software Product Development",
        description: "Our Software Product Development service is where your product takes shape. We specialize in custom software development, creating tailored solutions from the ground up to meet your unique requirements and goals. We embrace agile development methodologies, fostering flexibility and enabling rapid development iterations to adapt to evolving needs. Quality assurance is paramount, ensuring that your product is reliable, robust, and free of defects. Rigorous testing and debugging processes fine-tune your product, ensuring a flawless user experience upon launch. We offer:",
        iconData: [],
        subServicesData: softwareDevelopmentSubServices,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "productdiscovery"

    }, {
        id: "maintenance-support",
        image: "https://cdn.technolee.com/image/services/productdevelopment/maintenancesupport.webp",
        title: "Maintenance & Support",
        description: "Our Maintenance & Support service is dedicated to ensuring the long-term success and sustainability of your product. We offer continuous updates and upgrades to keep your product aligned with the latest technology advancements and user expectations. Our team promptly addresses and resolves issues, providing bug fixes and continuous performance optimization. We proactively monitor your product's performance and implement necessary adjustments. Our user assistance and training resources are readily available to support users and ensure they maximize the value of your product. We provide:",
        iconData: [],
        subServicesData: maintenanceSubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "productdiscovery"

    }]

    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                <title>Product Development Services - Product Discovery & Design, Software Product Consulting, Software Product Architecture Design, Software Product Development, Maintenance & Support</title>
                <meta name="description" content="Explore our product development services, including product discovery & design, software product consulting, architecture design, development, and ongoing maintenance & support. We bring your software product ideas to life and ensure their long-term success." />
                <meta name="keywords" content="product development services, product discovery, software product consulting, architecture design, development, maintenance & support, Technolee" />
            </Helmet>

            <Banner
                alt={'productbanner'}
                image="https://cdn.technolee.com/image/services/productdevelopment/productbanner.webp"
                title="Bringing Your Vision to Life"
                description="Unleash the potential of your software product with our end-to-end product development services. Discover our expertise in product discovery & design, software product consulting, architecture design, development, and ongoing maintenance & support."
            />
            <div className='page-content container'>
                <div>
                    {AllServices.map((item, ind) =>
                        <ServicesCard
                            key={ind}
                            id={item.id}
                            image={item.image}
                            title={item.title}
                            description={item.description}
                            iconData={item.iconData}
                            subServicesData={item.subServicesData}
                            isLeft={item.isLeft}
                            alt={item.alt}
                            isFullWidth={item.isFullWidth}
                            isReadMore={item.isReadMore}
                        />
                    )}

                </div>
            </div>
        </>
    )
}