import React from 'react'
import TechnologiesDetails from '../../../../camponets/TechnologiesDetails/TechnologiesDetails';
import { Helmet } from 'react-helmet';

const TechName = "Node Js"

const BannerData = [
    { alt: "seleniumbanner", BannerimgUrl: 'https://cdn.technolee.com/image/technologies/technobennar/seleniumbanner.webp', htmlString: 'Unleashing <span class="text-highlight">SQL Server </span>Excellence for Your Business', BennerDecription: "Explore Technolee's SQL Server Solutions, where robust database management meets unparalleled efficiency and security." }
]

const AboutCardData = [
    { alt: "seleniumtechnology", imgUrl: 'https://cdn.technolee.com/image/technologies/technoexceptional/seleniumtechnology.webp', AboutTitle: "", AboutDecription: "" }
]

const TechDetailsCardData = [
    { title: 'Proven Expertise', description: 'Benefit from decades of industry experience, where our seasoned professionals bring a wealth of knowledge to every SQL Server project. Our track record of successful implementations reflects our commitment to excellence.' },
    { title: 'Tailored Solutions', description: 'We understand that each business is unique. Our approach involves crafting customized SQL Server solutions tailored to your specific needs, ensuring that our services align perfectly with your business goals.' },
    { title: 'Scalable Solutions', description: 'Future-proof your SQL Server infrastructure with our scalable solutions. As your business evolves, our architecture allows for seamless expansion, ensuring your database systems can grow alongside your company.' },
    { title: 'Data Security Focus', description: 'Security is at the core of our SQL Server services. From encryption protocols to access controls, we prioritize robust security measures, safeguarding your critical data against potential threats and ensuring compliance.' },
    { title: 'Performance Optimization', description: 'Elevate the performance of your SQL Server databases with our continuous monitoring and optimization services. We fine-tune configurations and queries to ensure your database operates at peak efficiency.' },
    { title: '24/7 Support', description: "Our dedicated support team is available round-the-clock to provide assistance whenever you need it. Whether it's troubleshooting issues or addressing queries, our commitment to 24/7 support ensures you are never alone in managing your SQL Server." },

]

const ExpertiseCardData = [
    { title: 'Database Design and Development', description: 'Entrust us with designing and developing efficient SQL Server databases. Our experts leverage industry best practices to create robust and scalable database structures tailored to your business needs.' },
    { title: 'Data Migration and Integration', description: 'Seamlessly transition your data with our Data Migration and Integration services. We ensure a smooth transfer, preserving data integrity and minimizing downtime for uninterrupted operations.' },
    { title: 'Performance Tuning', description: 'Optimize SQL Server performance with our specialized tuning services. From query optimization to server configuration adjustments, we fine-tune every aspect to enhance overall database performance.' },
    { title: 'Backup and Recovery', description: 'Safeguard your critical data with our Backup and Recovery solutions. We implement reliable strategies, ensuring that your data is backed up regularly and can be swiftly recovered in the event of unforeseen circumstances.' },
    { title: 'Clustering and High Availability', description: 'Ensure uninterrupted access to your databases with our Clustering and High Availability solutions. We implement advanced clustering techniques, providing redundancy and minimizing downtime.' },
    { title: 'Security Implementation', description: 'Our Security Implementation services go beyond the basics. We deploy advanced security measures, including encryption, access controls, and regular security audits to fortify your SQL Server environment.' },

]

const TechFancyCardData = [
    { title: 'Database Installation and Configuration', description: 'Rely on our expertise for the seamless installation and configuration of SQL Server databases. We handle the technical aspects, ensuring your database environment is optimized from the start.' },
    { title: 'Query Optimization', description: 'Enhance query performance with our Query Optimization services. We analyze and fine-tune SQL queries, ensuring efficient execution and minimizing bottlenecks for improved overall system responsiveness.' },
    { title: 'Troubleshooting and Maintenance', description: 'Proactively address issues with our Troubleshooting and Maintenance services. Our team conducts regular checks, identifies potential problems, and implements preventive measures to maintain the health of your SQL Server databases.' },
    { title: 'Data Warehousing Solutions', description: 'Transform your data into actionable insights with our Data Warehousing Solutions. We design and implement effective data warehousing strategies, allowing you to derive valuable insights for strategic decision-making.' },
    { title: 'Cloud Integration', description: 'Embrace the flexibility of the cloud with our Cloud Integration services. We seamlessly integrate your SQL Server databases with popular cloud platforms, offering increased scalability and accessibility.' },
    { title: 'Customized Reporting Solutions', description: 'Access meaningful insights with our Customized Reporting Solutions. We tailor reporting solutions to your specific requirements, ensuring you have the right data at your fingertips for informed decision-making.' },
]


const KeyBenefitsCardData = [
    { alt: "reusability", title: 'Reliability and Stability', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/reusability.webp', description: 'Trust in the reliability and stability of SQL Server for your mission-critical applications. Our services ensure that your databases operate seamlessly, providing a stable foundation for your business operations.' },
    { alt: "scalability", title: 'Scalability', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/scalability.webp', description: "Easily scale your operations with SQL Server's scalable architecture. As your data needs grow, our services allow for efficient expansion, ensuring that your databases can accommodate increasing workloads." },
    { alt: "security", title: 'Comprehensive Security', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/security.webp', description: "Experience peace of mind with SQL Server's comprehensive security features. Our implementation goes beyond the basics, incorporating advanced security measures to protect your sensitive data from potential threats." },
    { alt: "performance", title: 'Performance Optimization', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/performance.webp', description: 'Enjoy peak performance with our continuous optimization efforts. From regular monitoring to fine-tuning configurations, we ensure that your SQL Server databases operate at optimal efficiency.' },
    { alt: "integration", title: 'Seamless Integration', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/integration.webp', description: 'Seamlessly integrate SQL Server with various applications and systems. Our services facilitate smooth communication between different components, streamlining your overall business processes.' },
    { alt: "efficienttest", title: 'Cost-Effective Solutions', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/efficienttest.webp', description: 'Benefit from cost-effective SQL Server solutions tailored to your budget. Our services offer a balance between high-quality database management and cost efficiency, ensuring optimal value for your investment.' },


]

const TestimonialsData = [
    { title: 'What versions of SQL Server do you support?', description: 'Our support extends to various versions, including SQL Server 2019, 2017, and earlier versions, ensuring compatibility with your preferences.' },
    { title: 'How do you ensure data security in SQL Server?', description: 'Our robust security measures include encryption, access controls, and regular security audits to fortify your SQL Server environment against potential threats.' },
    { title: 'Can you assist with SQL Server performance issues?', description: 'Absolutely. Our performance tuning services address issues at every level, ensuring optimal SQL Server performance for your business-critical applications.' },
    { title: 'Do you offer cloud integration services for SQL Server?', description: 'Yes, our Cloud Integration services seamlessly connect your SQL Server databases with popular cloud platforms, providing increased flexibility and accessibility.' },
    { title: 'What backup and recovery solutions do you provide for SQL Server?', description: 'Our Backup and Recovery solutions are designed to safeguard your critical data, with reliable backup strategies and swift recovery options to minimize potential data loss.' },
    { title: 'How quickly can you set up a new SQL Server database?', description: 'The timeline depends on the project scope, but our aim is efficient and prompt database setups to meet your requirements without compromising quality.' },
    { title: 'What industries have you served with SQL Server solutions?', description: 'Our SQL Server solutions have successfully catered to diverse industries, including finance, healthcare, e-commerce, and more, showcasing our adaptability and expertise.' },
    { title: 'Can you assist with SQL Server training for our internal team?', description: 'Certainly. Our comprehensive training programs empower your team with the necessary skills and knowledge to manage and utilize SQL Server effectively within your organization.' },

]


export default function SQLServer() {
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <title>SQL Server Solutions by Technolee - Unlocking Database Excellence</title>
                <meta name="description" content="SQL Server, Database Management, SQL Server Services, Data Warehousing, Database Security, Microsoft SQL, Database Optimization" />
                <meta name="keywords" content="Technolee offers comprehensive SQL Server solutions for efficient database management, data warehousing, and enhanced security. Discover our expertise in optimizing Microsoft SQL for your business success." />
            </Helmet>

            <TechnologiesDetails TechName={TechName} AboutCardData={AboutCardData} BannerData={BannerData} TechDetailsCardData={TechDetailsCardData} TechFancyCardData={TechFancyCardData} ExpertiseCardData={ExpertiseCardData} KeyBenefitsCardData={KeyBenefitsCardData} TestimonialsData={TestimonialsData} />
        </>
    )
}
