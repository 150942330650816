import React from 'react'
import TechnologiesDetails from '../../../../camponets/TechnologiesDetails/TechnologiesDetails';
import { Helmet } from 'react-helmet';

const TechName = "Native iOS"

const BannerData = [
    { alt: "iosbanner", BannerimgUrl: 'https://cdn.technolee.com/image/technologies/technobennar/iosbanner.webp', htmlString: 'Crafting Excellence in <span class="text-highlight">Native iOS</span>Development', BennerDecription: "Immerse your audience in a superior iOS experience with our native app development services. From conceptualization to deployment, our expert iOS developers ensure your app stands out for its performance, design, and user engagement." }
]

const AboutCardData = [
    { alt: "iostechnology", imgUrl: 'https://cdn.technolee.com/image/technologies/technoexceptional/iostechnology.webp', AboutTitle: "Unleashing the Power of Native iOS Development", AboutDecription: "Discover why our native iOS development services are the ideal choice for businesses seeking top-notch, platform-specific applications." }
]

const TechDetailsCardData = [
    { title: 'Expert iOS Developers', description: "Our skilled iOS developers bring years of experience to the table, ensuring the creation of robust and visually appealing native iOS applications." },
    { title: 'Tailored iOS Solutions', description: "We understand the unique requirements of iOS development, delivering tailored solutions that align seamlessly with Apple's ecosystem." },
    { title: 'Innovative App Solutions', description: "Embrace innovation with our forward-thinking approach to iOS development. We integrate the latest iOS features and design principles for cutting-edge applications." },
    { title: 'Quality Assurance', description: "Quality is at the core of our iOS development process. Rigorous testing ensures that the applications we deliver are reliable, secure, and deliver an outstanding user experience." },
    { title: 'Transparent Collaboration', description: "Collaboration is key. We involve you in the development process, ensuring transparent communication and making you an integral part of the decision-making process." },
]

const ExpertiseCardData = [
    { title: 'Swift and Objective-C Proficiency', description: "Leverage our proficiency in Swift and Objective-C, ensuring that your native iOS app is developed using the latest and most effective programming languages." },
    { title: 'UI/UX Design Excellence', description: "Elevate your app's visual appeal with our expert UI/UX design integration. We prioritize intuitive design and smooth navigation, ensuring an immersive user experience." },
    { title: 'Integration with Apple Ecosystem', description: "Seamless integration with the Apple ecosystem ensures that your native iOS app leverages the full range of features available on Apple devices." },
    { title: 'Performance Optimization', description: "Our team is dedicated to optimizing your native iOS app for superior performance. We implement best practices to ensure responsiveness, speed, and reliability." },
    { title: 'Security Best Practices', description: "Security is a top priority. We adhere to the highest security standards, ensuring that your iOS app is robust and protected against potential threats." },
]

const TechFancyCardData = [
    { title: 'Custom iOS App Development', description: "Tailored native iOS applications crafted to meet your unique business requirements. Our custom development ensures that your app stands out in functionality and design." },
    { title: 'Swift Migration', description: "Seamlessly migrate existing apps to Swift, ensuring they are up-to-date with the latest iOS technologies and standards." },
    { title: 'UI/UX Design', description: "Elevate your app's visual appeal with our expert UI/UX design services. We prioritize user-centric design, creating interfaces that captivate and engage users." },
    { title: 'Integration Services', description: "Ensure smooth integration with third-party APIs, plugins, and backend systems. We streamline the integration process, expanding your app's functionality." },
    { title: 'Maintenance and Support', description: "Beyond development, we offer ongoing maintenance and support services. Ensure your iOS apps stay up-to-date, secure, and perform seamlessly." },
    { title: 'Real-Time Features Implementation', description: "Implement real-time features that enhance user engagement and collaboration. Whether it's live updates, messaging, or collaborative features, we bring your iOS app to life with real-time capabilities." },
]


const KeyBenefitsCardData = [
    { alt: "performance", title: 'Optimized Performance', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/performance.webp', description: "Native iOS apps are optimized for performance, providing a smoother and faster user experience compared to cross-platform solutions." },
    { alt: "flexible", title: 'Access to Latest Features', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/flexible.webp', description: "Leverage the latest iOS features and capabilities to ensure your app stays competitive and takes advantage of the newest innovations." },
    { alt: "integration2", title: 'Seamless Integration', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/integration2.webp', description: "Seamlessly integrate your app with other iOS devices and services, providing a cohesive user experience within the Apple ecosystem." },
    { alt: "security", title: 'Enhanced Security', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/security.webp', description: "Native iOS apps benefit from Apple's robust security measures, ensuring the protection of user data and the overall integrity of the application." },
    { alt: "designaesthetics", title: 'Optimized UI/UX', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/designaesthetics.webp', description: "Tailor your app's user interface and experience to the specific design principles of iOS, creating an interface that feels native and intuitive." },
    { alt: "realtime", title: 'Efficient Performance Optimization', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/realtime.webp', description: "Our team dedicates efforts to optimize the performance of your native iOS app, ensuring it runs efficiently on a variety of iOS devices." },
]

const TestimonialsData = [
    { title: 'What Is the Advantage of Developing a Native iOS App?', description: 'Native iOS apps offer optimized performance, access to the latest features, seamless integration, enhanced security, and a UI/UX tailored to iOS design principles.' },
    { title: 'How Long Does it Take to Develop a Native iOS App?', description: 'Development time varies based on the complexity of the project. Our team ensures efficient development without compromising on quality.' },
    { title: 'You Migrate an Existing App to Native iOS?', description: 'Yes, we provide migration services to Swift, ensuring your existing app is up-to-date with the latest iOS technologies.' },
    { title: 'What Are the Key Features of a Native iOS App?', description: 'Native iOS apps can leverage the full range of iOS features, including performance optimization, access to device-specific functionalities, and seamless integration.' },
    { title: 'Is Native iOS Development More Expensive Than Cross-Platform?', description: 'While native development may have higher upfront costs, it offers long-term advantages in terms of performance, user experience, and access to the latest iOS features.' },
    { title: 'How Do You Ensure the Security of Native iOS Apps?', description: "We adhere to Apple's security best practices, implementing measures to protect user data and ensure the overall security of native iOS applications." },
    { title: 'Can Real-Time Features Be Implemented in Native iOS Apps?', description: 'Yes, we can implement real-time features such as live updates, messaging, and collaboration in native iOS apps, enhancing user engagement.' },
    { title: 'What Is the Role of UI/UX Design in Native iOS Development?', description: 'UI/UX design is crucial in native iOS development to create interfaces that are intuitive, visually appealing, and aligned with iOS design principles.' },
]

export default function Ios() {
    return (
        <div>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <title>Native iOS App Development Services</title>
                <meta name="description" content="Elevate your iOS app experience with our native iOS development services. Our expert iOS developers create seamless, performance-oriented applications tailored to your business needs." />
                <meta name="keywords" content="iOS App Development, Native iOS Apps, Swift Programming, iOS UI/UX Design, Objective-C Development, iOS Integration Services, iOS Maintenance and Support, Real-Time iOS Apps, iOS Security Best Practices, iOS Developers, iOS Performance Optimization, iOS Migration Services, Custom iOS Solutions, Kotlin to Swift Migration, iOS Consulting Services" />
            </Helmet>

            <TechnologiesDetails TechName={TechName} AboutCardData={AboutCardData} BannerData={BannerData} TechDetailsCardData={TechDetailsCardData} TechFancyCardData={TechFancyCardData} ExpertiseCardData={ExpertiseCardData} KeyBenefitsCardData={KeyBenefitsCardData} TestimonialsData={TestimonialsData} />
        </div>
    )
}
