import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

class GoogleMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            latlong: {
                lat: 22.994784,
                lng: 72.499077
            },
            address: '123 Main St, City, Country'
        };
    }

    componentDidMount() {
        // Customize the map options to disable map/satellite control
        const map = this.refs.map.map;

        const mapOptions = {
            disableDefaultUI: false, // Disable all default UI controls
            mapTypeControl: false, // Disable map type control (map/satellite button)
        };

        map.setOptions(mapOptions);
    }

    render() {
        const { address } = this.props; // Get the address from props

        return (
            <div>
                <Map
                    google={this.props.google}
                    initialCenter={this.state.latlong}
                    ref="map"
                >
                    <Marker position={this.state.latlong}
                    />
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAii_9SU6ec6sCpwyaHIurMBvktQ8fv6JI'
})(GoogleMap);
