import React from 'react'
import TechnologiesDetails from '../../../../camponets/TechnologiesDetails/TechnologiesDetails';
import { Helmet } from 'react-helmet';

const TechName = ""

const BannerData = [
    { alt: "azurebanner", BannerimgUrl: 'https://cdn.technolee.com/image/technologies/technobennar/azurebanner.webp', htmlString: 'Redefine Your Cloud Experience with <span class="text-highlight">Azure</span>', BennerDecription: "Dive into the cloud innovation journey with our Azure development services. From scalable infrastructure to cutting-edge solutions, we empower your digital transformation." }
]

const AboutCardData = [
    { alt: "azuretechnology", imgUrl: 'https://cdn.technolee.com/image/technologies/technoexceptional/azuretechnology.webp', AboutTitle: "Your Trusted Azure Development Partner", AboutDecription: "Discover why businesses trust our Azure development services for creating robust, scalable, and secure cloud-based solutions." }
]

const TechDetailsCardData = [
    { title: 'Certified Azure Experts', description: "Our certified Azure experts bring in-depth knowledge and expertise to design, deploy, and optimize solutions on the Microsoft Azure platform." },
    { title: 'Strategic Cloud Consulting', description: "Benefit from strategic cloud consulting services, where we align Azure solutions with your business goals, ensuring maximum efficiency and ROI." },
    { title: 'Scalable and Flexible Solutions', description: "We specialize in developing scalable and flexible solutions on Azure, providing the foundation for your applications to grow seamlessly with your business." },
    { title: 'Security and Compliance Focus', description: "Security is at the forefront of our Azure development. We ensure that your cloud solutions adhere to the highest security standards and compliance requirements." },
    { title: 'Cost Optimization Strategies', description: "Implement cost optimization strategies to ensure that your Azure resources are utilized efficiently, maximizing the value of your investment." },
]

const ExpertiseCardData = [
    { title: 'Azure Cloud Application Development', description: "We specialize in developing cloud-native applications on Azure, ensuring agility, scalability, and optimal performance." },
    { title: 'Serverless Architecture with Azure Functions', description: "Implement serverless architecture using Azure Functions, enabling you to build and run applications without managing infrastructure." },
    { title: 'Microservices Development on Azure', description: "Leverage microservices architecture on Azure, creating modular and scalable applications that can be developed, deployed, and scaled independently." },
    { title: 'Azure DevOps Integration', description: "Integrate Azure into your DevOps practices for streamlined development, continuous integration, and continuous delivery, ensuring rapid and reliable software releases." },
    { title: 'Data Storage and Analytics with Azure', description: "Utilize Azure services for efficient data storage and analytics, leveraging services like Azure Blob Storage, Azure SQL Database, and Azure Synapse Analytics for optimal insights." },
]

const TechFancyCardData = [
    { title: 'Azure Cloud Application Development', description: "Develop cloud-native applications on Azure, harnessing the power of scalable infrastructure and cutting-edge cloud services." },
    { title: 'Serverless Architecture with Azure Functions', description: "Implement serverless architecture using Azure Functions, allowing you to focus on building code without managing servers." },
    { title: 'Microservices Development on Azure', description: "Embrace microservices development on Azure for modular, scalable, and independently deployable applications." },
    { title: 'Azure DevOps Integration', description: "Integrate Azure into your DevOps practices for automated testing, continuous integration, and continuous delivery, ensuring efficient and reliable software delivery." },
    { title: 'Data Storage and Analytics with Azure', description: "Leverage Azure services like Azure Blob Storage, Azure SQL Database, and Azure Synapse Analytics for efficient data storage, retrieval, and analytics." },
    { title: 'Azure Security and Compliance', description: "Prioritize security and compliance in your Azure solutions, ensuring that your applications adhere to industry standards and regulations." },
    { title: 'Cost Optimization Strategies', description: "Implement cost optimization strategies for your Azure resources, ensuring that your investment aligns with your business goals." },
    { title: 'Azure Maintenance and Support', description: "Beyond development, we offer ongoing maintenance and support services, ensuring that your Azure applications are secure, up-to-date, and performing optimally." },
]


const KeyBenefitsCardData = [
    { alt: "scalability", title: 'Scalability and Flexibility', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/scalability.webp', description: "Azure provides on-demand scalability, allowing your applications to grow seamlessly with the changing needs of your business." },
    { alt: "efficienttest", title: 'Cost-Effective Solutions', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/efficienttest.webp', description: "Optimize costs with Azure, paying only for the resources you consume, and benefit from a flexible pricing model that suits your budget." },
    { alt: "integration2", title: 'Global Reach and Availability', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/integration2.webp', description: "Leverage Azure's global infrastructure to ensure high availability and low-latency experiences for users across the globe." },
    { alt: "security", title: 'Security and Compliance', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/security.webp', description: "Azure prioritizes security and compliance, providing a secure foundation for your applications to meet industry standards and regulations." },
    { alt: "innovation", title: 'Innovation with Cutting-Edge Services', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/innovation.webp', description: "Stay at the forefront of innovation with Azure's ever-expanding suite of services, enabling you to incorporate the latest technologies into your applications." },
    { alt: "reusability", title: 'Reliability and Redundancy', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/reusability.webp', description: "Ensure reliability and redundancy with Azure, where your applications benefit from a robust and resilient infrastructure." },
]

const TestimonialsData = [
    { title: 'Why Choose Azure for Cloud Development?', description: 'Azure offers scalability, flexibility, cost-effectiveness, global reach, security, and a wide range of cutting-edge services, making it an ideal choice for cloud development.' },
    { title: 'How Does Azure Ensure Security and Compliance?', description: 'Azure prioritizes security and compliance through robust infrastructure, encryption, access controls, and adherence to industry standards and regulations.' },
    { title: 'What Are the Key Azure Services for Cloud Application Development?', description: 'Key Azure services for cloud application development include Azure App Service, Azure Functions for serverless architecture, Azure Kubernetes Service for container orchestration, and more.' },
    { title: 'Can Existing Applications Be Migrated to Azure?', description: 'Yes, existing applications can be migrated to Azure using various migration strategies, ensuring a smooth transition and optimization for the cloud environment.' },
    { title: 'How Does Azure Ensure Data Storage and Analytics?', description: 'Azure provides services like Azure Blob Storage, Azure SQL Database, and Azure Synapse Analytics for efficient data storage, retrieval, and analytics.' },
    { title: 'What Measures Are Taken for Azure Cost Optimization?', description: 'Azure cost optimization includes using reserved instances, right-sizing resources, leveraging spot instances, and monitoring resource usage for efficient budget management.' },
    { title: 'Is Azure Suitable for Small and Medium-sized Businesses (SMBs)?', description: 'Yes, Azure is suitable for SMBs, offering scalable solutions that can be tailored to the specific needs and budget constraints of small and medium-sized businesses.' },
    { title: 'How Does Azure Support Global Application Deployment? ', description: "Azure's global infrastructure ensures high availability and low-latency experiences for users worldwide, supporting global application deployment." },
]

export default function Azure() {
    return (
        <div>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <title>Empower Your Digital Solutions with Microsoft Azure</title>
                <meta name="description" content="Explore our Azure development services to harness the power of Microsoft Azure. Elevate your applications with scalable, flexible, and innovative cloud solutions." />
                <meta name="keywords" content="Azure Development, Microsoft Azure, Azure Solutions, Azure Cloud Application Development, Serverless Architecture, Microservices Development, Azure DevOps Integration, Data Storage and Analytics with Azure, Azure Security and Compliance, Cost Optimization Strategies, Azure Maintenance and Support, Advantages of Azure, Azure Development Benefits, Azure FAQs" />
            </Helmet>

            <TechnologiesDetails TechName={TechName} AboutCardData={AboutCardData} BannerData={BannerData} TechDetailsCardData={TechDetailsCardData} TechFancyCardData={TechFancyCardData} ExpertiseCardData={ExpertiseCardData} KeyBenefitsCardData={KeyBenefitsCardData} TestimonialsData={TestimonialsData} />
        </div>
    )
}
