import React from 'react'
import TechnologiesDetails from '../../../../camponets/TechnologiesDetails/TechnologiesDetails';
import { Helmet } from 'react-helmet';

const TechName = "TypeScript"

const BannerData = [
  { alt: "typescriptbanner", BannerimgUrl: 'https://cdn.technolee.com/image/technologies/technobennar/typescriptbanner.webp', htmlString: 'Elevate Your Codebase with <span class="text-highlight">TypeScript</span>', BennerDecription: "Embrace the future of web development with our TypeScript development services. From enhanced code readability to improved collaboration, we bring TypeScript's benefits to your projects." }
]

const AboutCardData = [
  { alt: "typescripttechnology", imgUrl: 'https://cdn.technolee.com/image/technologies/technoexceptional/typescripttechnology.webp', AboutTitle: "Your Trusted Partner for TypeScript Development", AboutDecription: "Discover why businesses trust our TypeScript development services for building secure, scalable, and high-performance applications." }
]

const TechDetailsCardData = [
  { title: 'Expert TypeScript Developers', description: "Our skilled TypeScript developers bring expertise in building applications that are not only robust but also benefit from the advantages of static typing." },
  { title: 'Cutting-Edge Development Approach ', description: "Stay ahead with our cutting-edge approach to TypeScript development, incorporating the latest language features and best practices." },
  { title: "TypeScript's Strong Typing Advantage", description: "Leverage TypeScript's strong typing to catch potential errors early in the development process, ensuring code reliability and maintainability." },
  { title: 'Enhanced Code Readability', description: "Experience improved code readability and organization with TypeScript, making it easier to understand and maintain complex codebases." },
  { title: 'Collaborative Development', description: "Collaboration is key in our development process. We involve you in decision-making, ensuring transparency and effective communication throughout the project." },
]

const ExpertiseCardData = [
  { title: 'TypeScript Application Development', description: "We specialize in building robust and scalable applications using TypeScript, enhancing both frontend and backend development." },
  { title: 'TypeScript + Framework Integration', description: "Seamlessly integrate TypeScript with popular frameworks like Angular, React, or VueJS, harnessing the benefits of both static typing and framework features." },
  { title: 'TypeScript Library Development', description: "Develop reusable and efficient libraries with TypeScript, ensuring consistency and productivity across projects." },
  { title: 'Migration Services to TypeScript', description: "Migrate existing JavaScript codebases to TypeScript, unlocking the advantages of static typing and modern JavaScript features." },
  { title: 'Codebase Audits and Optimization', description: "Conduct audits and optimize existing TypeScript codebases, ensuring performance, scalability, and adherence to best practices." },
]

const TechFancyCardData = [
  { title: 'Custom TypeScript Application Development', description: "Craft tailored applications with TypeScript, ensuring security, scalability, and adherence to your unique business requirements." },
  { title: 'TypeScript + Framework Integration', description: "Seamlessly integrate TypeScript with popular frameworks, leveraging the benefits of static typing and framework features." },
  { title: 'TypeScript Library Development', description: "Develop efficient and reusable libraries with TypeScript, promoting consistency and productivity across projects." },
  { title: 'Migration Services to TypeScript', description: "Migrate existing JavaScript codebases to TypeScript, unlocking the advantages of static typing and modern JavaScript features." },
  { title: 'Codebase Audits and Optimization', description: "Conduct audits and optimize existing TypeScript codebases, ensuring performance, scalability, and adherence to best practices." },
  { title: 'TypeScript Maintenance and Support', description: "Beyond development, we offer ongoing maintenance and support services to keep your TypeScript applications secure, up-to-date, and optimized." },
]


const KeyBenefitsCardData = [
  { alt: "reusability", title: 'Static Typing for Code Reliability', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/reusability.webp', description: "TypeScript's static typing allows for early error detection, enhancing code reliability and reducing the likelihood of runtime issues." },
  { alt: "realtime", title: 'Improved Code Readability and Maintainability', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/realtime.webp', description: "Benefit from enhanced code readability and maintainability with TypeScript, making it easier to understand and manage complex codebases." },
  { alt: "integration2", title: 'Compatibility with Popular Frameworks', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/integration2.webp', description: "TypeScript seamlessly integrates with popular frameworks like Angular, React, and VueJS, providing a versatile and productive development experience." },
  { alt: "scalability", title: 'Enhanced Developer Productivity', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/scalability.webp', description: "TypeScript's features, such as code completion and navigation, contribute to enhanced developer productivity, streamlining the development process." },
  { alt: "developmentcoding", title: 'Early Detection of Code Issues', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/developmentcoding.webp', description: "Leverage TypeScript's features to catch potential issues early in the development process, reducing debugging time and improving code quality." },
  { alt: "performance", title: 'Scalability and Performance Optimization', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/performance.webp', description: "Ensure scalability and performance optimization in your applications with TypeScript, addressing challenges associated with growing codebases." },
]

const TestimonialsData = [
  { title: 'What Is the Advantage of Choosing TypeScript for Web Development?', description: 'TypeScript offers static typing for code reliability, improved code readability and maintainability, compatibility with popular frameworks, enhanced developer productivity, early detection of code issues, and scalability and performance optimization.' },
  { title: 'How Long Does It Take to Develop a TypeScript Application?', description: 'Development time varies based on project complexity. Our team ensures efficient development without compromising quality.' },
  { title: 'Can TypeScript Applications Integrate with Other Technologies?', description: 'Yes, TypeScript applications seamlessly integrate with various technologies, ensuring versatility and compatibility.' },
  { title: 'What Are the Key Features of a TypeScript Application?', description: 'Key features include static typing for code reliability, improved code readability and maintainability, compatibility with popular frameworks, enhanced developer productivity, early detection of code issues, and scalability and performance optimization.' },
  { title: 'Is TypeScript Development Suitable for Existing JavaScript Codebases?', description: 'Yes, TypeScript is suitable for migrating existing JavaScript codebases, unlocking the benefits of static typing and modern JavaScript features.' },
  { title: 'How Does TypeScript Enhance Developer Productivity?', description: 'TypeScript enhances developer productivity through features like code completion, navigation, and early error detection, streamlining the development process.' },
  { title: 'Can TypeScript Applications Be Optimized for Performance? ', description: 'Yes, our team dedicates efforts to optimize the performance of TypeScript applications, ensuring they run efficiently on various devices.' },
  { title: 'How Does TypeScript Contribute to Code Reliability?', description: "TypeScript's static typing allows for early error detection, contributing to code reliability and reducing the likelihood of runtime issues." },
]

export default function TypeScript() {
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Empowering Modern Development with TypeScript</title>
        <meta name="description" content="Explore our TypeScript development services, where we leverage the power of static typing and modern JavaScript features to build robust, scalable, and maintainable applications." />
        <meta name="keywords" content="TypeScript Development, TypeScript Developers, Static Typing, TypeScript Framework Integration, TypeScript Library Development, Migration to TypeScript, Codebase Audits, TypeScript Maintenance and Support, Advantages of TypeScript, TypeScript Development Benefits, TypeScript FAQs, TypeScript + Angular, TypeScript + React, TypeScript + VueJS" />
      </Helmet>

      <TechnologiesDetails TechName={TechName} AboutCardData={AboutCardData} BannerData={BannerData} TechDetailsCardData={TechDetailsCardData} TechFancyCardData={TechFancyCardData} ExpertiseCardData={ExpertiseCardData} KeyBenefitsCardData={KeyBenefitsCardData} TestimonialsData={TestimonialsData} />
    </div>
  )
}
