import React from 'react'
import BlogCard from '../../camponets/BlogCard/BlogCard'
import { Helmet } from 'react-helmet';
import Banner from '../../camponets/Banner/Banner';

const BlogCardData = [
  { alt: 'Blog', imgUrl: "", imgChip: 'Flutter App Development', Createdby: 'Kinjan Shah', BlogDate: 'Nov 3, 2023', title: "Flutter's Cross-Platform App Development: An Profitable Business Strategy", Link: '/blogdetails', description: 'When it comes to developing mobile application...', },
  { alt: 'Blog', imgUrl: "", imgChip: 'Flutter App Development', Createdby: 'Kinjan Shah', BlogDate: 'Nov 3, 2023', title: "Flutter's Cross-Platform App Development: An Profitable Business Strategy", Link: '/', description: 'When it comes to developing mobile application...', },
  { alt: 'Blog', imgUrl: "", imgChip: 'Flutter App Development', Createdby: 'Kinjan Shah', BlogDate: 'Nov 3, 2023', title: "Flutter's Cross-Platform App Development: An Profitable Business Strategy", Link: '/', description: 'When it comes to developing mobile application...', },
  { alt: 'Blog', imgUrl: "", imgChip: 'Flutter App Development', Createdby: 'Kinjan Shah', BlogDate: 'Nov 3, 2023', title: "Flutter's Cross-Platform App Development: An Profitable Business Strategy", Link: '/', description: 'When it comes to developing mobile application...', },
]

export default function Blog() {
  const htmlString = 'Our<span class="text-highlight">Blog</span>';
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Contact Technolee - Your Partner in Software & Web Development, Digital Transformation, and Product Innovation</title>
        <meta name="description" content="Reach out to Technolee, a leading software and web development company in Ahmedabad, India. Collaborate with us to drive digital transformation and innovate your products." />
        <meta name="keywords" content="contact us, software development, web development, digital transformation, product development, Ahmedabad, India, Technolee" />
      </Helmet>

      <div className='page-content container'>
        <Banner
          image=""
          alt={'Blog Worldwide'}
          title={htmlString}
          description="Avidclan is a renowned and leading web and mobile app development company with a vision to fulfill business requirements using innovative solutions. We assist our clients in a way that they can provide a superior digital experience for their clients worldwide."
        />
        <BlogCard BlogCardData={BlogCardData} />
      </div>
    </>
  )
}
