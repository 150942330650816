import React from 'react'
import TechnologiesDetails from '../../../../camponets/TechnologiesDetails/TechnologiesDetails';
import { Helmet } from 'react-helmet';


const TechName = ""

const BannerData = [
  { alt: "dotnetbanner", BannerimgUrl: 'https://cdn.technolee.com/image/technologies/technobennar/dotnetbanner.webp', htmlString: 'Unleashing the Power of <span class="text-highlight">.NET</span> Development', BennerDecription: "Elevate your digital presence with our expert .NET development services. From innovative web applications to powerful enterprise solutions, we harness the full potential of the .NET framework for your business success." }
]

const AboutCardData = [
  { alt: "dotnettechnology", imgUrl: 'https://cdn.technolee.com/image/technologies/technoexceptional/dotnettechnology.webp', AboutTitle: "Your Trusted .NET Development Partner", AboutDecription: "Discover why businesses choose our .NET development services for their mission-critical projects." }
]

const TechDetailsCardData = [
  { title: 'Experienced .NET Developers', description: "Our seasoned .NET developers bring a wealth of experience, ensuring the seamless development of robust and scalable applications." },
  { title: 'Versatility in .NET Technologies', description: "From ASP.NET to .NET Core, we specialize in a range of .NET technologies, providing versatile solutions that meet diverse business requirements." },
  { title: 'Innovative Approach', description: "We embrace innovation, incorporating the latest features and advancements in the .NET framework to deliver cutting-edge applications." },
  { title: 'Quality Assurance', description: "Quality is at the core of our .NET development process. Rigorous testing ensures the reliability, security, and performance of your applications." },
  { title: 'Collaborative Development', description: "Collaboration is key to success. We involve you in the development process, ensuring transparency, effective communication, and your active participation." },

]

const ExpertiseCardData = [
  { title: 'ASP.NET Development', description: "Leverage the power of ASP.NET for dynamic, feature-rich web applications. Our expertise spans web forms, MVC, and Web API development." },
  { title: '.NET Core Development', description: "Embrace the future with .NET Core development. We build cross-platform applications that are scalable, efficient, and leverage the latest .NET advancements." },
  { title: 'Enterprise Solutions', description: "Our .NET development extends to crafting robust enterprise solutions, addressing complex business challenges with scalable and secure applications." },
  { title: 'Custom .NET Development', description: "Tailor-made solutions are our expertise. We provide custom .NET development services, ensuring that applications align precisely with your unique requirements." },
  { title: 'Integration Services', description: "Seamlessly integrate your .NET applications with third-party APIs, databases, and other systems, enhancing overall functionality." },

]

const TechFancyCardData = [
  { title: 'ASP.NET Web Application Development', description: "Craft visually stunning and functionally rich web applications using ASP.NET, ensuring an engaging user experience." },
  { title: '.NET Core Cross-Platform Development', description: "Embrace cross-platform capabilities with .NET Core development, ensuring your applications reach a wider audience." },
  { title: 'Enterprise Application Development', description: "Address complex business needs with our enterprise application development services, providing scalable and secure solutions." },
  { title: 'Custom .NET Solutions', description: "Tailor-made solutions for your unique business requirements. Our custom .NET development ensures applications that stand out in functionality and design." },
  { title: 'Integration Services', description: "Seamlessly integrate your .NET applications with third-party systems, databases, and APIs, enhancing overall functionality." },
  { title: 'Maintenance and Support', description: "Beyond development, we offer ongoing maintenance and support services, ensuring your .NET applications remain secure, up-to-date, and optimized." },
]


const KeyBenefitsCardData = [
  { alt: "flexible", title: 'Scalability and Flexibility', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/flexible.webp', description: ".NET applications are inherently scalable, accommodating growth and changes in user demands. Flexibility is a key advantage." },
  { alt: "security", title: 'Security Best Practices', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/security.webp', description: "Security is paramount. Benefit from .NET's robust security features, ensuring the protection of sensitive data and secure user interactions." },
  { alt: "crossplatform", title: 'Cross-Platform Capabilities', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/crossplatform.webp', description: "With .NET Core, enjoy cross-platform development, extending your application's reach to various operating systems." },
  { alt: "integration", title: 'Rapid Development', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/integration.webp', description: ".NET's framework streamlines development processes, facilitating rapid application development without compromising quality." },
  { alt: "integration2", title: 'Versatile Integration', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/integration2.webp', description: ".NET seamlessly integrates with a variety of systems, databases, and APIs, ensuring a versatile and well-connected application ecosystem." },
  { alt: "easytouseic", title: 'Rich User Experience', imgUrl: 'https://cdn.technolee.com/image/easytouseic.webp', description: "Leverage the capabilities of ASP.NET for crafting visually stunning web applications, providing users with an engaging and intuitive experience." },

]

const TestimonialsData = [
  { title: 'What Is the Advantage of Choosing .NET for Web Development?', description: '.NET offers scalability, security, cross-platform capabilities, rapid development, versatile integration, and a rich user experience for web applications.' },
  { title: 'How Long Does It Take to Develop a .NET Application?', description: 'Development time varies based on project complexity. Our team ensures efficient development without compromising quality.' },
  { title: 'Can You Migrate an Existing App to .NET?', description: 'Yes, we provide migration services to .NET, ensuring your existing app benefits from the scalability and security of the .NET framework.' },
  { title: 'What Are the Key Features of a .NET Application?', description: '.NET applications offer scalability, security, cross-platform capabilities, rapid development, versatile integration, and a rich user experience.' },
  { title: 'Is .NET Development More Expensive Than Other Technologies?', description: 'While initial costs may vary, the long-term benefits of scalability, security, and versatility make .NET development a cost-effective choice.' },
  { title: 'How Do You Ensure Security in .NET Applications?', description: "We adhere to .NET's security best practices, implementing measures to protect user data and ensure the overall security of .NET applications." },
  { title: 'Can .NET Applications Integrate with Third-Party Systems?', description: 'Yes, .NET seamlessly integrates with various systems, databases, and APIs, ensuring a well-connected and versatile application ecosystem.' },
  { title: 'What Role Does .NET Play in Enterprise Application Development?', description: '.NET is a key player in crafting robust enterprise solutions, addressing complex business challenges with scalable and secure applications.' },

]

export default function DotNet() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Expert .NET Development Services for Robust Applications</title>
        <meta name="description" content="Explore our comprehensive .NET development services, covering ASP.NET, .NET Core, and more. Our experienced .NET developers craft scalable, secure, and feature-rich applications tailored to your business needs." />
        <meta name="keywords" content=".NET Development, ASP.NET, .NET Core, Enterprise Application Development, Custom .NET Solutions, .NET Integration Services, .NET Maintenance and Support, Scalable .NET Applications, Secure .NET Development, Cross-Platform .NET, Rapid .NET Development, .NET Migration Services, Versatile .NET Integration, Rich User Experience with .NET, .NET Security Best Practices, .NET Developers, .NET Consulting Services" />
      </Helmet>

      <TechnologiesDetails TechName={TechName} AboutCardData={AboutCardData} BannerData={BannerData} TechDetailsCardData={TechDetailsCardData} TechFancyCardData={TechFancyCardData} ExpertiseCardData={ExpertiseCardData} KeyBenefitsCardData={KeyBenefitsCardData} TestimonialsData={TestimonialsData} />
    </>
  )
}
