import React from 'react'
import './blogcard.css'
import { FaUser } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function BlogCard({ BlogCardData }) {
    return (
        <div>
            <section className="articles" >
                {BlogCardData.map((item, ind) => {
                    return (
                        <article key={ind}>
                            <div className="article-wrapper">
                                <div style={{ position: 'relative' }}>
                                    <figure>
                                        <img src={item.imgUrl}  width='100%' height='100%' alt={item.alt} />
                                    </figure>
                                    <div className='blog-img-name'>
                                        <p >{item.imgChip}</p>
                                    </div>
                                </div>
                                <div className="article-body">
                                    <div className='flex blog-content-main'>
                                        <div className='flex blog-content'>
                                            <FaUser className='blog-icon' />
                                            <p>{item.Createdby}</p>
                                        </div>
                                        <div className='flex blog-date'>
                                            <FaRegCalendarAlt className='blog-icon' />
                                            <p>{item.BlogDate}</p>
                                        </div>
                                    </div>
                                    <h2>{item.title}</h2>
                                    <p>{item.description}</p>
                                </div>
                                <hr />
                                <div className='flex-space-between blog-footer'>
                                    <Link className='flex-space-between' to={item.Link}><p>Read Full Article</p>
                                        <FaLongArrowAltRight className='blog-footer-icon' /></Link>
                                </div>
                            </div>
                            
                        </article>
                    )
                })}

            </section>

        </div>
    )
}
