import React from 'react'
import TechnologiesDetails from '../../../../camponets/TechnologiesDetails/TechnologiesDetails';
import { Helmet } from 'react-helmet';

const TechName = "VueJS"

const BannerData = [
  { alt: "", BannerimgUrl: 'https://cdn.technolee.com/image/technologies/technobennar/vuejsbanner.webp', htmlString: 'Redefine Web Experiences with <span class="text-highlight">VueJS</span>', BennerDecription: "Experience the future of web development with our VueJS development services. From dynamic interfaces to seamless performance, we leverage VueJS to enhance your digital presence." }
]

const AboutCardData = [
  { alt: "", imgUrl: 'https://cdn.technolee.com/image/technologies/technoexceptional/vuextechnology.webp', AboutTitle: "Your Partner for Exceptional VueJS Development", AboutDecription: "Discover why businesses choose our VueJS development services for building modern, user-centric web applications." }
]

const TechDetailsCardData = [
  { title: 'Expert VueJS Developers', description: "Our skilled VueJS developers bring expertise in creating scalable and interactive web applications, ensuring a superior user experience." },
  { title: 'Innovative Development Approach', description: "Embrace innovation with our forward-thinking approach to VueJS development. We leverage the latest features and design principles for cutting-edge applications." },
  { title: 'Component-Based Architecture', description: "Our developers specialize in building applications using Vue's component-based architecture, ensuring modular, maintainable, and efficient code." },
  { title: 'Performance Optimization', description: "We prioritize performance optimization, delivering web applications that are fast, responsive, and provide an exceptional user experience." },
  { title: 'Collaborative Development', description: "Collaboration is integral to our development process. We involve you in decision-making, ensuring transparency and effective communication throughout the project." },
]

const ExpertiseCardData = [
  { title: 'Single Page Applications (SPAs)', description: "Leverage the power of VueJS to build efficient and dynamic single-page applications that deliver a seamless user experience." },
  { title: 'Vue Component Development', description: "We specialize in developing Vue components, ensuring reusability and efficiency in your web application development." },
  { title: 'Vue Router Integration', description: "Integrate Vue Router for seamless navigation and state management, ensuring a smooth user experience in your VueJS applications." },
  { title: 'Vuex State Management', description: "Utilize Vuex for efficient state management in your VueJS applications, ensuring a scalable and well-organized architecture." },
  { title: 'Real-Time Features Implementation', description: "Implement real-time features such as live updates, messaging, and collaboration, enhancing user engagement in your VueJS applications." },
]

const TechFancyCardData = [
  { title: 'Custom VueJS Application Development', description: "Craft tailored web applications that align with your unique business requirements, ensuring functionality and design excellence." },
  { title: 'VueJS Component Library development', description: "Develop reusable VueJS component libraries, streamlining your development process and ensuring consistency across applications." },
  { title: 'Vue Router Integration Services', description: "Integrate Vue Router for seamless navigation and state management, ensuring a smooth user experience in your VueJS applications." },
  { title: 'Vuex State Management', description: "Utilize Vuex for efficient state management, ensuring a scalable and well-organized architecture in your VueJS applications." },
  { title: 'Real-Time Features Implementation', description: "Implement real-time features that enhance user engagement, including live updates, messaging, and collaborative functionalities." },
  { title: 'Performance Optimization', description: "Our team dedicates efforts to optimize the performance of your VueJS applications, ensuring they run efficiently on various devices." },
  { title: 'Maintenance and Support', description: "Beyond development, we offer ongoing maintenance and support services to keep your VueJS applications secure, up-to-date, and optimized." },
]


const KeyBenefitsCardData = [
  { alt: "integration", title: 'Modular Architecture', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/integration.webp', description: "VueJS's modular architecture allows for the development of applications with components that are easily maintainable, scalable, and reusable." },
  { alt: "integration2", title: 'Ease of Integration', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/integration2.webp', description: "VueJS seamlessly integrates with existing projects and libraries, making it a versatile choice for web development." },
  { alt: "reusability", title: 'Vue Component Reusability', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/reusability.webp', description: "Benefit from Vue's component-based approach, ensuring the reusability of components across your VueJS applications." },
  { alt: "crossplatform", title: 'Flexible and Progressive Framework', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/crossplatform.webp', description: "VueJS is known for its flexibility and progressive nature, allowing developers to adopt and use features at their own pace." },
  { alt: "Developer-Friendly", title: 'Developer-Friendly Ecosystem', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/ecosystem.webp', description: "VueJS provides a developer-friendly ecosystem with clear documentation, making it accessible for both beginners and experienced developers." },
  { alt: "designaesthetics", title: 'Ease of Learning and Adoption', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/designaesthetics.webp', description: "VueJS's simplicity and ease of learning make it an ideal choice for developers looking to quickly adopt and implement web development projects." },
]

const TestimonialsData = [
  { title: 'What Is the Advantage of Choosing VueJS for Web Development?', description: "VueJS offers a modular architecture, ease of integration, Vue component reusability, a flexible and progressive framework, a developer-friendly ecosystem, and ease of learning and adoption." },
  { title: 'How Long Does It Take to Develop a VueJS Application?', description: "Development time varies based on project complexity. Our team ensures efficient development without compromising quality." },
  { title: 'Can VueJS Applications Integrate with Other Technologies?', description: "Yes, VueJS applications seamlessly integrate with various technologies, ensuring versatility and compatibility." },
  { title: 'What Are the Key Features of a VueJS Application?', description: "Key features include modular architecture, ease of integration, Vue component reusability, a flexible and progressive framework, a developer-friendly ecosystem, and ease of learning and adoption." },
  { title: 'Is VueJS Development Suitable for Single Page Applications (SPAs)?', description: "Yes, VueJS is highly suitable for developing efficient and dynamic single-page applications (SPAs) that provide a seamless user experience." },
  { title: 'How Do You Ensure the Performance Optimization of VueJS Applications?', description: "Our team dedicates efforts to optimize the performance of VueJS applications, ensuring they run efficiently on various devices." },
  { title: 'Can Real-Time Features Be Implemented in VueJS Applications?', description: "Yes, VueJS applications can implement real-time features such as live updates, messaging, and collaboration to enhance user engagement." },
  { title: 'What Role Does UI/UX Design Play in VueJS Development?', description: "UI/UX design is crucial in VueJS development to create interfaces that are visually appealing, intuitive, and aligned with user expectations." },
]

export default function VueJs() {
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Elevate User Experiences with VueJS Development</title>
        <meta name="description" content="Explore our VueJS development services for creating interactive, scalable, and modern web applications. Our proficient VueJS developers bring creativity and efficiency to your digital solutions." />
        <meta name="keywords" content="VueJS Development, VueJS Developers, Modular Architecture, Ease of Integration, Vue Component Reusability, Flexible VueJS Framework, Developer-Friendly VueJS Ecosystem, Ease of Learning VueJS, Custom VueJS Applications, VueJS Component Library Development, Vue Router Integration Services, Vuex State Management, VueJS Maintenance and Support, Advantages of VueJS, VueJS Development Benefits, VueJS FAQs" />
      </Helmet>

      <TechnologiesDetails TechName={TechName} AboutCardData={AboutCardData} BannerData={BannerData} TechDetailsCardData={TechDetailsCardData} TechFancyCardData={TechFancyCardData} ExpertiseCardData={ExpertiseCardData} KeyBenefitsCardData={KeyBenefitsCardData} TestimonialsData={TestimonialsData} />
    </div>
  )
}
