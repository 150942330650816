import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../camponets/Banner/Banner';
import ServicesCard from '../../../camponets/ServicesCard/ServicesCard';


export default function WebDevelopment() {
    const enterpriseSubServices = [{ name: 'Intranet Portals' }, { name: 'Enterprise Resource Planning (ERP)' }, { name: 'Customer Relationship Management (CRM)' }]
    const eCommerceSubServices = [{ name: 'Online Store Development' }, { name: 'Payment Gateway Integration' }, { name: 'Inventory Management' }]
    const mobileSubServices = [{ name: 'API Development' }, { name: 'Data Management' }, { name: 'User Authentication' }]
    const customWebSubServices = [{ name: 'Content Management Systems (CMS):' }, { name: 'Web Applications' }]

    const AllServices = [{
        id: "enterprise-solutions",
        image: 'https://cdn.technolee.com/image/services/webdevelopment/enterprisesolutions.webp',
        title: "Enterprise Solutions",
        description: "Our Enterprise Solutions web development service is designed to empower large organizations with tailored, scalable, and robust digital solutions. We understand the complexities of big businesses and offer intranet portals, enterprise resource planning (ERP) systems, and customer relationship management (CRM) platforms. These solutions streamline operations, enhance efficiency, and strengthen growth prospects. At Technolee, we take pride in developing solutions that meet the unique demands of your enterprise, making processes more efficient, teams more connected, and data more accessible. Our enterprise services encompass:",
        iconData: [],
        subServicesData: enterpriseSubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "enterprisesolutions"
    }, {
        id: "ecommerce-web-development",
        image: 'https://cdn.technolee.com/image/services/webdevelopment/ecommerceweb.webp',
        title: "eCommerce Web Development",
        description: "Our eCommerce Web Development service is all about creating engaging and feature-rich online stores. We're experts at crafting visually appealing and user-friendly e-commerce platforms that captivate your audience. We handle everything from designing the perfect online store to integrating secure payment gateways and implementing robust inventory management systems. With our expertise, your online business can thrive, attracting customers, managing transactions securely, and ensuring smooth inventory operations. Our eCommerce offerings include:",
        iconData: [],
        subServicesData: eCommerceSubServices,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "ecommerceweb"

    }, {
        id: "mobile-backend-api",
        image: 'https://cdn.technolee.com/image/services/webdevelopment/mobilebackend.webp',
        title: "Mobile Backend & APIs",
        description: "Mobile apps rely on a solid foundation, and that's precisely what our Mobile Backend & APIs service provides. We design and develop RESTful APIs, ensuring a seamless connection between your mobile apps and backend systems. Our data management capabilities make sure that data storage, retrieval, and synchronization are efficient. With robust user authentication and authorization mechanisms in place, your mobile apps will be both secure and fully functional. Our services include:",
        iconData: [],
        subServicesData: mobileSubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "mobilebackend"

    }, {
        id: "custom-web-development",
        image: 'https://cdn.technolee.com/image/services/webdevelopment/customwebdevelopment.webp',
        title: "Custom Web Development",
        description: "Our Custom Web Development service is all about bringing your unique vision to life. Whether you need a bespoke content management system (CMS) for seamless content management and publishing or custom web applications tailored to your specific business needs, we've got you covered. Our custom solutions are designed to align perfectly with your requirements, ensuring that your digital presence stands out and performs exactly as intended. Our custom development offerings encompass:",
        iconData: [],
        subServicesData: customWebSubServices,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "customwebdevelopment"

    }]

    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <title>Web Development Services - Enterprise, eCommerce, Mobile Backend, Custom Web Development</title>
                <meta name="description" content="Explore our web development services; including enterprise solutions, eCommerce, mobile backend and APIs, and custom web development. We deliver tailored web solutions for businesses across various industries." />
                <meta name="keywords" content="web development services, enterprise solutions, eCommerce, mobile backend, APIs, custom web development, Technolee" />
            </Helmet>

            <Banner
                alt={'web banner'}
                image="https://cdn.technolee.com/image/services/webdevelopment/webbanner.webp"
                title="Tailored Web Development Services"
                description="Unleash the potential of the web with our customized solutions. Discover our web development expertise in enterprise solutions, eCommerce, mobile backend, APIs, and custom web development."
            />
            <div className='page-content container'>
                <div>
                    {AllServices.map((item, ind) => {
                        return (
                            <ServicesCard
                                key={ind}
                                id={item.id}
                                image={item.image}
                                title={item.title}
                                description={item.description}
                                iconData={item.iconData}
                                subServicesData={item.subServicesData}
                                isLeft={item.isLeft}
                                isFullWidth={item.isFullWidth}
                                alt={item.alt}
                                isReadMore={item.isReadMore}
                            />
                        )
                    })}
                </div>
            </div>
        </>
    )
}