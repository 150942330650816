import React from 'react'
import { Link } from 'react-router-dom'
import './animationbutton.css'

export default function AnimationButton({ title, link, setOpen }) {
    return (
        <div className='wrapper'>
            <Link to={link} onClick={() => setOpen(true)}><span>{title}</span></Link>
        </div>
    )
}
