import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../camponets/Banner/Banner';
import ServicesCard from '../../../camponets/ServicesCard/ServicesCard';


export default function DigitalTransformation() {
    const operationalTransformation = [{ name: 'Process Mapping and Analysis' }, { name: 'Automation Solutions' }, { name: 'Performance Analytics' }]
    const CustomerExperienceTransformation = [{ name: 'CRM Implementation' }, { name: 'Chatbot Integration' }, { name: 'Personalized Customer Journeys' }]
    const TechnologicalEvolution = [{ name: 'Technology Assessment' }, { name: 'AI and IoT Integration' }, { name: 'Blockchain Solutions' }]
    const DataDrivenTransformation = [{ name: 'Predictive Analysis' }, { name: 'Actionable Insight' }, { name: 'Robust Data Management' }, { name: 'Real-Time Analytics' }]

    const AllServices = [{
        id: "Operational-Transformation",
        image: "https://cdn.technolee.com/image/services/digitaltransfomation/operationaltransformation.webp",
        title: "Operational Transformation",
        description: "Revolutionize your business processes with Technolee's Operational Transformation services. We meticulously map and analyze your existing workflows, identifying inefficiencies and bottlenecks. Our experts leverage advanced automation solutions to streamline operations, reducing manual effort and enhancing overall productivity. Through comprehensive performance analytics, we provide valuable insights that empower informed decision-making. Experience a new era of efficiency as we optimize your processes, ensuring that your business operates at its peak potential.",
        iconData: [],
        subServicesData: operationalTransformation,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "operationaltransformation"
    }, {
        id: "Customer-Experience-Transformation",
        image: "https://cdn.technolee.com/image/services/digitaltransfomation/customerexperiencetransformation.webp",
        title: "Customer Experience Transformation",
        description: "At Technolee, we redefine customer interactions through our Customer Experience Transformation services. We prioritize creating seamless, personalized experiences that leave a lasting impact. Our team excels in implementing CRM solutions to strengthen customer relationships, integrating chatbots for instant support, and designing personalized customer journeys. With a focus on enhancing satisfaction and loyalty, our services elevate your brand in the eyes of your customers, fostering a connection that goes beyond transactions.",
        iconData: [],
        subServicesData: CustomerExperienceTransformation,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "customerexperiencetransformation"
    }, {
        id: "Technological-Evolution",
        image: "https://cdn.technolee.com/image/services/digitaltransfomation/technologicalevolution.webp",
        title: "Technological Evolution",
        description: "Stay ahead of the curve with Technolee's Technological Evolution services, guiding your business through the dynamic landscape of emerging technologies. Our experts conduct thorough technology assessments, ensuring your systems align with the latest advancements. From integrating AI and IoT solutions to implementing Blockchain for enhanced security, we equip your business with the tools needed to thrive in a rapidly evolving digital ecosystem. Embrace innovation, adaptability, and future readiness with our Technological Evolution services, setting the stage for sustained success.",
        iconData: [],
        subServicesData: TechnologicalEvolution,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "technologicalevolution"
    }, {
        id: "Data-Driven-Transformation",
        image: "https://cdn.technolee.com/image/services/digitaltransfomation/datadriventransformation.webp",
        title: "Data-Driven Transformation",
        description: "In an era dominated by information, Technolee's Data-driven Transformation services empower businesses to leverage their data effectively. We focus on optimizing data collection, storage, and analysis to enable informed decision-making. Our experts implement robust data management systems, ensuring the integrity and accessibility of your critical information. From predictive analytics that anticipates market trends to actionable insights that drive strategic initiatives, this transformation type propels your business forward in a data-driven landscape. Elevate your decision-making process and gain a competitive edge with Technolee's Data-driven Transformation services.",
        iconData: [],
        subServicesData: DataDrivenTransformation,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "datadriventransformation"
    }]

    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                <title>Digital Transformation Services by Technolee - Transforming Businesses for the Future</title>
                <meta name="description" content="Explore Technolee's Digital Transformation services, designed to revolutionize businesses. Our expert solutions encompass various transformation types, ensuring enhanced efficiency, agility, and competitiveness." />
                <meta name="keywords" content="Digital Transformation, Business Innovation, Technological Evolution, Strategic Transformation, Digital Solutions, Future-Ready Business" />
            </Helmet>

            <Banner
                alt={"Digital Transformation"}
                image="https://cdn.technolee.com/image/services/digitaltransfomation/digitalbanner.webp"
                title="Empower Your Business with Data Excellence"
                description="Unlock the potential of your data with Technolee's Data-Driven Transformation. From predictive analytics to real-time insights, we optimize data management for informed decision-making and a competitive edge."
            />
            <div className='page-content container'>
                <div>
                    {AllServices.map((item, ind) =>
                        <ServicesCard
                            key={ind}
                            id={item.id}
                            image={item.image}
                            title={item.title}
                            description={item.description}
                            iconData={item.iconData}
                            subServicesData={item.subServicesData}
                            isLeft={item.isLeft}
                            alt={item.alt}
                            isFullWidth={item.isFullWidth}
                            isReadMore={item.isReadMore}
                        />
                    )}
                </div>
            </div>
        </>
    )
}
