import React from 'react'
import TechnologiesDetails from '../../../../camponets/TechnologiesDetails/TechnologiesDetails';
import { Helmet } from 'react-helmet';

const TechName = ""

const BannerData = [
    { alt: "googlecloudbanner", BannerimgUrl: 'https://cdn.technolee.com/image/technologies/technobennar/googlecloudbanner.webp', htmlString: 'Elevate Your Digital Journey with <span class="text-highlight">Google Cloud</span>', BennerDecription: "Embark on a transformative cloud experience with our Google Cloud development services. From scalable infrastructure to innovative solutions, we empower your digital evolution." }
]

const AboutCardData = [
    { alt: "googlecloudtechnology", imgUrl: 'https://cdn.technolee.com/image/technologies/technoexceptional/googlecloudtechnology.webp', AboutTitle: "Your Trusted Google Cloud Development Partner", AboutDecription: "Discover why businesses trust our Google Cloud development services for creating robust, scalable, and secure cloud-based solutions." }
]

const TechDetailsCardData = [
    { title: 'Certified Google Cloud Experts', description: "Our certified Google Cloud experts bring deep knowledge and expertise to design, deploy, and optimize solutions on the Google Cloud Platform." },
    { title: 'Strategic Cloud Consulting', description: "Benefit from strategic cloud consulting services, where we align Google Cloud solutions with your business goals, ensuring maximum efficiency and ROI." },
    { title: 'Scalable and Flexible Solutions', description: "We specialize in developing scalable and flexible solutions on Google Cloud, providing the foundation for your applications to grow seamlessly with your business." },
    { title: 'Security and Compliance Focus', description: "Security is at the forefront of our Google Cloud development. We ensure that your cloud solutions adhere to the highest security standards and compliance requirements." },
    { title: 'Cost Optimization strategies', description: "Implement cost optimization strategies to ensure that your Google Cloud resources are utilized efficiently, maximizing the value of your investment." },
]

const ExpertiseCardData = [
    { title: 'Google Cloud App Engine Development', description: "We specialize in developing scalable and flexible applications on Google Cloud App Engine, ensuring streamlined deployment and management." },
    { title: 'Serverless Architecture with Google Cloud Functions', description: "Implement serverless architecture using Google Cloud Functions, allowing you to build and run applications without managing infrastructure." },
    { title: 'Microservices Development on Google Cloud', description: "Leverage microservices architecture on Google Cloud, creating modular and scalable applications that can be developed, deployed, and scaled independently." },
    { title: 'Google Cloud DevOps Integration', description: "Integrate Google Cloud into your DevOps practices for streamlined development, continuous integration, and continuous delivery, ensuring rapid and reliable software releases." },
    { title: 'Data Storage and Analytics with Google Cloud', description: "Utilize Google Cloud services for efficient data storage and analytics, leveraging services like Cloud Storage, Cloud SQL, and BigQuery for optimal insights." },
]

const TechFancyCardData = [
    { title: 'Google Cloud App Engine Development', description: "Develop scalable and flexible applications on Google Cloud App Engine, harnessing the power of streamlined deployment and management." },
    { title: 'Serverless Architecture with Google Cloud Functions', description: "Implement serverless architecture using Google Cloud Functions, allowing you to focus on building code without managing servers." },
    { title: 'Microservices Development on Google Cloud', description: ": Embrace microservices development on Google Cloud for modular, scalable, and independently deployable applications." },
    { title: 'Google Cloud DevOps Integration', description: "Integrate Google Cloud into your DevOps practices for automated testing, continuous integration, and continuous delivery, ensuring efficient and reliable software delivery." },
    { title: 'Data Storage and Analytics with Google Cloud', description: "Leverage Google Cloud services like Cloud Storage, Cloud SQL, and BigQuery for efficient data storage, retrieval, and analytics." },
    { title: 'Google Cloud Security and Compliance', description: "Prioritize security and compliance in your Google Cloud solutions, ensuring that your applications adhere to industry standards and regulations." },
    { title: 'Cost Optimization Strategies', description: "Implement cost optimization strategies for your Google Cloud resources, ensuring that your investment aligns with your business goals." },
    { title: 'Google Cloud Maintenance and Support', description: "Beyond development, we offer ongoing maintenance and support services, ensuring that your Google Cloud applications are secure, up-to-date, and performing optimally." },
]


const KeyBenefitsCardData = [
    { alt: "scalability", title: 'Scalability and Flexibility', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/scalability.webp', description: "Google Cloud provides on-demand scalability, allowing your applications to grow seamlessly with the changing needs of your business." },
    { alt: "efficienttest", title: 'Cost-Effective Solutions', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/efficienttest.webp', description: "Optimize costs with Google Cloud, paying only for the resources you consume, and benefit from a flexible pricing model that suits your budget." },
    { alt: "flexible", title: 'Global Reach and Availability', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/flexible.webp', description: "Leverage Google Cloud's global infrastructure to ensure high availability and low-latency experiences for users across the globe." },
    { alt: "security", title: 'Security and Compliance', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/security.webp', description: "Google Cloud prioritizes security and compliance, providing a secure foundation for your applications to meet industry standards and regulations." },
    { alt: "innovation", title: 'Innovation with Cutting-Edge Services', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/innovation.webp', description: "Stay at the forefront of innovation with Google Cloud's ever-expanding suite of services, enabling you to incorporate the latest technologies into your applications." },
    { alt: "reusability", title: 'Reliability and Redundancy', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/reusability.webp', description: "Ensure reliability and redundancy with Google Cloud, where your applications benefit from a robust and resilient infrastructure." },
]

const TestimonialsData = [
    { title: 'Why Choose Google Cloud for Cloud Development?', description: "Google Cloud offers scalability, flexibility, cost-effectiveness, global reach, security, and a wide range of cutting-edge services, making it an ideal choice for cloud development." },
    { title: 'How Does Google Cloud Ensure Security and Compliance?', description: "Google Cloud prioritizes security and compliance through robust infrastructure, encryption, access controls, and adherence to industry standards and regulations." },
    { title: 'Are the Key Google Cloud Services for Cloud Application Development?', description: "Key Google Cloud services for cloud application development include Google App Engine, Google Cloud Functions for serverless architecture, Google Kubernetes Engine for container orchestration, and more." },
    { title: 'Can Existing Applications Be Migrated to Google Cloud?', description: "Yes, existing applications can be migrated to Google Cloud using various migration strategies, ensuring a smooth transition and optimization for the cloud environment." },
    { title: 'How Does Google Cloud Ensure Data Storage and Analytics?', description: "Google Cloud provides services like Cloud Storage, Cloud SQL, and BigQuery for efficient data storage, retrieval, and analytics." },
    { title: 'What Measures Are Taken for Google Cloud Cost Optimization?', description: "Google Cloud cost optimization includes using committed use discounts, right-sizing resources, leveraging preemptible instances, and monitoring resource usage for efficient budget management." },
    { title: 'Is Google Cloud Suitable for Small and Medium-sized Businesses (SMBs)?', description: "Yes, Google Cloud is suitable for SMBs, offering scalable solutions that can be tailored to the specific needs and budget constraints of small and medium-sized businesses." },
    { title: 'How Does Google Cloud Support Global Application Deployment?', description: "Google Cloud's global infrastructure ensures high availability and low-latency experiences for users worldwide, supporting global application deployment." },
]

export default function GoogleCloud() {
    return (
        <div>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <title>Unleash Innovation with Google Cloud Development</title>
                <meta name="description" content="Explore our Google Cloud development services to harness the capabilities of Google Cloud Platform. Elevate your applications with scalable, flexible, and cutting-edge cloud solutions." />
                <meta name="keywords" content="Google Cloud Development, Google Cloud Platform, GCP Development, Google Cloud Solutions, Google Cloud App Engine, Serverless Architecture, Microservices Development, Google Cloud DevOps Integration, Data Storage and Analytics with Google Cloud, Google Cloud Security and Compliance, Cost Optimization Strategies, Google Cloud Maintenance and Support, Advantages of Google Cloud, Google Cloud Development Benefits, Google Cloud FAQs" />
            </Helmet>

            <TechnologiesDetails TechName={TechName} AboutCardData={AboutCardData} BannerData={BannerData} TechDetailsCardData={TechDetailsCardData} TechFancyCardData={TechFancyCardData} ExpertiseCardData={ExpertiseCardData} KeyBenefitsCardData={KeyBenefitsCardData} TestimonialsData={TestimonialsData} />
        </div>
    )
}
