import React from 'react'
import './techfancycard.css'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'

export default function TechFancyCard({ TechFancyCardData }) {
    return (
        <div>
            <div className='flex'>
                {TechFancyCardData.map((item, ind) => {
                    return (
                        <div className='tech-fency-card' key={ind}>
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </div>
                    )
                })}

                {/*<div className='tech-fency-button'>
                    <h3>Xamarin Consulting</h3>
                    <p>Do you need some help with it? Technolee would love to help you! Kindly click on ‘Contact Us’ to reach us and share your query.</p>
                    <div>
                        <Link to='/contactus' ><span><BsFillTelephoneFill className='tech-fency-button-icon' />Contact Us</span></Link>
                    </div>
        </div>*/}
            </div>
        </div>
    )
}
