import React from "react";
import PageSectionSecondary from "../../camponets/Page-Section-Secondary/PageSectionSecondary";
import { FaRegCalendarAlt, FaUser } from "react-icons/fa";
import { Helmet } from 'react-helmet';

export default function BlogDetails() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Blog Detail - Technolee | Your Partner in Software & Web Development</title>
        <meta name="description" content="Read the latest blog from Technolee, your leading software and web development partner in Ahmedabad, India. Stay updated with the latest trends in digital transformation and product innovation." />
        <meta name="keywords" content="blog, software development, web development, digital transformation, product development, Ahmedabad, India, Technolee" />
      </Helmet>

      <div className="page-content container blog-details-main">
        <PageSectionSecondary title="Flutter's Cross-Platform App Development: An Profitable Business Strategy" />
        <article>
          <div style={{ position: "relative" }}>
            <figure className="blogdetails-fig">
              <div width="100%" height="300px">
                <img src='' alt='Flutter App Development' />
              </div>
            </figure>
            <div className="blogdetails-img-name">
              <p>Flutter App Development</p>
            </div>
          </div>
        </article>
        <div className="flex blog-content-main">
          <div className="flex blog-content" style={{ width: "12%" }}>
            <FaUser className="blog-icon" />
            <p>Kinjan Shah</p>
          </div>
          <div className="flex blog-date">
            <FaRegCalendarAlt className="blog-icon" />
            <p>Jun 3, 2023</p>
          </div>
        </div>

        <div className="blog-details-content">
          <p>
            When it comes to developing mobile applications, business owners
            often find themselves at a crossroads. They are puzzled by questions
            about the best platform to choose, or whether they should go for a
            cross-platform solution. In this era, where time is of the essence
            and the goal is to reach as many users as possible, cross-platform
            solutions, such as Flutter, are proving to be a profitable business
            strategy.
          </p>
          <h1>Why Choose Cross-Platform?</h1>
          <p>
            The decision to go with cross-platform app development is a
            strategic one. This approach offers a slew of benefits that make it
            an increasingly popular choice among businesses. Here why:
          </p>

          <ul className="blog-list-ul">
            <li>
              <span>Time and Cost Efficiency :</span> Unlike native app
              development, where you need to write separate code bases for
              different platforms, cross-platform development requires only one.
              This single codebase works seamlessly on multiple platforms,
              substantially reducing the time spent on development. Less time
              means less money, making cross-platform development a
              cost-effective solution, especially for small and medium
              enterprises.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
