import React, { useEffect, useState } from 'react'
import './tabs.css';
import { MdWeb } from "react-icons/md";
import { BsServer } from "react-icons/bs";
import { TbDeviceMobileCode } from "react-icons/tb";
import { FaCloudArrowDown } from "react-icons/fa6";
import { Link } from 'react-router-dom';


export default function Tabs() {
    const [check, setCheck] = useState(true)

    return (
        <>
            <div className="tabs">
                <input type="radio" id="tab1" onClick={() => setCheck(true)} checked={check ? true : false} name="tab-control" readOnly />
                <input type="radio" id="tab2" onClick={() => setCheck(false)} name="tab-control" readOnly />
                <input type="radio" id="tab3" onClick={() => setCheck(false)} name="tab-control" readOnly />
                <input type="radio" id="tab4" onClick={() => setCheck(false)} name="tab-control" readOnly />
                <ul>
                    <li title="Features"><label htmlFor="tab1"><MdWeb style={{ height: "25px" }} /><br /><span>Front End</span></label></li>
                    <li title="Delivery Contents"><label htmlFor="tab2"><BsServer /><br /><span>Back End</span></label></li>
                    <li title="Shipping"><label htmlFor="tab3"><TbDeviceMobileCode style={{ height: "25px" }} /><br /><span>Mobile Devlopment</span></label></li>
                    <li title="Returns"><label htmlFor="tab4"><FaCloudArrowDown /><br /><span>Infra & DevOps</span></label></li>
                </ul>

                <div className="slider">
                    <div className="indicator"></div>
                </div>
                <div className="content">
                    <section>
                        <div className='flex tab-tech-block'>
                            <Link to='/technologies/reactjs'>  <div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/reactnative.webp" alt='React' />
                                </div>
                                <p>React Js</p>
                            </div></Link>
                            <Link to='/technologies/angular'><div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/angular.webp" alt='angular Js' />
                                </div>
                                <p>Angular</p>
                            </div></Link>
                            <Link to='/technologies/vuejs'><div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/vuejs.webp" alt='VueJs' />
                                </div>
                                <p>Vue Js</p>
                            </div></Link>
                            <Link to='/technologies/typescript'><div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/typescript.webp" alt='TS' />
                                </div>
                                <p>TypeScript</p>
                            </div></Link>
                        </div>
                    </section>
                    <section>
                        <div className='flex tab-tech-block'>
                            <Link to='/technologies/dotnet'>  <div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/dotnet.webp" alt='Dotnet' />
                                </div>
                                <p>.Net</p>
                            </div></Link>
                            <Link to='/technologies/nodejs'><div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/nodejs.webp" alt='Node Js' />
                                </div>
                                <p>Node Js</p>
                            </div></Link>
                            <Link to='/technologies/sqlserver'><div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/sqlserver.webp" alt='SQL Server' />
                                </div>
                                <p>SQL Server</p>
                            </div></Link>

                        </div>
                    </section>
                    <section>
                        <div className='flex tab-tech-block'>
                            <Link to='/technologies/reactnative'>  <div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/reactnative.webp" alt='React Native' />
                                </div>
                                <p>React Native</p>
                            </div></Link>
                            <Link to='/technologies/flutter'><div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/flutter.webp" alt='Flutter' />
                                </div>
                                <p>Flutter</p>
                            </div></Link>
                            <Link to='/technologies/ios'><div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/ios.webp" alt='iOS' />
                                </div>
                                <p>IOS</p>
                            </div></Link>
                            <Link to='/technologies/android'><div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/android.webp" alt='Android' />
                                </div>
                                <p>Android</p>
                            </div></Link>

                        </div>
                    </section>
                    <section>
                        <div className='flex tab-tech-block'>
                            <Link to='/technologies/aws'>  <div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/aws.webp" alt='AWS' />
                                </div>
                                <p>Aws</p>
                            </div></Link>
                            <Link to='/technologies/azure'><div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/azure.webp" alt='Azure' />
                                </div>
                                <p>Azure</p>
                            </div></Link>
                            <Link to='/technologies/googlecloud'><div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/googlecloud.webp" alt='GoogleCloud' />
                                </div>
                                <p>GoogleCloud</p>
                            </div></Link>
                            <Link to='/technologies/selenium'><div className='tab-list-main'>
                                <div className='tab-list-div-main'>
                                    <img className='tab-list-div-img' src="https://cdn.technolee.com/image/services/selenium.webp" alt='Selenium' />
                                </div>
                                <p>Selenium</p>
                            </div></Link>

                        </div>
                    </section>
                </div>
            </div>
        </>

    )

}
