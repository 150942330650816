import React from 'react';
import './keybenefitscard.css'

export default function KeyBenefitsCard({ KeyBenefitsCardData }) {
    return (
        <>
            <div className='flex keybenefits-top'>
                {KeyBenefitsCardData.map((item, ind) => {
                    return (
                        <div className='keybenefits-main' key={ind}>
                            <div className='keybenefits-image-container'>
                                <img className='keybenefits-img1' src={item.imgUrl} alt={item.alt} />
                            </div>

                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
