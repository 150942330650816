import React from 'react'
import './expertisecard.css'

export default function ExpertiseCard({ ExpertiseCardData }) {
    return (
        <>
            <div className='flex expertisecard'>
                {ExpertiseCardData.map((item, ind) => {
                    return (
                        <div className='expertisecard-main' key={ind}>
                            <h2>{item.title}</h2>
                            <p>{item.description}</p>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
