import React from 'react'
import './techdetailscard.css'
import { MdRadioButtonChecked } from 'react-icons/md'

export default function TechDetailsCard({ TechDetailsCardData }) {
    return (
        <>
            <div className='flex-space-between '>
                {TechDetailsCardData.map((item,ind)=>{
                    return(
                        <div className='techdetails' key={ind}>
                        <div className='flex-space-between techdetails-main'>
                            <MdRadioButtonChecked className='techdetails-icon' />
                            <div className='techdetails-title'><h2>{item.title}</h2></div>
                        </div>
                        <p className='techdetails-decription'>{item.description}</p>
                    </div>
                    )
                }) }
               
            </div>
        </>
    )
}
