import React from 'react'
import './symbocs.css'

export default function Symbocs({ data }) {
    return (
        <div className='flex-space-between symbocs-main'>
            {data.map((item, ind) => {
                return (
                    <div key={ind} className='symbocs-content' >
                        <img src={item.imgUrl} alt={item.alt} />
                        <h3>{item.title}</h3>
                    </div>
                )
            })}
        </div>
    )
}
