import React from 'react'
import './secondarybutton.css'
import { Link } from 'react-router-dom'
import { BsFillTelephoneFill } from 'react-icons/bs'

export default function SecondaryButtom({ link, title }) {
  return (
    <div>
      <div id='secondary-wrapper'>
        <Link to='/contactus' ><span><BsFillTelephoneFill className='secondary-wrapper-icon' />Contact Us</span></Link>
      </div>
    </div>
  )
}
