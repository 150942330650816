import React from 'react';
import './card.css';
import ReadMoreButton from '../ReadMorebtn/ReadMoreButton';
import LazyLoad from 'react-lazyload';

export default function Card({ isButton, image, title, description, Link, cardHeight, alt }) {
    return (
        <div className={`${isButton ? "card" : "card2"}`}>
            <div className='card-img-div'>
                <LazyLoad height={210} offset={100}>
                    <img className="card-image" style={{ maxWidth: '100%', maxHeight: '210px' }} src={image} alt={alt} />
                </LazyLoad>
            </div>
            <h2 className="card-title">{title}</h2>
            <p className="card-description" style={{ minHeight: cardHeight }}>{description}</p>
            {isButton && <div className='card-button'>
                <ReadMoreButton link={Link} title='Details' />
            </div>}
        </div>
    );
}