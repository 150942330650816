import React, { Suspense, lazy, useCallback, useEffect, useState } from "react";
import './contectusform.css'
import { toast } from 'react-hot-toast';

import { MdOutlineMailOutline } from "react-icons/md";
import ReactPhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { PostInqury } from "../../Api/Api";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import GoogleRecaptcha from "../GoogleRecaptcha/GoogleRecaptcha";

const renderLoader = () => <span className='loader'></span>;
let success1 = {
  FirstName: false,
  LastName: false,
  EmailAddress: false,
  PhoneNo: false,
  Message: false,
  ReCaptcha: false
}

const ContactForm = () => {

  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [Success, setSuccess] = useState(success1);
  const [formData, setFormData] = useState({
    ID: '0',
    FirstName: "",
    LastName: "",
    EmailAddress: "",
    CountryCode: '+91',
    PhoneNo: "",
    Message: "",
    IPAddress: "192.168.1.1",
   
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    validation(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const validation = async (name, value) => {
    if (name == "FirstName" && value == "") {
      setSuccess({
        ...Success,
        FirstName: true,
      });
    } else if (name == "FirstName" && value != "") {
      setSuccess({
        ...Success,
        FirstName: false,
      });
    }
    else if (name == "LastName" && value == "") {
      setSuccess({
        ...Success,
        LastName: true,
      });
    } else if (name == "LastName" && value !== "") {
      setSuccess({
        ...Success,
        LastName: false,
      });
    } else if (name == "EmailAddress" && value == "") {
      setSuccess({
        ...Success,
        EmailAddress: true,
      });
    } else if (name == "EmailAddress" && value !== "") {
      setSuccess({
        ...Success,
        EmailAddress: false,
      });
    } else if (formData.PhoneNo.length == "") {
      setSuccess({
        ...Success,
        PhoneNo: true,
      });
    } else if (formData.PhoneNo.length > 13) {
      setSuccess({
        ...Success,
        PhoneNo: true,
      });
    } else if (formData.PhoneNo.length < 13) {
      setSuccess({
        ...Success,
        PhoneNo: true,
      });
    } else if (name == "Message" && value == "") {

      setSuccess({
        ...Success,
        Message: true,
        PhoneNo: false
      });
    } else if (name == "Message" && value !== "") {
      setSuccess({
        ...Success,
        Message: false,
        PhoneNo: false,
        ReCaptcha: true,
      });
    }  else {
      if (formData.FirstName == "" || formData.LastName == "" || formData.EmailAddress == "" || formData.PhoneNo == "" || formData.Message == "") {
        setSuccess({ ...Success, FirstName: true, LastName: true, EmailAddress: true, PhoneNo: true, Message: true })
      } else {
        const loading = toast.loading("In progress...");
        try {
          const { data } = await PostInqury(formData);
          toast.dismiss(loading);
          toast.success(data.message, {
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
              maxWidth: "700px"
            },
            duration: 10000,
          });
          setSuccess({ ...Success, ReCaptcha: false })
          setFormData({ ...formData, FirstName: "", LastName: '', EmailAddress: '', PhoneNo: '', Message: ''})
        } catch (err) {
          toast.dismiss(loading);
          toast.error(err);
        };
      }
    }
  }



  const handleSubmit = async (e) => {
    e.preventDefault();
    validation("", "");

  };
  

  return (
    <div className="contect-main">
      {/* <h1>Contact Us</h1> */}
      <form onSubmit={handleSubmit}>
        <div className=" flex-space-between">
          <div className="form-group">
            <label htmlFor="firstname">First Name</label>
            <input
              id="firstname"
              type="text"
              name="FirstName"
              placeholder="First Name"
              value={formData.FirstName}
              onChange={handleChange}
            />
            {Success.FirstName && <p>Please Enter First Name</p>}
          </div>
          <div className="form-group">
            <label htmlFor="lastname">Last Name</label>
            <input
              id="lastname"
              type="text"
              name="LastName"
              placeholder="Last Name"
              value={formData.LastName}
              onChange={handleChange}
            />
            {Success.LastName && <p>Please Enter Last Name</p>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              name="EmailAddress"
              placeholder="Email"
              value={formData.EmailAddress}
              onChange={handleChange}
            />
            {Success.EmailAddress && <p>Please Enter Email</p>}
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <ReactPhoneInput
              id="phone"
              international
              countryCallingCodeEditable={false}
              withCountryCallingCode
              defaultCountry="IN"
              name="PhoneNo"
              value={formData.PhoneNo}
              onChange={(value) => setFormData({ ...formData, PhoneNo: value })}
            />
            {Success.PhoneNo && <p>Please Enter Valid Phone No</p>}
          </div>
        </div>
        <div className="form-group-msg">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="Message"
            value={formData.Message}
            onChange={handleChange}
          />
          {Success.Message && <p>Please Enter Message</p>}
        </div>
     {Success.ReCaptcha && <GoogleRecaptcha show={true} formData={formData} setFormData={setFormData}/>}

        {/* {Success.ReCaptcha && <p className='recaptcha'>Please Verify ReCaptcha</p>} */}
        <div className='submit-btn'>
          <button type="submit" id="contactUsButton" > <span> Let's Connect<MdOutlineMailOutline className='submit-icon' /></span></button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;