import React, { useEffect, useRef, useState } from 'react'
import { RiCloseCircleFill } from "react-icons/ri";
import { PiBagThin } from 'react-icons/pi'
import { IoMdInformationCircleOutline } from "react-icons/io";
import AnimationButton from '../AnimationButton/AnimationButton'
import ApplyForm from '../ApplyForm/ApplyForm'
import PageSectionSecondary from '../Page-Section-Secondary/PageSectionSecondary'
import './cardopening.css'
import Jobdescription from '../JobDescription/Jobdescription';


export default function CardOpening({ careersData, JobDescription, Qualification }) {

    const [Open, setOpen] = useState(false);
    const [Description, setDescription] = useState(false);
    const [positionName, setpositionName] = useState("");
    const [jobdec, setjobdec] = useState([]);


    const handlemainblock = (e) => {
        if (e.target.id == "dialog-main-block") {
            setOpen(false)
        }
    }
    const handledecmainblock = (e) => {
        if (e.target.id == "dicmain-block") {
            setDescription(false)
        }
    }
    // const handleDescriptionbox = (event) => {
    //     event.preventDefault();

    //     if (event.target === event.currentTarget) {
    //         setDescription(false)
    //     }
    // }
    // const handleopenbox = (event) => {
    //     event.preventDefault();

    //     if (event.target === event.currentTarget) {
    //         setOpen(false)
    //     }
    // }
    // const handleinneropenbox = (event) => {

    // }
    return (
        <>
            <div className='flex card-opening-main'>
                {careersData.map((item, ind) =>
                    <div className='card-opening' key={ind}>
                        <span className='card-opening-badge'>{item.totalPosition}</span>
                        <div className='card-opening-header'>
                            <p>{item.header}</p>
                        </div>
                        <div>
                            <div className='card-opening-content'>
                                <p> <PiBagThin className='card-opening-icon' /><span>Exp :&nbsp;</span> {item.exp}</p>
                            </div>
                            {/* <div className='card-opening-content'>
                                <p> <MdOutlineLocationOn className='card-opening-icon' /><span>Location: </span> {item.location}</p>
                            </div> */}
                        </div>
                        <div className='flex job-dcription'>
                            <IoMdInformationCircleOutline className='job-dcription-icon' />
                            <p>{item.description}</p>
                        </div>
                        <hr className='card-opening-hr' />
                        <div className='flex-space-between card-opening-button-content '>
                            <div className='card-opening-button' onClick={(e) => setpositionName(item.header)}>
                                <AnimationButton link={item.link} title={item.buttontitle} setOpen={setOpen} />
                            </div>
                            <div className='second-button'>
                                <div onClick={(e) => (setpositionName(item.header), setDescription(true), setjobdec(item.JobDescription))} ><span>Job Description</span></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {Description && <div className="dialog-box" id='dicmain-block' onClick={(e) => handledecmainblock(e)} >
                <div className="dialog-inner"  >
                    <div className='flex-space-between'>
                        <div className='dialog-heading'>
                            <PageSectionSecondary isSize={true} title={`Position Details for ${positionName} `} />
                        </div>
                        <div className='dialog-icon'>
                            <RiCloseCircleFill onClick={() => setDescription(false)} className='icon' />
                        </div>
                    </div>
                    <Jobdescription description={JobDescription} Qualification={Qualification} JobDescription={jobdec} />
                </div>
            </div>}

            {Open && <div className="dialog-box" id='dialog-main-block' onClick={(e) => handlemainblock(e)} >
                <div className="dialog-inner"  >
                    <div >
                        <div className='flex-space-between'  >
                            <div className='dialog-heading'>
                                <PageSectionSecondary isSize={true} title={`Submit below details to apply for the ${positionName} `} />
                            </div>
                            <div className='dialog-icon'>
                                <RiCloseCircleFill onClick={() => setOpen(false)} className='icon' />
                            </div>
                            <ApplyForm positionName={positionName} setOpen={setOpen} />
                        </div>
                    </div>
                </div>
            </div>}

        </>
    )
}
