import React from 'react'
import './faqs.css'
import { Link } from 'react-router-dom'
import './faqs.css'

export default function FAQs({ TestimonialsData }) {
    return (
        <div>
            <div className="acc-kontainer">
                {TestimonialsData.map((item, ind) => {
                    return (
                        <div key={ind}>
                            <input type="radio" name="acc" id={`ass${ind + 1}`} />
                            <label for={`ass${ind + 1}`}>{item.title}</label>
                            <div className="acc-body">
                                {item.description}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
