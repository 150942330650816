import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../camponets/Banner/Banner';
import ServicesCard from '../../../camponets/ServicesCard/ServicesCard';



export default function IoTService() {
    const industrialSubServices = [{ name: 'Process Automation' }, { name: 'Predictive Maintenance' }, { name: 'Supply Chain Optimization' }, { name: 'Quality Control' }]
    const homeAutomationSubServices = [{ name: 'Smart Lighting and Climate Control' }, { name: 'Security and Surveillance' }, { name: 'Energy Management' }, { name: 'Entertainment Systems' }]
    const smartHealthcareSubServices = [{ name: 'Remote Patient Monitoring' }, { name: 'Medication Adherence' }, { name: 'Healthcare Asset Tracking' }, { name: 'Elderly Care Solutions' }]
    const connectedDevicesSubServices = [{ name: 'Wearable Devices' }, { name: 'Smart Gadgets' }, { name: 'Industrial Sensors' }, { name: 'Agriculture IoT' }]

    const AllServices = [{
        id: "industrial-automation",
        image: "https://cdn.technolee.com/image/services/iotimages/industrialautomation.webp",
        title: "Industrial Automation",
        description: "Our Industrial Automation service harnesses the power of the Internet of Things (IoT) to revolutionize manufacturing and production processes. We offer a comprehensive suite of IoT solutions that include process automation, predictive maintenance, supply chain optimization, and quality control. Process automation streamlines manufacturing operations, enhancing efficiency and productivity. Predictive maintenance uses real-time data and analytics to prevent equipment failures, ensuring continuous operations. Supply chain optimization transforms inventory and logistics management, reducing costs and improving supply chain efficiency. Our quality control solutions guarantee product consistency and compliance, setting new standards in industrial automation. We offer:",
        iconData: [],
        subServicesData: industrialSubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "industrialautomation"
    }, {
        id: "home-automation",
        image: "https://cdn.technolee.com/image/services/iotimages/homeautomation.webp",
        title: "Home Automation",
        description: "Our Home Automation service brings the future to your doorstep, offering a range of IoT solutions to enhance daily living. With smart lighting and climate control, you can personalize your home environment for comfort and energy savings. Our security and surveillance systems provide remote monitoring and protection for your property. Energy management optimizes electricity consumption and reduces costs, while our entertainment systems create immersive home entertainment experiences. Whether it's a smart thermostat, security cameras, or voice-controlled lighting, our home automation solutions offer convenience, security, and energy efficiency. We provide:",
        iconData: [],
        subServicesData: homeAutomationSubServices,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "homeautomation"

    }, {
        id: "smart-healthcare",
        image: "https://cdn.technolee.com/image/services/iotimages/smarthealthcare.webp",
        title: "Smart Healthcare",
        description: "Our Smart Healthcare service leverages the power of IoT to transform patient care and healthcare processes. We provide remote patient monitoring solutions, enabling healthcare providers to keep track of patient health remotely. Medication adherence solutions ensure that patients take their medications as prescribed, improving treatment outcomes. Healthcare asset tracking solutions monitor the location and status of medical equipment, reducing losses and improving efficiency. Our elderly care solutions enhance the well-being and safety of seniors, enabling them to age in place while staying connected to caregivers and healthcare providers. It includes:",
        iconData: [],
        subServicesData: smartHealthcareSubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "smarthealthcare"

    }, {
        id: "connected-devices",
        image: "https://cdn.technolee.com/image/services/iotimages/connecteddevices.webp",
        title: "Connected Devices",
        description: "Our Connected Devices service connects the world through a wide range of IoT technology. We offer wearable devices that track health and fitness, from smart watches to fitness trackers. Our smart gadgets encompass a variety of consumer-focused IoT products, from smart home devices to innovative gadgets. Industrial sensors provide data collection solutions across various industries, facilitating data-driven decision-making. In agriculture IoT, we enhance crop and livestock management with connected devices, optimizing yields and resource utilization. Our IoT solutions empower businesses and individuals with connectivity and innovation. We offer:",
        iconData: [],
        subServicesData: connectedDevicesSubServices,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "connecteddevices"

    }]

    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <title>: IoT Services - Industrial Automation, Home Automation, Smart Healthcare, Connected Devices</title>
                <meta name="description" content="Explore our IoT services, including industrial automation, home automation, smart healthcare, and connected devices. We empower businesses and individuals with innovative IoT solutions that enhance productivity and improve lives." />
                <meta name="keywords" content="IoT services, industrial automation, home automation, smart healthcare, connected devices, Technolee" />
            </Helmet>

            <Banner
                alt={'Iot Service'}
                image="https://cdn.technolee.com/image/services/iotimages/iotbanner.webp"
                title="Shaping the Internet of Things"
                description="Experience the future of IoT with our comprehensive services. Discover our expertise in industrial automation, home automation, smart healthcare, and connected devices, driving innovation and connectivity"
            />
            <div className='page-content container'>
                <div>
                    {AllServices.map((item, ind) =>
                        <ServicesCard
                            key={ind}
                            id={item.id}
                            image={item.image}
                            title={item.title}
                            description={item.description}
                            iconData={item.iconData}
                            subServicesData={item.subServicesData}
                            isLeft={item.isLeft}
                            alt={item.alt}
                            isFullWidth={item.isFullWidth}
                            isReadMore={item.isReadMore}
                        />
                    )}
                </div>
            </div>
        </>
    )
}