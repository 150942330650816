import React from 'react'
import TechnologiesDetails from '../../../../camponets/TechnologiesDetails/TechnologiesDetails';
import { Helmet } from 'react-helmet';

const TechName = "AWS"

const BannerData = [
    { alt: "awsbanner", BannerimgUrl: 'https://cdn.technolee.com/image/technologies/technobennar/awsbanner.webp', htmlString: 'Your Cloud Experience with <span class="text-highlight">AWS</span>', BennerDecription: "Dive into the world of cloud innovation with our AWS development services. From scalable infrastructure to cutting-edge solutions, we empower your digital journey." }
]

const AboutCardData = [
    { alt: "awstechnology", imgUrl: 'https://cdn.technolee.com/image/technologies/technoexceptional/awstechnology.webp', AboutTitle: "Your AWS Development Partner of Choice", AboutDecription: "Discover why businesses trust our AWS development services for creating robust, scalable, and secure cloud-based solutions." }
]

const TechDetailsCardData = [
    { title: 'Certified AWS Experts', description: "Our certified AWS experts bring in-depth knowledge and expertise to design, deploy, and optimize solutions on the Amazon Web Services platform." },
    { title: 'Strategic Cloud Consulting', description: "Benefit from strategic cloud consulting services, where we align AWS solutions with your business goals, ensuring maximum efficiency and ROI." },
    { title: 'Scalable and Flexible Solutions', description: "We specialize in developing scalable and flexible solutions on AWS, providing the foundation for your applications to grow seamlessly with your business." },
    { title: 'Security and Compliance Focus', description: "Security is at the forefront of our AWS development. We ensure that your cloud solutions adhere to the highest security standards and compliance requirements." },
    { title: 'Cost Optimization Strategies', description: "Implement cost optimization strategies to ensure that your AWS resources are utilized efficiently, maximizing the value of your investment." },
]

const ExpertiseCardData = [
    { title: 'AWS Cloud Application Development ', description: "We specialize in developing cloud-native applications on AWS, ensuring agility, scalability, and optimal performance." },
    { title: 'Serverless Architecture with AWS Lambda', description: "Implement serverless architecture using AWS Lambda, enabling you to build and run applications without managing infrastructure." },
    { title: 'Microservices Development on AWS', description: "Leverage microservices architecture on AWS, creating modular and scalable applications that can be developed, deployed, and scaled independently." },
    { title: 'AWS DevOps Integration', description: "Integrate AWS into your DevOps practices for streamlined development, continuous integration, and continuous delivery, ensuring rapid and reliable software releases." },
    { title: 'Data Storage and Analytics with AWS', description: "Utilize AWS for efficient data storage and analytics, leveraging services like Amazon S3, DynamoDB, and Amazon Redshift for optimal insights." },
]

const TechFancyCardData = [
    { title: 'AWS Cloud Application Development', description: "Develop cloud-native applications on AWS, harnessing the power of scalable infrastructure and cutting-edge cloud services." },
    { title: 'Serverless Architecture with AWS Lambda', description: "Implement serverless architecture using AWS Lambda, allowing you to focus on building code without managing servers." },
    { title: 'Microservices Development on AWS', description: "Embrace microservices development on AWS for modular, scalable, and independently deployable applications." },
    { title: 'AWS DevOps Integration', description: "Integrate AWS into your DevOps practices for automated testing, continuous integration, and continuous delivery, ensuring efficient and reliable software delivery." },
    { title: 'Data Storage and Analytics with AWS', description: "Leverage AWS services like Amazon S3, DynamoDB, and Amazon Redshift for efficient data storage, retrieval, and analytics." },
    { title: 'AWS Security and Compliance', description: "Prioritize security and compliance in your AWS solutions, ensuring that your applications adhere to industry standards and regulations." },
    { title: 'Cost Optimization Strategies', description: "Implement cost optimization strategies for your AWS resources, ensuring that your investment aligns with your business goals." },
    { title: 'AWS Maintenance and Support', description: "Beyond development, we offer ongoing maintenance and support services, ensuring that your AWS applications are secure, up-to-date, and performing optimally." },
]


const KeyBenefitsCardData = [
    { alt: "scalability", title: 'Scalability and Flexibility', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/scalability.webp', description: "AWS provides on-demand scalability, allowing your applications to grow seamlessly with the changing needs of your business." },
    { alt: "efficienttest", title: 'Cost-Effective Solutions', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/efficienttest.webp', description: "Optimize costs with AWS, paying only for the resources you consume, and benefit from a flexible pricing model that suits your budget." },
    { alt: "innovation", title: 'Global Reach and Availability', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/innovation.webp', description: "Leverage AWS's global infrastructure to ensure high availability and low-latency experiences for your users across the globe." },
    { alt: "security", title: 'Security and Compliance', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/security.webp', description: "AWS prioritizes security and compliance, providing a secure foundation for your applications to meet industry standards and regulations." },
    { alt: "ecosystem", title: 'Innovation with Cutting-Edge Services', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/ecosystem.webp', description: "Stay at the forefront of innovation with AWS's ever-expanding suite of services, enabling you to incorporate the latest technologies into your applications." },
    { alt: "reusability", title: 'Reliability and Redundancy', imgUrl: 'https://cdn.technolee.com/image/technologies/technoexpetise/reusability.webp', description: "Ensure reliability and redundancy with AWS, where your applications benefit from a robust and resilient infrastructure." },
]

const TestimonialsData = [
    { title: 'Why Choose AWS for Cloud Development?', description: "AWS offers scalability, flexibility, cost-effectiveness, global reach, security, and a wide range of cutting-edge services, making it an ideal choice for cloud development." },
    { title: 'How Does AWS Ensure Security and Compliance?', description: "AWS prioritizes security and compliance through robust infrastructure, encryption, access controls, and adherence to industry standards and regulations." },
    { title: 'What Are the Key AWS Services for Cloud Application Development?', description: "Key AWS services for cloud application development include AWS Lambda for serverless architecture, Amazon S3 for storage, DynamoDB for NoSQL databases, and more." },
    { title: 'Can Existing Applications Be Migrated to AWS?', description: "Yes, existing applications can be migrated to AWS using various migration strategies, ensuring a smooth transition and optimization for the cloud environment." },
    { title: 'How Does AWS Ensure Data Storage and Analytics?', description: "AWS provides services like Amazon S3 for storage, DynamoDB for NoSQL databases, and Amazon Redshift for analytics, ensuring efficient data storage and retrieval." },
    { title: 'What Measures Are Taken for AWS Cost Optimization?', description: "AWS cost optimization includes using reserved instances, right-sizing resources, leveraging spot instances, and monitoring resource usage for efficient budget management." },
    { title: 'Is AWS Suitable for Small and Medium-sized Businesses (SMBs)?', description: "Yes, AWS is suitable for SMBs, offering scalable solutions that can be tailored to the specific needs and budget constraints of small and medium-sized businesses." },
    { title: 'How Does AWS Support Global Application Deployment?', description: "AWS's global infrastructure ensures high availability and low-latency experiences for users worldwide, supporting global application deployment." },
]

export default function Aws() {
    return (
        <div>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <title>Unleash the Power of AWS for Your Digital Solutions</title>
                <meta name="description" content="Explore our AWS development services to harness the scalability, flexibility, and innovation offered by Amazon Web Services. Elevate your applications with our AWS expertise." />
                <meta name="keywords" content="AWS Development, Amazon Web Services, AWS Solutions, AWS Cloud Application Development, Serverless Architecture, Microservices Development, AWS DevOps Integration, Data Storage and Analytics with AWS, AWS Security and Compliance, Cost Optimization Strategies, AWS Maintenance and Support, Advantages of AWS, AWS Development Benefits, AWS FAQs" />
            </Helmet>

            <TechnologiesDetails TechName={TechName} AboutCardData={AboutCardData} BannerData={BannerData} TechDetailsCardData={TechDetailsCardData} TechFancyCardData={TechFancyCardData} ExpertiseCardData={ExpertiseCardData} KeyBenefitsCardData={KeyBenefitsCardData} TestimonialsData={TestimonialsData} />
        </div>
    )
}
