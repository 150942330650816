import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../camponets/Banner/Banner';
import CardOpening from '../../camponets/CardOpening/CardOpening';
import PageSectionMain from '../../camponets/page-Section-Main/PageSectionMain';
import PageSectionSecondary from '../../camponets/Page-Section-Secondary/PageSectionSecondary';
import HiringCard from '../../camponets/HiringCard/HiringCard';

export default function Careers() {
  const htmlString = 'Join Our <span class="text-highlight">Team</span>';
  const careersData = [
    {
      JobDescription: [{
        Responsibilities: [
          'Identify and pursue new business opportunities to achieve growth targets.',
          'Build and maintain strong relationships with clients and key stakeholders.',
          'Develop and execute business strategies to drive revenue and market share.',
          'Collaborate with the marketing team to create effective promotional materials.',
          'Conduct market research to identify trends, opportunities, and potential clients.',
          'Negotiate and close business deals, ensuring mutually beneficial agreements.',
          'Provide leadership and direction to the business development team.'
        ], Requirements: [
          'Proven experience as a Business Development Manager.',
          'Strong understanding of the software and technology industry.',
          'Excellent communication and negotiation skills',
          'Demonstrated ability to lead and motivate a business development team.',
          'Strategic thinking and problem-solving capabilities.',
          'Track record of achieving and exceeding sales targets.',
          'Bachelor\'s degree in Business, Marketing, or a related field.',
        ]
      }], totalPosition: '2', header: "Business Development Manager", exp: "1-3 Years", location: "Ahmedabad", link: "", buttontitle: 'Apply Now', description: 'We are in search of a talented Business Development Manager to help us expand our client base. If you are a strategic thinker with a proven track record of driving growth, this role offers you an exciting opportunity.'
    },
    {
      JobDescription: [{
        Responsibilities: [
          "Attracting new clients by innovating and overseeing the sales process for the business",
          'Identifying and researching opportunities that come up in new and existing markets',
          'Identify and evaluate potential sales leads through networking and online research.',
          'Maintaining relationships with existing clients.',
          'Connecting with prospective clients and taking them onboard.',
          'To reach a large number of targeted customers or clients.',
          'Lead generation knowledge using LinkedIn and Cold emails.',
          'Lead generation from bidding portals mainly from Upwork and freelancer.',
          'Writing business proposals',
          'Preparing and delivering pitches and presentations to potential new clients',
          'Creating positive, long-lasting relationships with current and potential clients.',
          "Stay updated on industry trends and competitors' activities.",
          'Maintaining client activities in CRM',
          'Running outbound campaigns (phone calls, emails, etc.) to create sales opportunities',
        ], Requirements: [
          'Bachelor’s degree in business management, marketing, sales or a related field.',
          '3-4 years of experience in sales or business development roles.',
          'Excellent writing and communication skills',
          'Ability to build and maintain relationships with clients.',
          'Understand B2B & B2C market.',
          'Ability to work independently and as part of a team.',
        ]
      }],
      totalPosition: '2', header: "Business Development Executive", exp: "2 + Years", location: "Ahmedabad", link: "", buttontitle: 'Apply Now', description: 'We are looking for experienced Business Development Executives (The Hunter - Lead Generator) to join our team! As a Business Development executive, you will be responsible for strategic and operational business development.'
    },
    {
      JobDescription: [{
        Responsibilities: [
          'Design, develop, and maintain .NET-based applications.',
          'Collaborate with stakeholders to gather and analyze software requirements.',
          'Implement best practices for coding, testing, and deployment.',
          'Troubleshoot, debug, and optimize applications for performance and scalability.',
          'Stay updated on emerging technologies and contribute to continuous improvement.',
        ], Requirements: [
          'Proven experience as a .NET Developer.',
          'Strong proficiency in C# and the .NET framework.',
          'Experience with database systems, such as SQL Server.',
          'Familiarity with front-end technologies (HTML, CSS, JavaScript).',
        ]
      }], totalPosition: '4', header: "ASP.NET/C# Developer", exp: "2-4 Years", location: "Ahmedabad", link: "", buttontitle: 'Apply Now', description: 'Join our team as an ASP.NET/C# Developer and contribute to the creation of robust, scalable web applications. If you are proficient in ASP.NET and C# and have a passion for software development, we want to meet you.'
    },
    {
      JobDescription: [{
        Responsibilities: [
          'Lead and manage the entire project lifecycle.',
          'Develop detailed project plans, including timelines, milestones, and resource allocation.',
          'Monitor and control project execution, ensuring adherence to timelines and budgets.',
          'Collaborate with cross-functional teams to define project scope and requirements.',
          'Identify and mitigate project risks, ensuring successful project delivery.',
          'Maintain effective communication with clients and internal stakeholders.',
          'Conduct regular project reviews and provide status updates.',
        ], Requirements: [
          'Proven experience as a Project Manager in the software development industry.',
          'Strong project management skills with a focus on planning and execution.',
          'Excellent leadership and team management abilities.',
          'Solid understanding of software development processes and methodologies.',
          'Exceptional communication and interpersonal skills.',
          'Project Management Professional (PMP) certification is a plus.',

        ]
      }], totalPosition: '1', header: "Project Manager", exp: "4-6 Years", location: "Ahmedabad", link: "", buttontitle: 'Apply Now', description: "We're looking for an experienced Project Manager to oversee our software and web development projects. If you excel at project planning, execution, and team leadership, we'd like to discuss how you can lead our projects to success."
    },
    {
      JobDescription: [{
        Responsibilities: [
          'Develop and maintain mobile applications using React Native.',
          'Collaborate with UI/UX designers to implement elegant and responsive user interfaces.',
          'Integrate third-party APIs and libraries to enhance application functionality.',
          'Troubleshoot, debug, and optimize application performance for a seamless user experience.',
          'Stay updated on emerging trends and technologies in mobile development.',
        ], Requirements: [
          'Proven experience as a React Native Developer.',
          'Strong proficiency in JavaScript and React.',
          'Familiarity with native build tools, like XCode, Android Studio.',
          'Solid understanding of RESTful APIs and mobile security principles.',
          'Experience with version control systems, such as Git.',
          'Excellent problem-solving and communication skills.'
        ]
      }], totalPosition: '2', header: "React Native Developer", exp: "2-4 Years", location: "Ahmedabad", link: "", buttontitle: 'Apply Now', description: 'We are looking for a React Native Developer to build high-quality mobile applications for our clients. If you have a strong background in mobile app development and React Native, this role could be your next career move.'
    },
    {
      JobDescription: [{
        Responsibilities: [
          "Develop user interfaces with ReactJS for web applications.",
          'Collaborate with UI/UX designers to implement design mockups.',
          'Optimize components for maximum performance across a vast array of web-capable devices and browsers.',
          'Work closely with back-end developers to integrate front-end components.',
          'Participate in code reviews to maintain code quality standards.'
        ], Requirements: [
          'Proven experience as a ReactJS Developer.',
          'Strong proficiency in JavaScript, HTML, CSS, and React.',
          'Familiarity with RESTful APIs and modern front-end build pipelines.',
          'Experience with common front-end development tools, such as Babel, Webpack, etc.',
          'Good understanding of web accessibility standards.',
          'Excellent problem-solving and collaboration skills.',
        ]
      }],
      totalPosition: '2', header: "ReactJS Developer", exp: "2-4 Years", location: "Ahmedabad", link: "", buttontitle: 'Apply Now', description: 'We are seeking a skilled ReactJS Developer to join our dynamic team. If you have a passion for crafting exceptional user experiences and are proficient in React, we want to hear from you. Your expertise will drive the development of cutting-edge web applications.'
    },
  ]




  const HiringCardData = [
    { isLine: true, imgUrl: "https://cdn.technolee.com/image/career/resumescreening.webp", title: 'Resume Screening', alt: 'Resume Screening' },
    { isLine: true, imgUrl: "https://cdn.technolee.com/image/career/initialinterview.webp", title: 'Initial Interview', alt: 'Initial Interview' },
    { isLine: true, imgUrl: "https://cdn.technolee.com/image/career/technicalassessment.webp", title: 'Technical Assessment', alt: 'Technical Assessment' },
    { isLine: true, imgUrl: "https://cdn.technolee.com/image/career/referencechecks.webp", title: 'Reference Checks', alt: 'Reference Checks' },
    { isLine: true, imgUrl: "https://cdn.technolee.com/image/career/hrinterview.webp", title: 'HR Interview', alt: 'HR Interview' },
    { isLine: true, imgUrl: "https://cdn.technolee.com/image/career/joboffer.webp", title: 'Job Offer', alt: 'Job Offer' },
    { isLine: false, imgUrl: "https://cdn.technolee.com/image/career/onboarding.webp", title: 'Onboarding', alt: 'Onboarding' },
  ]

  const JobDescription = [
    { description: "Create a strong pipeline of new sales opportunities. Develop and manage the sales funnel by proactively identifying, tracking, and facilitating successful closures." },
    { description: "Communicate and present effectively to all the client levels." },
    { description: "Monitor and report on all sales activities." },
    { description: "Work with technical staff and product specialists where required to address customer requirements." },
    { description: "Ongoing client relationship management to ensure the potential value of the customer is maximized and customer delight is maintained on a long-term basis." },
    { description: "Experience in bidding on platforms like Upwork, Guru, Freelancer, and PPH, and must have a strong profile on one or more mentioned platforms." },
    { description: "Proactively hunt for new business opportunities outside the existing or traditional client base through market and account research, sales events, networking, vendor events, and other programs." },
    { description: "Prospect potential customers using various direct methods such as video calling and indirect methods such as networking." },
  ]

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Join Technolee - Explore Exciting Job Opportunities</title>
        <meta name="description" content="Discover career prospects at Technolee. We're hiring for various roles, including ReactJS and React Native developers, ASP.NET/C# developers, Business Development Managers, and Project Managers." />
        <meta name="keywords" content="job openings, career opportunities, ReactJS developer, React Native developer, ASP.NET developer, C# developer, Business Development Manager, Project Manager, Technolee" />
      </Helmet>

      <Banner
        alt={'Career'}
        image="https://cdn.technolee.com/image/career/careerbanner.webp"
        title={htmlString}
        description="Explore our current job openings for ReactJS, React Native, ASP.NET/C#, Business Development Manager, and Project Manager Positions. Take the next step in your career with us."
      />
      <div className='page-content container'>

        <div>
          <PageSectionMain title='OPENINGS' />
          <PageSectionSecondary title='Current Openings' />
          <CardOpening careersData={careersData} JobDescription={JobDescription} Qualification="Any Graduate" />
        </div>
        <div>
          <PageSectionMain title='WORKING PROCESS' />
          <PageSectionSecondary title='Our Tech Hiring Process' />
          <HiringCard data={HiringCardData} />
        </div>
      </div>
    </>
  )
}
