import React, { useEffect } from 'react';
import './aboutcard.css';
import ReadMoreButton from '../ReadMorebtn/ReadMoreButton';
import ContactForm from '../ContectUsForm/ContactForm';

export default function AboutCard({
  isLeft,
  alt,
  image,
  title,
  mainTitle,
  description,
  link,
  subTitle,
  description2
}) {
  useEffect(() => {
    // Preload critical images
    const preloadImage = new Image();
    preloadImage.src = image;
  }, [image]);

  return (
    <div className='flex-space-between aboutcard-main'>
      {isLeft && (
        <div className='aboutcard-img'>
          <img
            className='aboutcard-image'
            src={image}
            alt={alt}
            loading="eager"  // Ensure this image is loaded eagerly
          />
        </div>
      )}
      <div className='aboutcard-content' style={{ width: '53%' }}>
        <h2>{title} <hr /></h2>
        <h1>{mainTitle}</h1>
        <p className='aboutcard-subtitle'>{subTitle}</p>
        <p className='aboutcard-artical'>{description}</p>
        <p className='aboutcard-artical'>{description2}</p>
        {link !== "" && (
          <div className='aboutcard-button'>
            <ReadMoreButton link={link} title='Discover More' />
          </div>
        )}
      </div>
      {!isLeft && (
        <div className='aboutcard-img' style={{ width: '45%' }}>
          <ContactForm />
        </div>
      )}
    </div>
  );
}
