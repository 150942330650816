
import React from 'react'
import './hiringcard.css'

export default function HiringCard({ data }) {
    return (
        <div className='flex-space-between hiringcard-main'>
            {data.map((item, ind) => {
                return <>
                    <div key={ind} className='hiringcard-content' >
                        <div className='hiringcard-icon' >
                            <img className='hiringcard-image' src={item.imgUrl} alt={item.alt} />
                        </div>
                        <h2>{item.title}</h2>
                    </div>
                    {item.isLine && <div className={`hiringcard-line${ind + 1}`} key={ind}></div>}
                    {/* {!item.isLine && <div className='hiringcard-right-corner'></div>} */}
                    {/* <div className='hiringcard-left-corner'></div> */}
                </>
            })}
        </div>
    )
}
