import React, { useState } from 'react';
import './jobdescription.css'

export default function Jobdescription({ description, Qualification, JobDescription }) {
    const [responsibilities, setresponsibilities] = useState(JobDescription[0].Responsibilities)
    const [requirements, setrequirements] = useState(JobDescription[0].Requirements)
    

    return (
        <div className='job-description-main'>
            <h3>Roles & Responsibilities</h3>
            <hr />
            <ul className='job-description-list'>
                {responsibilities.map((item, ind) => {
                    return <li key={ind}>{item}</li>
                })}
            </ul>
            <h3>Required Qualification</h3>
            <hr style={{ marginTop: "5px" }} />
            <ul className='job-description-list'>
            {requirements.map((item, ind) => {
                return <li key={ind}>{item}</li>
            })}
            </ul>
        </div>
    )
}
