


import React from 'react';
import Slider from 'react-slick';
import './TechnologiesTestimonials.css';
import Group6 from '../../../src/image/TechnologiesNew/Group 26.png';
import Group7 from '../../../src/image/TechnologiesNew/Group 27.png';
import Group8 from '../../../src/image/TechnologiesNew/Group 28.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const testimonials = [
  {
    image: Group6,
    title: "Fast Hiring Process",
    description: "Start Building Your Apps Faster, And Speed Up The Recruitment Process With Us."
  },
  {
    image: Group7,
    title: "Top 10% Talent",
    description: "At Technolee, Get Access To The Top 10% Of The Talent Pool Of Best React Native Developers."
  },
  {
    image: Group8,
    title: "Personalized Engagement Solutions",
    description: "As Per Your Needs, Get To Choose From Flexible Models, Including Full-Time, Part-Time, Or Contract Solutions."
  }
];

const CustomCardSlider = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    arrows: false, 
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  let sliderRef = React.useRef(null);

  return (
    <div className="slider-container">
      <Slider ref={slider => (sliderRef = slider)} {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="slider-item">
            <div className="slider-card">
              <div className="card-icon">
                <img width={80} src={testimonial.image} alt={testimonial.title} />
              </div>
              <h3>{testimonial.title}</h3>
              <p>{testimonial.description}</p>
            </div>
          </div>
        ))}
      </Slider>


      <div className="custom-navigation-buttons">
                <button onClick={() => sliderRef.slickPrev()} className="custom-swiper-prev">
                    &#10094;
                </button>
                <button  onClick={() => sliderRef.slickNext()} className="custom-swiper-next">
                    &#10095;
                </button>
            </div>
    </div>
  );
};

export default CustomCardSlider;
