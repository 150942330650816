import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../camponets/Banner/Banner';
import ServicesCard from '../../../camponets/ServicesCard/ServicesCard';


export default function UIUXDesign() {
    const requirementSubServices = [{ name: 'Stakeholder Interviews' }, { name: 'User Surveys and Research' }, { name: 'Scope Definition' }]
    const analysisSubServices = [{ name: 'Usability Analysis' }, { name: 'Competitor Analysis' }, { name: 'Content Analysis' }, { name: 'Performance Analysis' }]
    const wireframingSubServices = [{ name: 'Website Wireframing' }, { name: 'App Wireframing' }, { name: 'Interactive Prototypes' }]
    const customWebSubServices = [{ name: 'Website Redesign' }, { name: 'Bespoke Web Design' }, { name: 'Responsive Web Design' }]
    const uiuxDesignSubServices = [{ name: 'User Interface (UI) Design' }, { name: 'User Experience (UX) Design' }, { name: 'Interactive Prototyping' }]
    const mobileUISubServices = [{ name: 'iOS App UI Design' }, { name: 'Android App UI Design' }, { name: 'Cross-Platform App UI Design' }]

    const AllServices = [{
        id: "requirement-understanding-services",
        image: "https://cdn.technolee.com/image/services/uiuxdesign/requirementunderstanding.webp",
        title: "Requirement Understanding Services",
        description: "Wireframing is the blueprint of effective design, and our Wireframing service takes it to the next level. We meticulously create detailed visual representations of your website or app's layout. Our services cover website Wireframing, app Wireframing, and interactive prototypes. Whether you're planning a web revamp or a mobile app development, our wireframes ensure clarity in structure and navigation. Interactive prototypes allow for user testing and validation, guaranteeing that the final design meets user expectations. This service includes:",
        iconData: [],
        subServicesData: requirementSubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "requirement understanding"
    }, {
        id: "analysis",
        image: "https://cdn.technolee.com/image/services/uiuxdesign/analysis.webp",
        title: "Analysis",
        description: "Our Analysis service is the cornerstone of every successful design project. We begin with a comprehensive review of your existing digital presence, thoroughly assessing your website or application. Our team identifies areas for improvement, conducting usability analysis, competitor analysis, content analysis, and performance analysis. This process provides valuable insights that serve as the foundation for crafting design solutions tailored to your specific needs. By uncovering opportunities and challenges, we pave the way for enhancing user experiences and achieving your digital goals. Types of analysis services include:",
        iconData: [],
        subServicesData: analysisSubServices,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "analysis"

    }, {
        id: "wireframing",
        image: "https://cdn.technolee.com/image/services/uiuxdesign/wireframing.webp",
        title: "Wireframing",
        description: "Wireframing is the blueprint of effective design, and our Wireframing service takes it to the next level. We meticulously create detailed visual representations of your website or app's layout. Our services cover website Wireframing, app Wireframing, and interactive prototypes. Whether you're planning a web revamp or a mobile app development, our wireframes ensure clarity in structure and navigation. Interactive prototypes allow for user testing and validation, guaranteeing that the final design meets user expectations. Types of Wireframing services include:",
        iconData: [],
        subServicesData: wireframingSubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "wireframing"

    }, {
        id: "custom-web-design",
        image: "https://cdn.technolee.com/image/services/uiuxdesign/customwebdesign.webp",
        title: "Custom Web Design",
        description: "Our Custom Web Design service is dedicated to creating unique and captivating online experiences. We offer website redesign, bespoke web design, and responsive web design. We transform outdated websites into modern and engaging platforms, ensuring they match your brand identity seamlessly. Whether you need a visually stunning landing page or a complex e-commerce platform, we design responsive websites that deliver consistent and delightful user experiences across all devices and screen sizes. We offer:",
        iconData: [],
        subServicesData: customWebSubServices,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "customwebdesign"

    }, {
        id: "uiux-design",
        image: "https://cdn.technolee.com/image/services/uiuxdesign/uiux.webp",
        title: "UI/UX Design",
        description: "Our Mobile UI Design service is dedicated to crafting mobile app interfaces that are visually appealing, intuitive, and user-friendly. We provide iOS app UI design, Android app UI design, and cross-platform app UI design. We create mobile interfaces that match the unique characteristics and expectations of each platform. Whether you're targeting iOS users, Android users, or both, our designs optimize the mobile experience, ensuring that users can easily navigate and engage with your apps. We offer:",
        iconData: [],
        subServicesData: uiuxDesignSubServices,
        isLeft: false,
        isFullWidth: true,
        isReadMore: false,
        alt: "uiux"

    }, {
        id: "mobile-ui-design",
        image: "https://cdn.technolee.com/image/services/uiuxdesign/mobileui.webp",
        title: "Mobile UI Design",
        description: "Our Requirement Understanding service is the foundation for a successful design project. We begin by collaborating closely with you to gain a deep understanding of your project's requirements, objectives, and target audience. This service includes stakeholder interviews, user surveys and research, and scope definition. We interview key stakeholders to gather insights, collect user feedback, and conduct in-depth research to inform the design strategy. With scope definition, we outline key deliverables and project milestones, ensuring a clear and shared vision for the project. We provide:",
        iconData: [],
        subServicesData: mobileUISubServices,
        isLeft: true,
        isFullWidth: true,
        isReadMore: false,
        alt: "mobileui"

    }]
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />

                <title>Design Services - Analysis, Wireframing, Custom Web Design, UI/UX Design, Mobile UI Design</title>
                <meta name="description" content="Explore our design services, including analysis, Wireframing; custom web design, UI/UX design, and mobile UI design. We create captivating and user-focused design solutions to elevate your digital presence." />
                <meta name="keywords" content="design services, analysis, Wireframing, custom web design, UI/UX design, mobile UI design, Technolee" />
            </Helmet>

            <Banner
                alt={'uiuxbanner'}
                image="https://cdn.technolee.com/image/services/uiuxdesign/uiuxbanner.webp"
                title="Designing Exceptional Digital Experiences"
                description="Elevate your digital presence with our specialized design solutions. Discover our expertise in design analysis, Wireframing, custom web design, UI/UX design, and mobile UI design."
            />
            <div className='page-content container'>
                <div>
                    {AllServices.map((item, ind) =>
                        <ServicesCard
                            key={ind}
                            id={item.id}
                            image={item.image}
                            title={item.title}
                            description={item.description}
                            iconData={item.iconData}
                            subServicesData={item.subServicesData}
                            isLeft={item.isLeft}
                            alt={item.alt}
                            isFullWidth={item.isFullWidth}
                            isReadMore={item.isReadMore}
                        />
                    )}
                </div>
            </div>
        </>
    )
}