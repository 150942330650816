import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../camponets/Banner/Banner';
import PortfolioCard from '../../camponets/PortfolioCard/PortfolioCard';
import AdCard from '../../camponets/AdCard/AdCard';


export default function Portfolio() {
  const htmlString = 'Explore Our <span class="text-highlight">Creative</span> ';
  const contentData = [
    { alt: "Elite BDM", image: "https://cdn.technolee.com/image/portfolio/devices03.webp", keyword: ["#OfflineFirstBusiness", "#iPadAppMagic", "#EffortlessDealerManagement", "#OrderProcessingSimplified", "	#TechInnovation"], title: 'Elite BDM: Offline First Elegance on the iPad', description: 'Embark on a journey of seamless business operations with Elite BDM, an avant-garde iPad application designed for company representatives. This offline-first marvel empowers reps to effortlessly manage dealers, process orders, and access a wealth of information at their fingertips. Elite BDM redefines efficiency, ensuring every aspect of dealer management is a fluid and intuitive experience.' },
    { alt: "SIMply", image: "https://cdn.technolee.com/image/portfolio/devices04.webp", keyword: ["#CrossPlatformRecharge", "MobileTopUpRewards", "#DealerManagement", "#TechInnovations", "	#UserFriendlyApps"], title: 'SIMply: A Cross-Platform Recharge Revolution', description: "SIMply, a cross-platform masterpiece revolutionizes the way dealers interact with mobile recharge. This app not only facilitates quick top-ups but also introduces a rewards system, adding a layer of gamification. The user-friendly interface ensures effortless management of users, creating a seamless and rewarding experience for dealers." },
    { alt: "Rewards", image: "https://cdn.technolee.com/image/portfolio/devices04.webp", keyword: ["#CrossPlatformMerchandising", "#DistributorRewards", "#PointsRedemption", "#TransactionDetails", "	#TechSolutions"], title: 'Rewards: Cross-Platform Merchandising Marvel', description: "Rewards, a cross-platform marvel, caters to the distributors' world with an immersive merchandising experience. Distributors can redeem points, make purchases, and seamlessly navigate transaction details. This app is a testament to bridging the gap between convenience and functionality, creating a unified platform for rewarding experiences." },

    { alt: "Bausch & Lomb", image: "https://cdn.technolee.com/image/portfolio/devices11.webp", keyword: ["#SaaSMarketing", "#MedicalMarketing", "#SocialMediaPortal", "#DigitalHealth", "	#DoctorEngagement"], title: 'Bausch & Lomb: SaaS Symphony for Medical Marketing', description: "Bausch & Lomb's SaaS-based marketing portal is a symphony for doctors, orchestrating social media marketing, landing page generation, social automation, and email marketing. This platform redefines medical marketing, providing a comprehensive toolkit for doctors to enhance their digital presence." },
    { alt: "Group Controller", image: "https://cdn.technolee.com/image/portfolio/devices11.webp", keyword: ["#IoTManagement", "#AirConditionerControl", "#SmartHVAC", "#EnergyEfficiency", "	#GroupACManagement"], title: 'Group Controller: iOT Symphony for Air Conditioners', description: "Group Controller, an iOT-based web and app solution, revolutionizes the management of bulk and group air conditioners. Offering scheduling and real-time status updates, Group Controller ensures optimal control over air conditioning systems." },
    { alt: "Air", image: "https://cdn.technolee.com/image/portfolio/devices11.webp", keyword: ["#HVACDecisions", "#AirConditioningPortal", "#CoolingTech", "#EnergyEfficientHVAC", "	#OptimalAirComfort"], title: 'Air: Precision Portal for HVAC Decisions', description: "Air, a portal utilizing mathematical and algorithmic calculations, aids users in identifying the optimal air conditioning model and ducting system. By entering HVAC parameters for each room, users can make informed decisions, ensuring comfort and energy efficiency." },

    { alt: "EazyMeds", image: "https://cdn.technolee.com/image/portfolio/devices10.webp", keyword: ["#OnlinePharmacy", "#MedicineOrders", "#HealthTech", "#PrescriptionUpload", "	#WellnessJourney"], title: 'EazyMeds: Your Online Pharmacy Concierge', description: "EazyMeds, an online eCommerce application, revolutionizes the way users purchase medicines. Whether searching for specific medications or uploading prescriptions, EazyMeds ensures a hassle-free experience. Track orders effortlessly and embark on a journey of health and wellness with a platform designed for convenience." },
    { alt: "Chemisto", image: "https://cdn.technolee.com/image/portfolio/devices10.webp", keyword: ["#B2BMedicineProcurement", "#PharmacyTech", "#MedicalDistribution", "#SaaSInHealthcare", "	#MedicineSupplyChain"], title: 'Chemisto: B2B Brilliance in Medicine Procurement', description: "Chemisto, a B2B mobile app, elevates the medical procurement process for stores and distributors. This SaaS-based app streamlines the purchase of medicines, ensuring a seamless and efficient experience. Chemisto is not just an app; it's a catalyst for transforming B2B interactions in the pharmaceutical domain." },
    { alt: "MedKart", image: "https://cdn.technolee.com/image/portfolio/devices10.webp", keyword: ["#GenericMedicines", "#SavingsInHealthcare", "#MedicineComparison", "#EconomicalHealthcare", "	#PharmacyTech"], title: 'MedKart: Prescription-Based Savings Extravaganza', description: "MedKart, an online eCommerce app and web platform, introduces a unique approach to buying generic medicines. Users can compare compositions, upload prescriptions, and witness the potential savings through monthly and yearly comparisons. MedKart is not just a platform; it's a gateway to informed and economical healthcare choices." },

    { alt: "FuzzyBanter", image: "https://cdn.technolee.com/image/portfolio/devices04.webp", keyword: ["#OnlineDating", "#FuzzyFace", "#RealTimeChat", "#MatchingProfiles", "	#LoveConnections"], title: 'FuzzyBanter: Unveiling Romance with a Fuzzy Touch', description: "FuzzyBanter, an online dating sensation, adds an intriguing twist to connecting souls. With a fuzziness adjustable by the user, real-time chat features, and profile matching, FuzzyBanter takes online dating to a new dimension. Embrace the mystery, ignite conversations, and let love find its way." },
    { alt: "Erasafe", image: "https://cdn.technolee.com/image/portfolio/devices10.webp", keyword: ["#FinTechApp", "#RetailManagement", "#FinancialSolutions", "#DigitalWallets", "	#UserFriendlyFinance"], title: 'Erasafe: FinTech Prowess for Retail Management', description: "Erasafe, a FinTech application, empowers financing companies to manage retailers, wallets, redeem points, and efficiently handle user interactions. This comprehensive app ensures a smooth journey from lead generation to deal completion, creating a seamless experience for financial entities." },
    { alt: "Greenply", image: "https://cdn.technolee.com/image/portfolio/devices10.webp", keyword: ["#SaaSIndustrialMaintenance", "#WorkOrderSchedules", "#TaskTracking", "#MaintenanceRecords", "	#IndustrialTech"], title: 'Greenply: SaaS Harmony for Maintenance Records', description: "Greenply's SaaS application and web platform redefine maintenance records for industries. Offering computerized schedules, work orders, and real-time tracking, Greenply ensures industries have a comprehensive solution for their maintenance needs." },

    { alt: "Pranayam", image: "https://cdn.technolee.com/image/portfolio/devices04.webp", keyword: ["#YogaApp", "#MeditationJourney", "#HolisticWellness", "#Mindfulness", "	#InnerPeace"], title: 'Pranayam: Harmony with the Soul', description: "Pranayam, a yoga companion app, offers users a holistic experience of meditation, pranayama, timers, and more. This online and offline sanctuary promotes wellness, ensuring users can achieve tranquility at their own pace. Pranayam is not just an app; it's a pathway to inner peace and well-being." },
    { alt: "PEN World", image: "https://cdn.technolee.com/image/portfolio/devices10.webp", keyword: ["#BusinessNetworking", "#CommunityConnections", "#NetworkingTech", "#BusinessHub", "	#PENWorld"], title: 'PEN World: Networking Refined for a Community', description: "PEN World, a web and app platform for business networking within a specific community, sets the stage for meaningful connections. Providing detailed profiles and comprehensive networking tools, PEN World is more than a platform; it's a community hub for business interactions." },
    { alt: "Quizzing", image: "https://cdn.technolee.com/image/portfolio/devices04.webp", keyword: ["#OnlineQuizzes", "#StudentEngagement", "#RealTimeResults", "#EducationTech", "	#KnowledgeExplorer"], title: 'Discover Me: Quizzing into Real-Time Knowledge', description: "Discover Me, an online app and web platform, invites students into the world of real-time quizzes. Enroll, participate, and witness results unfolding in real-time. The platform is not just about testing knowledge; it's a journey of exploration and continuous learning." },

    { alt: "LHS", image: "https://cdn.technolee.com/image/portfolio/devices11.webp", keyword: ["#LeadHandling", "#LeadManagement", "#DealClosure", "#SalesTech", "	#EfficientSales"], title: 'LHS: Web-Based Wizardry for Lead Handling', description: "LHS, a web-based system, brings wizardry to lead handling, streamlining the entire cycle from lead generation to deal closure. This platform ensures efficiency, transparency, and effective lead management for businesses." },
    { alt: "Loyality SIMTech", image: "https://cdn.technolee.com/image/portfolio/devices10.webp", keyword: ["#LoyaltyProgram", "#RewardsApp", "#CustomerEngagement", "#MerchandisingSolutions", "	#SaaSloyalty"], title: 'SIMTech Loyalty: Loyalty Elevated to Excellence', description: "SIMTech Loyalty, a dedicated loyalty app, reshapes the way businesses manage reward points and merchandise. This application sets a new standard for loyalty programs, providing a platform for seamless transactions and rewarding customer experiences." },
    { alt: "Event Eligance", image: "https://cdn.technolee.com/image/portfolio/devices11.webp", keyword: ["#PartyPlots", "#EventManagement", "#CelebrationSpaces", "#EventPlanning", "	#MemorableEvents"], title: 'Tulsi Events: Elevating Events to Elegance', description: "Tulsi Events, a website dedicated to party plots, weddings, and events, provides a detailed showcase of venues, services, and event details. This platform is not just about events; it's a visual journey into the world of celebrations and gatherings." },
    { alt: "DreamWorld Holiday", image: "https://cdn.technolee.com/image/portfolio/devices11.webp", keyword: ["#TravelManagement", "#HolidayPackages", "#DestinationExplorer", "#TravelTech", "	#AdventureAwaits"], title: 'Dreamworld Holidayz: Travel Management Elevated', description: "Dreamworld Holidayz, a travel app, takes users on a journey of travel management, showcasing destinations, packages, and details. This app is not just about travel; it's a portal to curate and manage unforgettable holiday experiences." },
    { alt: "DreamWorld", image: "https://cdn.technolee.com/image/portfolio/devices11.webp", keyword: ["#TravelManagement", "#HolidayPackages", "#DestinationExplorer", "#TravelTech", "	#AdventureAwaits"], title: 'Dreamworld Holidayz: Travel Management Elevated', description: "Dreamworld Holidayz, a travel app, takes users on a journey of travel management, showcasing destinations, packages, and details. This app is not just about travel; it's a portal to curate and manage unforgettable holiday experiences." },

  ]

  const AdcardData = [
    { buttonname: 'Start Your Project', link: '/services#projectinqurys', Title: "Let's Bring Your Ideas to Life, Together!", SubTitle: 'Propel Your Project Forward with Technolee \'s Expertise', Description: 'Discover the possibilities of collaboration! Whether you have a specific project in mind, an idea for product development, or you\'re considering a digital transformation, Technolee is eager to hear from you. Our dedicated team is prepared to turn your aspirations into reality. Share your requirements with us, and let\'s embark on a journey of innovation and success.', }
  ]

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Our Portfolio - See Our Creative Work and Success Stories</title>
        <meta name="description" content="Explore our portfolio to witness our innovative projects and success stories. From software and web development to digital transformation, our work speaks for itself." />
        <meta name="keywords" content="portfolio, creative work, success stories, software development, web development, digital transformation, Technolee" />
      </Helmet>

      <Banner
        alt={"Portfolio Banner"}
        image="https://cdn.technolee.com/image/career/lifewithtechnolee/lifebanner.webp"
        title={htmlString}
        description="Dive into our showcase of innovative projects, software development, web design, digital transformation, and more. Discover our commitment to excellence."
      />
      <div className='page-content'>
        {/* <div className='container'>
        </div> */}
        <div>
          <p className='simple-p-tag'>Welcome to our showcase, where we proudly present our portfolio of creative work, achievements, and success stories. At Technolee, we're passionate about turning ideas into remarkable solutions. Whether its software and web development, web design, digital transformation, or other technology endeavors, our portfolio reflects our dedication to excellence.</p>
          <PortfolioCard contentData={contentData} />

        </div>
        <div className='container' >

          <AdCard AdcardData={AdcardData} />
        </div>
      </div>
    </>
  )
}
